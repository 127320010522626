import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ScoreboardService } from 'src/app/core/services/scoreboard-api.service';
import {CognitoUserPool, CognitoUserAttribute, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import * as AWS from 'aws-sdk';
import { resourceLimits } from 'worker_threads';
import { error } from 'console';
import { async } from 'rxjs/internal/scheduler/async';
import { DashboardService } from 'src/app/core/services/dashboard.service';
import { type } from 'os';
import { DataSource } from '@angular/cdk/collections';
import { zip } from 'rxjs';

export interface ScoreboardModel {
    id: number;
    user: string;
    score: string;
    accuracy: string;
}

@Component({
    selector: 'app-sim-leaderboard',
    templateUrl: './leaderboard.component.html',
    styleUrls: ['./leaderboard.component.scss'],
})
export class SimLeaderboardComponent implements OnInit {
    public dataSource: any;
    public dataSource1: any;
    public scores: any [];
    public score: any;
    public accuracy: any;
    public counter: any[];
    public dynamodb: any;
    public usernames: any;
    public dict: any;
    public myArray: any;

    displayedColumns: string[] = ['position', 'name', 'score', 'accuracy'];
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    constructor(private scoreboardService: ScoreboardService, private dashboardService: DashboardService) {
        this.scores = [];
        this.counter = [];
    }

    ngOnInit(): void {
        /* this.retrieveScoreboard(); */
        this.dataBaseCall();

    }

    

    async dataBaseCall(){

        this.dict = [];
        this.score = [];
        this.usernames = [];
        
        this.dict = JSON.stringify(this.dict);
        this.dashboardService.getLeaderboard().subscribe(
            (data) => { console.log('test call', data);
            console.log(data)
            this.dataSource1 = data;
            this.usernames = this.dataSource1.usernameArray.slice(0,25)
            this.score = this.dataSource1.scoresArray.slice(0,25)
            this.accuracy = this.dataSource1.accuracyArray.slice(0,25)
            this.dataSource = new MatTableDataSource(this.usernames)
            this.dataSource.paginator = this.paginator;
        })
    }


    /* retrieveScoreboard(): void {
        this.scoreboardService.getAll().subscribe(
            (data) => {
                this.scores = data;
                this.dataSource = new MatTableDataSource<ScoreboardModel>(this.scores);
                this.dataSource.paginator = this.paginator;
            },
            (error) => {
                console.log(error);
            }
        );
    } */
} 
