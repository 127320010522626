<!DOCTYPE html>
<html>
<head>
<title>W3.CSS Template</title>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1">
<link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css">
<style>
  .w3-content {
    max-width: 100%;
    margin: auto;
    padding: 0 20px;
  }
  .w3-row {
    display: flex;
    flex-wrap: wrap;
    background: linear-gradient(0.25turn, #1891d8, #d768e0); /* Apply background to .w3-row */
    padding: 20px; /* Add padding to .w3-row to include background */
  }
  .w3-half {
    flex: 50%;
    box-sizing: border-box;
    padding: 20px;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  .w3-padding-large {
    padding: 20px;
  }
  button {
    width: 100%;
    padding: 10px 0;
    background: linear-gradient(0.25turn,#d768e0,#1891d8);
    color: white;
    border: none;
    cursor: pointer;
  }
  h1 {
    font-size: min(6vw, 36px) ; /* Set font size relative to viewport width */
  }
</style>
</head>
<body class="w3-content">


<!-- First Grid: Logo & About -->
<div class="w3-row">
  <div class="w3-half w3-container w3-center">
    <img src="assets/imgs/BookCoverExilio.png">
  </div>
  <div class="w3-half w3-container">
    <div class="w3-padding-64 w3-center">
      <h1>ExilioLabs© Beginners Guide to Trading & Cryptocurrency</h1>
      <div class="w3-left-align w3-padding-large">
        <p>ExilioLabs© beginners guide to trading & cryptocurrency aims to educate our readers on the most up to date key technical techniques and fundamental analysis behind our rapidly growing world in trading and cryptocurrency. Our mission at ExilioLabs© is to showcase the power of trading techniques when used correctly. ExilioLabs© prides itself in providing online educational learning tools and educational resources for people of all trading levels to enjoy!</p>
      </div>
    </div>
    <a href="https://www.amazon.com/Beginners-Guide-Trading-Cryptocurrency/dp/B0BCD511L1" style="display: block; text-decoration: none;">
      <button style="width: 100%; padding: 10px 0; background: linear-gradient(0.25turn,#d768e0,#1891d8); color: white; border: none; cursor: pointer;">
        <mat-icon style="padding-right: 5px">book</mat-icon> Buy Now
      </button>
    </a>
  </div>
</div>

</body>
</html>
