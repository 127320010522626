import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
} from '@angular/material/dialog';
import { ColorSchemeService } from 'src/app/core/services/color-scheme.service';
import { UserService } from 'src/app/core/services/user-api.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router } from '@angular/router';
import { ScoreboardService } from 'src/app/core/services/scoreboard-api.service';
import { DashboardService }  from 'src/app/core/services/dashboard.service' ;
import {CognitoUserPool, CognitoUserAttribute, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import * as AWS from 'aws-sdk';
import { resourceLimits } from 'worker_threads';
import { error } from 'console';
import { NULL_EXPR } from '@angular/compiler/src/output/output_ast';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { type } from 'os';
import { async } from 'rxjs/internal/scheduler/async';
import { __await } from 'tslib';
import { delay } from 'rxjs-compat/operator/delay';
import {SimDashboardComponent} from 'src/app/routes/sim/dashboard/dashboard.component';



export interface DialogData {
    name: string;
    email: string;
    password: string;
    confirmationCode : string;
}

@Component({
    selector: 'app-sim-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class SimHeaderComponent implements OnInit {
    public username: string;
    public name: string;
    public email: string;
    public showLogout: boolean;
    public darkTheme: boolean;
    public mobile: boolean;
    public confirmationCode: string;


    @Output() toggleSideBarForMe: EventEmitter<any> = new EventEmitter();

    constructor(
        public dialog: MatDialog,
        private colorSchemeService: ColorSchemeService,
        public authService: AuthService,
        private router: Router
    ) {
        this.showLogout = false;
    }

    ngOnInit() {
        if (window.screen.width < 600) {
            // 768px portrait
            this.mobile = true;
        }
        if (localStorage.getItem('prefers-color') === 'light') {
            this.darkTheme = false;
        } else {
            this.darkTheme = true;
        }

        if (localStorage.getItem('username') === 'null' ) {
            this.showLogout = false
        } else {
            this.showLogout = true;
            this.username = localStorage.getItem('username');
            console.log(localStorage.getItem('username'))
            console.log(localStorage.getItem('username'))
        }
    }

    toggleSideBar() {
        this.toggleSideBarForMe.emit();
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 300);
    }

    redirect(route: string, external: boolean): void {
        if (external) {
            window.open('http://localhost:8081', '_blank');
        } else {
            this.router.navigate([route],);
        }
    }

    changeTheme(): void {
        const scheme = this.colorSchemeService.currentActive();
        if (scheme === 'dark') {
            this.colorSchemeService.update('light');
        } else {
            this.colorSchemeService.update('dark');
        }

        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/sim-default']);
        });
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(HelpDialog, {
            width: '600px',
            height: '600px',
            backdropClass: 'backdropBackground',
            disableClose: true,
        });

        dialogRef.afterClosed().subscribe((result) => {
        });
    }

    openLoginDialog(): void {
        const dialogRef = this.dialog.open(LoginDialog, {
            width: '500px',
            height: '650px',
            data: { name: this.name, email: this.email },
            backdropClass: 'backdropBackground',
            disableClose: true,
        });

        dialogRef.afterClosed().subscribe((result) => {
            const token = localStorage.getItem('access_token');
            if (token !== 'null') {
                this.showLogout = true;
                this.username = localStorage.getItem('username');
            } else {
                localStorage.setItem('username', 'null')
            }

            this.router.navigateByUrl('/leaderboard', { skipLocationChange: true }).then(() => {
                this.router.navigate(['/']);
            });
        });
    }

    onLogout(): void {
        this.authService.logout();
        this.showLogout = false;
        localStorage.setItem('username', 'null')
        localStorage.setItem('access_token', 'null')
        this.router.navigate(['/sim-default']);
        console.log(localStorage.getItem('username'))
        window.location.reload();
    }
}

// Help dialog

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'app-help-dialog',
    templateUrl: './help-dialog.component.html',
    styleUrls: ['./header.component.scss'],
})
// tslint:disable-next-line: component-class-suffix
export class HelpDialog {
    constructor(public dialogRef: MatDialogRef<HelpDialog>) {}

    close(): void {
        this.dialogRef.close();
    }
}

// Login Dialog

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'app-login-dialog',
    templateUrl: './login-dialog.component.html',
    styleUrls: ['./header.component.scss'],
})
// tslint:disable-next-line: component-class-suffix
export class LoginDialog {
    public showRegister: boolean;
    public showLogin: boolean;
    public showLogout: boolean;
    public uuid: string;
    public name: string;
    public email: string;
    public failedLogin: boolean;
    public emailError: string;
    public usernameError: string;
    public passwordError: string;
    public registerError: any;
    public loginError: any;
    public confirmCodeError: any;
    public hide: boolean;
    public token: string;
    public loginView: number;
    public username: any;
    public confirmationCode: string;
    public dataGood: number;
    public test: any;
    public loginViewTest: any;

    constructor(
        public dialogRef: MatDialogRef<LoginDialog>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        public userService: UserService,
        private scoreboardService: ScoreboardService,
        public authService: AuthService,
        private dashboardService: DashboardService,
        private SimDashBoardComponent: SimDashboardComponent,
    ) {
        this.showRegister = true;
        this.showLogout = true;
        this.showLogin = false;
        this.failedLogin = false;
        this.hide = true;
        this.loginView = 0;
        this.dataGood = 0;
        this.token = localStorage.getItem('acces_token');
        if (this.token !== null) {
            this.loginView = 3;
        }
        this.confirmCodeError = null
        console.log(this.showLogout)
    }

    async onLogin(name: string, password: string): Promise<void> {

        localStorage.setItem('login', null);
        this.loginError = null

        name = name.replace(/\s+/g, '')

        var authenticationData = {
            Username: name,
            Password: password,
        };
        var authenticationDetails = new AuthenticationDetails(
            authenticationData
        );

        var poolData = {
            UserPoolId: 'eu-west-1_whall17HA', // Your user pool id here
            ClientId: '53eq9tifov659d5bsro82kn4oj', // Your client id here
        };
        var userPool = new CognitoUserPool(poolData);
        var userData = {
            Username: name,
            Pool: userPool,
        };
        var cognitoUser = new CognitoUser(userData);
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: function(result) {
                var accessToken = result.getAccessToken().getJwtToken();
        
                //POTENTIAL: Region needs to be set if not already set previously elsewhere.
                AWS.config.region = 'eu-west-1';
        
                AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                    IdentityPoolId: 'eu-west-1:01baacc8-3e31-4929-a0b8-4976aa4c8ebd', // your identity pool id here
                    Logins: {
                        // Change the key below according to the specific region your user pool is in.
                        'cognito-idp.eu-west-1.amazonaws.com/eu-west-1_whall17HA': result
                            .getIdToken()
                            .getJwtToken(),
                    },
                });
        
                //refreshes credentials using AWS.CognitoIdentity.getCredentialsForIdentity()
                ( < AWS.CognitoIdentityCredentials > AWS.config.credentials).refresh((error) => {
                    if (error) {
                        console.error(error);
                        localStorage.setItem('login', String(error));
                    } else {
                        // Instantiate aws sdk service objects now that the credentials have been updated.
                        // example: var s3 = new AWS.S3();
                        console.log('Successfully logged!:');
                        localStorage.setItem('login', 'logged')
                        /* code here for authentication code */
                    }
                });
            },
        
            onFailure: function(err) {
                console.log(err);
                localStorage.setItem('login', err);
                console.log(localStorage.getItem('login'))
            },
        });


        const delay = ms => new Promise(res => setTimeout(res, ms));
        await delay(2000);
        if (localStorage.getItem('login') == 'logged') {
            this.loginView = 2
            localStorage.setItem('username', name)
            this.username = localStorage.getItem('username');
            localStorage.setItem('access_token','true')

        }

        if (localStorage.getItem('login') == null){
            this.loginView = 2;
            localStorage.setItem('username', name)
            this.username = localStorage.getItem('username');
            localStorage.setItem('access_token','true')
        } else if (localStorage.getItem('login') == 'UserNotConfirmedException: User is not confirmed.' || localStorage.getItem('login') == 'UserNotConfirmedException') {
            this.loginView = 4;       
        } else {
                this.loginError = 'Incorrect Username or Password'
        };       
        
        
        /* const data = {
            username: name,
            password,
        };
        this.authService.login(data).subscribe(
            (x) => {
                this.name = x.user.username;
                this.email = x.user.email;
                this.authService.setLocalStorage(x);
                this.loginView = 2;
                this.retrieveScores(x.user.username);
            },
            (error) => {
                console.log(error);
                this.failedLogin = true;
            }
        ); */
    }

    retrieveScores(username: string): void {
        this.scoreboardService.getByUser(username).subscribe(
            (data) => {
                localStorage.setItem('score', data.score);
                localStorage.setItem('accuracy', data.accuracy);
                localStorage.setItem('correct', data.correct);
            },
            (error) => {
                console.log(error);
            }
        );
    }

    onLogout(): void {
        this.authService.logout();
        this.dialogRef.close();
    }

    onMaybeLater(): void {
        this.dialogRef.close();
    }

    onCancel2(): void {
        // this.data.name = name;
        // this.data.email = email;
        window.location.assign("https://www.w3schools.com")
        this.dialogRef.close();

    }

    async onCancel1(): Promise <void> {
        // this.data.name = name;
        // this.data.email = email;
        this.dialogRef.close();
        window.location.reload()
    }

    LoginViewDetails (): void {
        this.loginView = 4
        console.log('Im here')
    }


    async onRegister(name: string, email: string, password: string): Promise<void> {

        this.dataGood = 0;

        this.usernameError = null
        this.emailError = null
        this.passwordError = null
        this.registerError = null
        localStorage.setItem('testing', null);

        if (this.data.name == null || this.data.name == "") {
            this.usernameError = 'Please Provide a Username';
            this.dataGood = 1;
        }
        if (this.data.email == null || this.data.email == "") {
            this.emailError = 'Please Provide an Email';
            this.dataGood = 1;
        }
        if (this.data.password == null || this.data.password == "") {
            this.passwordError = 'Please Provide a Password';
            this.dataGood = 1;
        }

        if (this.dataGood == 0) {
            var poolData = {
            UserPoolId: 'eu-west-1_qDyxhCSsz', // Your user pool id here
            ClientId: '53eq9tifov659d5bsro82kn4oj', // Your client id here
        };
        var userPool = new CognitoUserPool(poolData);
        
        var attributeList = [];
        
        var dataEmail = {
            Name: 'email',
            Value: this.data.email,
        };
        
        var dataPhoneNumber = {
            Name: 'phone_number',
            Value: '+15555555555',
        };
        var attributeEmail = new CognitoUserAttribute(dataEmail);
        
        attributeList.push(attributeEmail);

        this.data.name = this.data.name.replace(/\s+/g, '')
        
        userPool.signUp(this.data.name, this.data.password, attributeList, null, function(
            err,
            result
        ) {
            if (err) {
                alert(err.message || JSON.stringify(err));
                localStorage.setItem('testing', err.message);                
                return;
            }
            var cognitoUser = result.user;
            console.log('test', cognitoUser);
            console.log('user name is ' + cognitoUser.getUsername());
        }); 

        
        localStorage.setItem('username', this.data.name)
        }
        
        setTimeout(this.functiontest, 500)
        const delay = ms => new Promise(res => setTimeout(res, ms));
        await delay(1000);
        this.registerError = this.functiontest();
        if ( this.registerError == 'null' && this.dataGood == 0){
            this.registerError = null
            this.loginView = 4
            return
        }      
    };

    functiontest(){
        this.test = String(localStorage.getItem('testing'))

        if (this.test !==null || this.test !== "") {
            this.registerError = this.test;
            console.log(this.registerError)
            return this.registerError
        } 

        if (this.test ==  null || this.test == "") {
            console.log('its null', this.test, typeof(this.test))
            this.loginView = 4;
            console.log('this is okay')
            return this.loginView
        }
        
    }


    async onConfirmationCode(confirmationCode: string): Promise<void> {
        localStorage.setItem('confirmCode', null)
        this.confirmCodeError = null
        var poolData = {
            UserPoolId: 'eu-west-1_qDyxhCSsz', // Your user pool id here
            ClientId: '53eq9tifov659d5bsro82kn4oj', // Your client id here
        };
        
        var userPool = new CognitoUserPool(poolData);
        var userData = {
            Username: localStorage.getItem('username'),
            Pool: userPool,
        };
        
        var cognitoUser = new CognitoUser(userData);

        this.data.confirmationCode = this.data.confirmationCode.replace(/\s+/g, '')

        cognitoUser.confirmRegistration(this.data.confirmationCode, true, function(err, result) {
            if (err) {

                alert(err.message || JSON.stringify(err));
                return;
            }
            console.log('call result: ' + result);
            localStorage.setItem('confirmCode','correct');
        });


        const delay = ms => new Promise(res => setTimeout(res, ms));
        await delay(1000);        
        if (localStorage.getItem('confirmCode') == 'correct'){
            this.loginView = 3
            localStorage.setItem('username', this.data.name)
            this.username = localStorage.getItem('username');
            localStorage.setItem('access_token','true')
            this.dashboardService.updateSubscription(this.data.name).subscribe()
            
        } else {
            this.confirmCodeError = 'Invalid Confirmation Code'
        }
    };

    onResendCode(confirmationCode: string): void {
        
        var poolData = {
            UserPoolId: 'eu-west-1_qDyxhCSsz', // Your user pool id here
            ClientId: '53eq9tifov659d5bsro82kn4oj', // Your client id here
        };
        
        var userPool = new CognitoUserPool(poolData);
        var userData = {
            Username: localStorage.getItem('username'),
            Pool: userPool,
        };
        
        var cognitoUser = new CognitoUser(userData);
        
        cognitoUser.resendConfirmationCode(function(err, result) {
            if (err) {
                alert(err.message || JSON.stringify(err));
                return;
            }
            console.log('call result: ' + result);
        });
    };
        
        /* const user = {
            username: name,
            email,
            password,
        };
        const login = {
            username: name,
            password,
        };
        this.authService.register(user).subscribe(
            (data) => {
                this.name = data.user;
                this.email = data.email;
                this.authService.login(data);
                this.loginView = 3;
            },
            (error) => {
                this.failedLogin = true;
                this.emailError = error.error.email;
                this.usernameError = error.error.username;
                this.passwordError = error.error.password;
            }
        ); */
    } 
