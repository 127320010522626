<script>
   function toggleZoomScreen() {
       document.body.style.zoom = "90%";
   }
</script>
<div *ngIf="!this.mobile" fxLayout="column" fxLayout.lt-lg="row">
    <div fxFlex="90">
        <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
            <div fxFlex="70">
                <div fxLayout="column" fxLayoutGap="20px">
                    <!-- Chart -->
                    <mat-card>
                        <div>
                            <highcharts-chart
                                [Highcharts]="Highcharts"
                                [options]="plotOptions"
                                style="width: 100%; height: 700px; display: block"
                            ></highcharts-chart>
                        </div>
                        <mat-divider style="border: 5px white;"></mat-divider>
                        <div class="row">
                            <div class="column">
                                <div class="card1">
                                    <b>RSI </b>
                                </div>
                            </div>
                            <div class="column">
                                <div class="card1">
                                   <b>%ROC </b>
                                </div>
                            </div>
                            <div class="column">
                                <div class="card1">
                                    <b>OBV</b>
                                </div>
                            </div>
                            <div class="column">
                                <div class="card1">
                                    <b>STOCH</b>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="column">
                                <div class="card">
                                    {{this.RSI}}
                                </div>
                            </div>
                            <div class="column">
                                <div class="card">
                                    {{this.ROC}}
                                </div>
                            </div>
                            <div class="column">
                                <div class="card">
                                    {{this.OBV}}
                                </div>
                            </div>
                            <div class="column">
                                <div class="card">
                                    {{this.Stoch}}
                                </div>
                            </div>
                        </div>
                    </mat-card>
                    <!-- Buy/Sell Buttons -->
                    <div>
                        <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
                            <mat-card fxFlex="33">
                                <button
                                    fxFlex="100"
                                    style="padding: 10px 0px 10px 0px"
                                    (click)="submitAction('buy')"
                                    mat-raised-button
                                    color="green"
                                    [disabled]="submitting"
                                >
                                    Buy
                                </button>
                            </mat-card>
                            <mat-card fxFlex="33">
                                <button
                                    fxFlex="100"
                                    style="padding: 10px 0px 10px 0px"
                                    (click)="submitAction('undetermined')"
                                    mat-raised-button
                                    color="grey"
                                    [disabled]="submitting"
                                >
                                    Undetermined
                                </button>
                            </mat-card>
                            <mat-card fxFlex="33">
                                <button
                                    fxFlex="100"
                                    style="padding: 10px 0px 10px 0px"
                                    (click)="submitAction('sell')"
                                    mat-raised-button
                                    color="red"
                                    [disabled]="submitting"
                                >
                                    Sell
                                </button>
                            </mat-card>
                        </div>
                    </div>
                </div>
            </div>

            <div fxFlex="30">
                <div fxLayout="column" fxLayoutGap="20px" xLayoutAlign="space-between center">

                    <div fxFlex="40">
                        <div>
                            <mat-card>
                                <div style="margin-top:10px; text-align: center;  font-size: 35px; height: 80px; display: block">
                                    <div >
                                        <mat-icon style="font-size: 20px; margin-right: 5px;" >leaderboard</mat-icon>
                                        <span *ngIf="!loggedIn" style="font-size: 30px"> -</span>
                                        <span *ngIf="loggedIn"> {{this.leaderboardPos}}<sup style="vertical-align: super; font-size: small;"> {{this.ordinal}}</sup></span>

                                        <br>
                                        <div style="margin-top: 0px;">
                                            <span style="text-align: center; font-size: 18px; font-weight: bold;">Leaderboard Position</span>
                                        </div>
                                    </div>
                                </div>
                            </mat-card>

                        </div>
                    </div>
                    <div fxFlex="10">
                        <mat-card>
                            <button
                                fxFlex="100"
                                style="padding: 5px 0px 5px 0px"
                                mat-raised-button
                                style="background: linear-gradient(0.25turn,#d768e0,#1891d8)"
                                (click)="onSave()"
                            >
                            <mat-icon style="padding-right: 5px">save</mat-icon>

                                Save Session
                            </button>
                        </mat-card>
                    </div>
                    <div fxFlex="30">
                        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
                            <mat-card fxFlex="50">
                                <div>
                                    <span style="text-align: center; font-size: 18px; font-weight: bold;">Current Score</span>
                                </div>
                                <div style="text-align: center;  font-size: 35px; height: 150px; display: block">
                                    <div style="padding-top: 25%;">
                                        <mat-icon style="font-size: 20px; margin-right: 5px;" >stacked_line_chart</mat-icon>
                                        <span *ngIf="this.currentScore>0" style="color: #589636;"><b>{{ this.currentScore }}</b></span>
                                        <span *ngIf="this.currentScore<0" style="color: #B2022F;"><b>{{ this.currentScore }}</b></span>
                                        <span *ngIf="this.currentScore==0"><b>{{ this.currentScore }}</b></span>
                                    </div>
                                </div>
                            </mat-card>
                            <mat-card fxFlex="50">
                                <div style="text-align: center; font-size: 18px; font-weight: bold;">
                                    <span>Accuracy</span>
                                </div>
                                <highcharts-chart
                                    [Highcharts]="Highcharts"
                                    [options]="pieOptions"
                                    style="width: 100%; height: 150px; display: block"
                                >
                                </highcharts-chart>
                            </mat-card>
                        </div>
                    </div>

                    <div style="font-family: 'Courier New', Courier, monospace; font-size: medium" fxFlex="10">
                        <!-- Rules -->
                        <mat-card>
                            <div>
                                <p style="text-align: center"><b>Rules</b></p>
                                <p>Each correct step will gain the user 1 point.</p>
                                <p>An incorrect step will yield in a loss of 1 point.</p>
                                <p>An Undetermined answer will not impact your score or accuracy.</p>
                            </div>
                            <div></div>
                        </mat-card>
                    </div>
                    <div style="font-family: 'Courier New', Courier, monospace;" fxFlex="10">
                        <!-- Rules -->
                        <mat-card>
                            <div>
                                <p style="text-align: center; font-weight: bold;"><b>Want to learn more?</b></p>
                                <p style="font-size: medium; font-style: italic;">If you want to gain a better understanding of what factors can influence the market, how to understand technical indicators better or advance your knowledge; check out our Educational book below <mat-icon>library_books</mat-icon></p>
                            </div>
                            <div></div>
                        </mat-card>
                        <!-- Rules -->
                        <mat-card>
                            <button
                                fxFlex="100"
                                style="padding: 5px 0px 5px 0px"
                                mat-raised-button
                                style="background: linear-gradient(0.25turn,#d768e0,#1891d8)"
                            >
                                <mat-icon style="padding-right: 5px">book</mat-icon>

                                <a href="https://www.amazon.com/Beginners-Guide-Trading-Cryptocurrency/dp/B0BCD511L1" target="_blank">ExilioLabs Beginners Book </a> 
                            </button>
                        </mat-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
<!--
    <div fxFlex="10">
        <br />
        <mat-divider></mat-divider>
        <br />
        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px" class="stats-cards">
            <mat-card fxFlex="100"> Space To Be Filled. Possibly Google AdSense? </mat-card>
        </div>
        <br />
        <mat-divider></mat-divider>
    </div> -->
</div>

<div *ngIf="this.mobile" fxLayout="column" fxLayout.lt-lg="row" style="max-width: 99%; overflow-x: hidden;">
    <div fxFlex="90">
        <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
            <div fxFlex="70">
                <div fxLayout="column" fxLayoutGap="20px">
                    <!-- Chart -->
                    <mat-card>
                        <div>
                            <highcharts-chart
                                [Highcharts]="Highcharts"
                                [options]="plotOptions"
                                style="width: 100%; height: 700px; display: block"
                            ></highcharts-chart>
                        </div>
                        <mat-divider style="border: 5px white;"></mat-divider>
                    </mat-card>
                    <!-- Buy/Sell Buttons -->
                    <div>
                        <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
                            <mat-card fxFlex="33">
                                <button
                                    fxFlex="100"
                                    style="padding: 10px 0px 10px 0px"
                                    (click)="submitAction('buy')"
                                    mat-raised-button
                                    color="green"
                                    [disabled]="submitting"
                                >
                                    Buy
                                </button>
                            </mat-card>
                            <mat-card fxFlex="33">
                                <button
                                    fxFlex="100"
                                    style="padding: 10px 0px 10px 0px"
                                    (click)="submitAction('undetermined')"
                                    mat-raised-button
                                    color="grey"
                                    [disabled]="submitting"
                                >
                                    Undetermined
                                </button>
                            </mat-card>
                            <mat-card fxFlex="33">
                                <button
                                    fxFlex="100"
                                    style="padding: 10px 0px 10px 0px"
                                    (click)="submitAction('sell')"
                                    mat-raised-button
                                    color="red"
                                    [disabled]="submitting"
                                >
                                    Sell
                                </button>
                            </mat-card>
                        </div>
                    </div>
                </div>
            </div>

            <div fxFlex="30">
                <div fxLayout="column" fxLayoutGap="20px" xLayoutAlign="space-between center">

                    <div fxFlex="40">
                        <div>
                            <mat-card>
                                <div style="margin-top:10px; text-align: center;  font-size: 35px; height: 80px; display: block">
                                    <div >
                                        <mat-icon style="font-size: 20px; margin-right: 5px;" >leaderboard</mat-icon>
                                        <span *ngIf="!loggedIn" style="font-size: 30px"> -</span>
                                        <span *ngIf="loggedIn"> {{this.leaderboardPos}}<sup style="vertical-align: super; font-size: small;"> {{this.ordinal}}</sup></span>

                                        <br>
                                        <div style="margin-top: 0px;">
                                            <span style="text-align: center; font-size: 18px; font-weight: bold;">Leaderboard Position</span>
                                        </div>
                                    </div>
                                </div>
                            </mat-card>

                        </div>
                    </div>
                    <div fxFlex="10">
                        <mat-card>
                            <button
                                fxFlex="100"
                                style="padding: 5px 0px 5px 0px"
                                mat-raised-button
                                style="background: linear-gradient(0.25turn,#d768e0,#1891d8)"
                                (click)="onSave()"
                            >
                            <mat-icon style="padding-right: 5px">save</mat-icon>

                                Save Session
                            </button>
                        </mat-card>
                    </div>
                    <div fxFlex="30">
                        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
                            <mat-card fxFlex="50">
                                <div>
                                    <span style="text-align: center; font-size: 18px; font-weight: bold;">Current Score</span>
                                </div>
                                <div style="text-align: center;  font-size: 35px; height: 150px; display: block">
                                    <div style="padding-top: 25%;">
                                        <mat-icon style="font-size: 20px; margin-right: 5px;" >stacked_line_chart</mat-icon>
                                        <span *ngIf="this.currentScore>0" style="color: #589636;"><b>{{ this.currentScore }}</b></span>
                                        <span *ngIf="this.currentScore<0" style="color: #B2022F;"><b>{{ this.currentScore }}</b></span>
                                        <span *ngIf="this.currentScore==0"><b>{{ this.currentScore }}</b></span>
                                    </div>
                                </div>
                            </mat-card>
                            <mat-card fxFlex="50">
                                <div style="text-align: center; font-size: 18px; font-weight: bold;">
                                    <span>Accuracy</span>
                                </div>
                                <highcharts-chart
                                    [Highcharts]="Highcharts"
                                    [options]="pieOptions"
                                    style="width: 100%; height: 150px; display: block"
                                >
                                </highcharts-chart>
                            </mat-card>
                        </div>
                    </div>

                    <div style="font-family: 'Courier New', Courier, monospace; font-size: small" fxFlex="10">
                        <!-- Rules -->
                        <mat-card>
                            <div>
                                <p style="text-align: center"><b>Rules</b></p>
                                <p>Each correct step will gain the user 1 point.</p>
                                <p>An incorrect step will yield in a loss of 1 point.</p>
                                <p>An Undetermined answer will not impact your score or accuracy.</p>
                            </div>
                            <div></div>
                        </mat-card>
                    </div>
                    <div style="font-family: 'Courier New', Courier, monospace;" fxFlex="10">
                        <!-- Rules -->
                        <mat-card>
                            <div>
                                <p style="text-align: center; font-weight: bold;"><b>Comments</b></p>
                                <p style="font-size: small; font-style: italic;">Please read the bellow guidance as to why the previous answer was {{this.previousPrediction}}</p>
                                <p style="font-size: small; font-weight: bold;"> {{this.previousComment}}</p>
                            </div>
                            <div></div>
                        </mat-card>
                        <!-- Rules -->
                        <mat-card>
                            <button
                                fxFlex="100"
                                style="padding: 5px 0px 5px 0px"
                                mat-raised-button
                                style="background: linear-gradient(0.25turn,#d768e0,#1891d8)"
                                routerLink="/default/ebook"
                            >
                                <mat-icon style="padding-right: 5px">book</mat-icon>

                                ExilioLabs Beginners Book
                            </button>
                        </mat-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
<!--
    <div fxFlex="10">
        <br />
        <mat-divider></mat-divider>
        <br />
        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px" class="stats-cards">
            <mat-card fxFlex="100"> Space To Be Filled. Possibly Google AdSense? </mat-card>
        </div>
        <br />
        <mat-divider></mat-divider>
    </div> -->
</div>