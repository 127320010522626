<div style="margin-left: 20%; margin-right: 20%" *ngIf=!messageSent>
    <div fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="20px">
        <h1 style="text-align: center;">How Can We Help You?</h1>
        <br />

        <div class="container" fxFlex="100" style="max-width:80%;">
            <mat-card fxFlex="100" style="text-align: center;">
                <div>
                    <h3>We’re happy to answer any questions you may have, Contact us on Instagram, Facebook or Twitter anytime 😁</h3>
                </div>
                <div class="row">
                    <div class="column">
                        <a href="https://www.facebook.com/ExilioLabs/" target="_blank"><img src="https://www.pinclipart.com/picdir/big/2-21918_download-transparent-background-facebook-logo-clipart-facebook-logo.png" style="max-width: 30%; max-height: 30%;" alt=""> </a> 
                    </div>
                    <div class="column">
                        <a href="https://www.instagram.com/exiliolabs/" target="_blank"> <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Instagram_icon.png/2048px-Instagram_icon.png" style="max-width: 30%; max-height: 30%;" alt=""></a> 
                    </div>
                    <div class="column">
                        <a href="https://twitter.com/ExilioLabs" target="_blank"><img src="https://www.clipartmax.com/png/full/135-1352882_png-format-twitter-logo-transparent.png" style="max-width: 30%; max-height: 30%;" alt=""> </a> 
                    </div>
                  </div>
                <mat-divider></mat-divider>
                <br>
                <div>
                    <h3>Or email us at <a href="mailto:trading@exiliolabs.com" style="color: dodgerblue ;">Trading@ExilioLabs.com</a></h3>
                </div>
            </mat-card>
            <br>
        </div>
    </div>
</div>
