import { Component, OnInit } from '@angular/core';
import { interval, Subscription, Subject } from 'rxjs';
import { DashboardService } from '../../core/services/dashboard.service';
import { ResourceService } from '../../core/services/resource.service';
import * as Highcharts from 'highcharts/highstock';
import HC_exporting from 'highcharts/modules/exporting';
import { NgxSpinnerService } from 'ngx-spinner';
import { IsMobile } from 'ng2-responsive';
import { takeUntil } from 'rxjs/operators';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { ThrowStmt } from '@angular/compiler';

interface Select {
    value: string;
    viewValue: string;
}

interface RSI {
    rsiData: any;
    candles: any;
    indicatordataframe: any;
    currentRSI: string;
    signalSuggestion: string;
    accUnder30: string;
    accOver70: string;
    buySellSignal: string;
    current_Price: any;
    body: any;
}

interface ADX {
    adxData: any;
    candles: any;
    plusDI: any;
    negDI: any;
    currentPlusDI: string;
    currentNegDI: string;
    currentADX: string;
    signalSuggestion: string;
    buySellSignal: string;
    current_Price: any;
}
interface MACD {
    macdData: any;
    candles: any;
    indicatordataframe: any;
    currentMACD: string;
    signalSuggestion: string;
    accUnder30: string;
    accOver70: string;
    buySellSignal: string;
    current_Price: any;
}
interface OBV {
    obvData: any;
    candles: any;
    indicatordataframe: any;
    currentOBV: string;
    signalSuggestion: string;
    accUnder30: string;
    accOver70: string;
    buySellSignal: string;
    current_Price: any;
}
interface UI {
    uiData: any;
    candles: any;
    indicatordataframe: any;
    currentUI: string;
    signalSuggestion: string;
    accUnder30: string;
    accOver70: string;
    buySellSignal: string;
    current_Price: any;
}
interface ROC {
    rocData: any;
    candles: any;
    indicatordataframe: any;
    currentROC: string;
    signalSuggestion: string;
    accUnder30: string;
    accOver70: string;
    buySellSignal: string;
    current_Price: any;
}
interface AO {
    aoData: any;
    candles: any;
    indicatordataframe: any;
    currentAO: string;
    signalSuggestion: string;
    accUnder30: string;
    accOver70: string;
    buySellSignal: string;
    current_Price: any;
}
interface KAMA {
    kamaData: any;
    candles: any;
    indicatordataframe: any;
    currentKAMA: string;
    signalSuggestion: string;
    accUnder30: string;
    accOver70: string;
    buySellSignal: string;
    current_Price: any;
}
interface STOCH {
    stochData: any;
    candles: any;
    indicatordataframe: any;
    currentSTOCH: string;
    signalSuggestion: string;
    accUnder30: string;
    accOver70: string;
    buySellSignal: string;
    current_Price: any;
}
interface TSI {
    tsiData: any;
    candles: any;
    indicatordataframe: any;
    currentTSI: string;
    signalSuggestion: string;
    accUnder30: string;
    accOver70: string;
    buySellSignal: string;
    current_Price: any;
}
interface UO {
    uoData: any;
    candles: any;
    indicatordataframe: any;
    currentUO: string;
    signalSuggestion: string;
    accUnder30: string;
    accOver70: string;
    buySellSignal: string;
    current_Price: any;
}
interface ADI {
    adiData: any;
    candles: any;
    indicatordataframe: any;
    currentADI: string;
    signalSuggestion: string;
    accUnder30: string;
    accOver70: string;
    buySellSignal: string;
    current_Price: any;
}
interface FII {
    fiiData: any;
    candles: any;
    indicatordataframe: any;
    currentFII: string;
    signalSuggestion: string;
    accUnder30: string;
    accOver70: string;
    buySellSignal: string;
    current_Price: any;
}
interface ATR {
    atrData: any;
    candles: any;
    indicatordataframe: any;
    currentATR: string;
    signalSuggestion: string;
    accUnder30: string;
    accOver70: string;
    buySellSignal: string;
    current_Price: any;
}
interface BB {
    bbData: any;
    candles: any;
    indicatordataframe: any;
    currentBB: string;
    signalSuggestion: string;
    accUnder30: string;
    accOver70: string;
    buySellSignal: string;
    current_Price: any;
}

interface ID {
    CodeValidity: string;
}

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
    private ngUnsubscribe: Subject<Object> = new Subject();
    public name: string;
    public ohlcChart: any[];
    public indicatorChart: any[];
    public adxChart: any[];
    public adxPlusDI: any[];
    public adxNegDI: any[];
    public current_Price: any[];
    public pairing: string;
    public coinPrices: any[];
    public indicator: string;
    public margin: number;
    public interval: string;
    public currency: string; //used for currency selection
    public symbol: string;
    public plotOptions: {};
    public adxOptions: {};
    public rsiOptions: {};
    public macdOptions: {};
    public rocOptions: {};
    public obvOptions: {};
    public uiOptions: {};
    public aoOptions: {};
    public kamaOptions: {};
    public stochOptions: {};
    public tsiOptions: {};
    public uoOptions: {};
    public adiOptions: {};
    public fiiOptions: {};
    public atrOptions: {};
    public bbOptions: {};
    public chartTheme: string;
    public chartText: string;
    public price: any;
    public price1: string;
    public suggestion: string;
    public colors: any[];
    public indicatorView: string;
    public lastUpdated: string;
    public adxResult: ADX;
    public rsiResult: RSI;
    public macdResult : MACD;
    public rocResult: ROC;
    public uiResult: UI;
    public obvResult: OBV;
    public aoResult: AO;
    public kamaResult: KAMA;
    public stochResult: STOCH;
    public tsiResult: TSI;
    public uoResult: UO;
    public adiResult: ADI;
    public fiiResult: FII;
    public atrResult: ATR;
    public bbResult: BB;
    public subscription: Subscription;
    public refreshPeriod: number;
    public feedback: string;
    public feedbackSubmtited: boolean;
    public notificationEmail: string;
    public emailSubmtited: boolean;
    public emailFailed: boolean;
    public mobile: boolean;
    public cutoff: number;
    public currency_Object: string;
    public option: string;
    public options1: boolean;
    public options2: boolean;
    public options: boolean;
    public stock: string;
    public time: string;
    public fault: boolean;
    public Exportdata: any [];
    public ExilioExportId: string;
    public Result: ID;
    public ExilioExportCodeError: string;

    Highcharts = Highcharts;
    
    pairings: Select[] = [
        { value: 'XRPUSDT', viewValue: 'Ripple (XRP)' },
        { value: 'BTCUSDT', viewValue: 'Bitcoin (BTC)' },
        { value: 'ETHUSDT', viewValue: 'Ethereum (ETH)' },
        { value: 'BCHUSDT', viewValue: 'Bitcoin Cash (BCH)' },
        { value: 'LTCUSDT', viewValue: 'Litecoin (LTC)' },
        { value: 'XLMUSDT', viewValue: 'Stellar (XLM)' },
        { value: 'ADAUSDT', viewValue: 'Cardano (ADA)' },
        { value: 'DOTUSDT', viewValue: 'Polkadot (DOT)' },
        { value: 'BNBUSDT', viewValue: 'Binance Coin (BNB)' },
        { value: 'DOGEUSDT', viewValue: 'Dodgecoin (DOGE)' },
        { value: 'XEMUSDT', viewValue: 'NEM (XEM)' },
        { value: 'UNIUSDT', viewValue: 'Uniswap (UNI)' },
        { value: 'TRXUSDT', viewValue: 'Tron (TRX)' },
        { value: 'LINKUSDT', viewValue: 'Chainlink (LINK)' },
        { value: 'ATOMUSDT', viewValue: 'Cosmos (ATOM)' },
        { value: 'AAVEUSDT', viewValue: 'Aave (AAVE)' },
        { value: 'XMRUSDT', viewValue: 'Monero (XMR)' },
    ];

    stocks: Select[] = [
        { value: 'AAPL', viewValue: 'Apple (AAPL)' },
        { value: 'MSFT', viewValue: 'Microsoft (MSFT)' },
        { value: 'AMZN', viewValue: 'Amazon (AMZN)' },
        { value: 'TSLA', viewValue: 'Tesla (TSLA)' },
        { value: 'GOOG', viewValue: 'Google (GOOG)' },
        { value: 'BRKA', viewValue: 'Berkshire Hathaway (BRK.A)' },
        { value: 'UNH', viewValue: 'UnitedHealth Group (UNH)' },
        { value: 'JNJ', viewValue: 'Johnson & Johnson (JNJ)' },
        { value: 'XOM', viewValue: 'Exxon Mobil (XOM)' },
        { value: 'JPM', viewValue: 'JP Morgan Chase (JPM)' },
        { value: 'NVDA', viewValue: 'NVIDIA (NVDA)' },
        { value: 'FB', viewValue: 'Meta (META)' },
        { value: 'PG', viewValue: 'Procter & Gamble (PG)' },
        { value: 'V', viewValue: 'Visa (V)' },
        { value: 'HD', viewValue: 'Home Depot (HD)' },
        { value: 'CVX', viewValue: 'Chevron (CVX)' },
        { value: 'LLY', viewValue: 'Eli Lilly (LLY)' },
    ];

    indicators: Select[] = [
        { value: 'adx', viewValue: 'ADX' },
        { value: 'rsi', viewValue: 'RSI' },
        { value: 'macd', viewValue: 'MACD' },
        { value: 'roc', viewValue: 'ROC' },
        { value: 'ui', viewValue: 'UI' },
        { value: 'obv', viewValue: 'OBV' },
        { value: 'ao', viewValue: 'AO' },
        { value: 'kama', viewValue: 'KAMA' },
        { value: 'stoch', viewValue: 'STOCH' },
        { value: 'tsi', viewValue: 'TSI' },
        { value: 'uo', viewValue: 'UO' },
        { value: 'adi', viewValue: 'ADI' },
        { value: 'fii', viewValue: 'FI' },
        { value: 'atr', viewValue: 'ATR' },
        { value: 'bb', viewValue: 'BB' },
    ];

    intervals: Select[] = [
        { value: '1m', viewValue: '1 Minute' },
        { value: '3m', viewValue: '3 Minutes' },
        { value: '5m', viewValue: '5 Minutes' },
        { value: '15m', viewValue: '15 Minutes' },
        { value: '30m', viewValue: '30 Minutes' },
        { value: '1h', viewValue: '1 Hour' },
        { value: '4h', viewValue: '4 Hours' },
        { value: '6h', viewValue: '6 Hours' },
        { value: '8h', viewValue: '8 Hours' },
        { value: '12h', viewValue: '12 Hours' },
        { value: '1d', viewValue: '1 Day' },
        // { value: '3d', viewValue: '3 Day' },
        // { value: '1w', viewValue: '1 Week' },
        // { value: '1M', viewValue: '1 Month' },
    ];

    times: Select[] = [
        { value: '1d', viewValue: '1 Day' },
        // { value: '3d', viewValue: '3 Day' },
        // { value: '1w', viewValue: '1 Week' },
        // { value: '1M', viewValue: '1 Month' },
    ];
// used to select currency
    currencies: Select[] = [
        { value: 'usd', viewValue: 'USD ($)' },
        { value: 'eur', viewValue: 'EURO (€)' },
        { value: 'aud', viewValue: 'AUD ($)' },
        { value: 'cad', viewValue: 'CAD ($)' },
        { value: 'jpy', viewValue: 'JPY (¥)' },
        { value: 'gbp', viewValue: 'GBP (£)' },
    ];

    constructor(private dashboardService: DashboardService, private _resourceService: ResourceService, private spinner: NgxSpinnerService) {
        this.ohlcChart = [];
        this.indicatorChart = [];
        this.coinPrices = [];
        this.indicator = 'rsi';
        this.interval = '1d';
        this.currency = 'usd';
        this.margin = 1;
        this.indicatorView = 'rsiView';
        this.symbol = 'XRPUSDT';
        this.adxResult = {} as ADX;
        this.rsiResult = {} as RSI;
        this.macdResult = {} as MACD;
        this.rocResult ={} as ROC;
        this.uiResult = {} as UI;
        this.obvResult ={} as OBV;
        this.lastUpdated = new Date().toLocaleTimeString();
        this.feedback = '';
        this.feedbackSubmtited = false;
        this.mobile = false;
        this.cutoff = 100;
        this.option = '1'
        this.options = true;
        this.fault = false;
        this.ExilioExportCodeError = null;
    }

    ngOnInit() {
        this.option = '1'
        if (window.screen.width < 500) {
            // 768px portrait
            this.mobile = true;
            this.cutoff = 20;
        }
        this.spinner.show();
        //Setting Chart theme based on toggle settings
        if (localStorage.getItem('prefers-color') === 'light') {
            this.chartTheme = '#FFFFFF';
            this.chartText = '#000000';
            this.setChart();
            this.setIdicatorChart();
        } else {
            this.chartTheme = '#09080A';//'#0C0C0C'; //'#00060A';//'#1D1D2F';
            this.chartText = '#ffffff';
            this.setChart();
            this.setIdicatorChart();
        }
        HC_exporting(Highcharts);
        // this.indicatorChart = this.dashboardService.getLineData();
        // this.setIdicatorChart();
        //Initialize Data
        this.getCoinPrices();
        this.updateInfo();

        // Reload Subscription every 60s
        this.refreshPeriod = 900;
        const source = interval(this.refreshPeriod * 1000);
        this.subscription = source.subscribe((val) => this.getIndicator());
    }

    switchDashboard(): void {
        this.options = true
        this.options1 = false 
        this.options2 = false

        this.option = '1'
    }

    switchDashboard1(): void {
        this.options = false
        this.options1 = true 
        this.options2 = false

        this.option = '2'
    }

    switchDashboard2(): void {
        this.options = false
        this.options1 = false 
        this.options2 = true

        this.option = '3'
    }

    getCoinPrices(): void {
        this.dashboardService.getAllCoinPrices().subscribe(
            (data) => {
                this.coinPrices = data;
                console.log(this.coinPrices)
            },
            (error) => {
                console.log(error);
            }
        );
    }

    loadCoinPrice(): void {
        this.dashboardService.getCoinPrice(this.symbol).subscribe(
            (data) => {
                this.price1 = (Math.round(data.price * 1000) / 1000).toFixed(3);
            },
            (error) => {
                console.log(error);
            }
        );
    }

    updateInfo(): void {
        this.spinner.show();
        this.getIndicator();
    }

    getIndicator(): void {
        
        if (this.symbol != this.currency){
            this.fault = false
            this.loadCoinPrice();

        if (this.currency === 'eur') {
        this.currency_Object = "€"
        } else if (this.currency === 'jpy') {
        this.currency_Object = "¥"
        } else if (this.currency === 'gbp') {
        this.currency_Object = "£"
        } else { this.currency_Object = "$"}


        this.dashboardService.getIndicator(this.interval,this.symbol, this.margin/100, this.currency, this.indicator, this.option).subscribe(
            (data) => { console.log(data);
               
                console.log(this.symbol)
                if (this.indicator === 'adx') {
                    this.adxResult = data;
                    this.indicatorView = 'adxView';
                    if (this.adxResult.candles.length > this.cutoff) {
                        this.adxResult.candles.splice(0, this.adxResult.candles.length - this.cutoff);
                    }
                    if (this.adxResult.adxData.length > this.cutoff) {
                        this.adxResult.adxData.splice(0, this.adxResult.adxData.length - this.cutoff);
                        this.adxResult.plusDI.splice(0, this.adxResult.plusDI.length - this.cutoff);
                        this.adxResult.negDI.splice(0, this.adxResult.negDI.length - this.cutoff);
                    }
                    this.ohlcChart = this.adxResult.candles;
                    this.setChart();
                    this.adxChart = this.adxResult.adxData;
                    this.adxPlusDI = this.adxResult.plusDI;
                    this.adxNegDI = this.adxResult.negDI;
                    this.price = this.adxResult.current_Price;
                    this.setIdicatorChart();
                } else if (this.indicator === 'rsi') {
                    this.rsiResult = data;
                    this.indicatorView = 'rsiView';
                    if (this.rsiResult.candles.length > this.cutoff) {
                        this.rsiResult.candles.splice(0, this.rsiResult.candles.length - this.cutoff);
                    }
                    if (this.rsiResult.indicatordataframe.length > this.cutoff) {
                        this.rsiResult.indicatordataframe.splice(0, this.rsiResult.indicatordataframe.length - this.cutoff);
                    }
                    this.ohlcChart = this.rsiResult.candles;
                    this.setChart();
                    this.indicatorChart = this.rsiResult.indicatordataframe;
                    this.price = this.rsiResult.current_Price;
                    this.setIdicatorChart();
                } else if (this.indicator === 'macd') {
                    this.macdResult = data;
                    this.indicatorView = 'macdView';
                    if (this.macdResult.candles.length > this.cutoff) {
                        this.macdResult.candles.splice(0, this.macdResult.candles.length - this.cutoff);
                    }
                    if (this.macdResult.indicatordataframe.length > this.cutoff) {
                        this.macdResult.indicatordataframe.splice(0, this.macdResult.indicatordataframe.length - this.cutoff);
                    }
                    this.ohlcChart = this.macdResult.candles;
                    this.setChart();
                    this.indicatorChart = this.macdResult.indicatordataframe;
                    this.price = this.macdResult.current_Price;
                    this.setIdicatorChart();
                } else if (this.indicator === 'roc') {
                    this.rocResult = data;
                    this.indicatorView = 'rocView';
                    if (this.rocResult.candles.length > this.cutoff) {
                        this.rocResult.candles.splice(0, this.rocResult.candles.length - this.cutoff);
                    }
                    if (this.rocResult.indicatordataframe.length > this.cutoff) {
                        this.rocResult.indicatordataframe.splice(0, this.rocResult.indicatordataframe.length - this.cutoff);
                    }
                    this.ohlcChart = this.rocResult.candles;
                    this.setChart();
                    this.indicatorChart = this.rocResult.indicatordataframe;
                    this.price = this.rocResult.current_Price;
                    this.setIdicatorChart();
                } else if (this.indicator === 'ui') {
                    this.uiResult = data;
                    this.indicatorView = 'uiView';
                    if (this.uiResult.candles.length > this.cutoff) {
                        this.uiResult.candles.splice(0, this.uiResult.candles.length - this.cutoff);
                    }
                    if (this.uiResult.indicatordataframe.length > this.cutoff) {
                        this.uiResult.indicatordataframe.splice(0, this.uiResult.indicatordataframe.length - this.cutoff);
                    }
                    this.ohlcChart = this.uiResult.candles;
                    this.setChart();
                    this.indicatorChart = this.uiResult.indicatordataframe;
                    this.price = this.uiResult.current_Price;
                    this.setIdicatorChart();
                } else if (this.indicator === 'obv') {
                    this.obvResult = data;
                    this.indicatorView = 'obvView';
                    if (this.obvResult.candles.length > this.cutoff) {
                        this.obvResult.candles.splice(0, this.obvResult.candles.length - this.cutoff);
                    }
                    if (this.obvResult.indicatordataframe.length > this.cutoff) {
                        this.obvResult.indicatordataframe.splice(0, this.obvResult.indicatordataframe.length - this.cutoff);
                    }
                    this.ohlcChart = this.obvResult.candles;
                    this.setChart();
                    this.indicatorChart = this.obvResult.indicatordataframe;
                    this.price = this.obvResult.current_Price;
                    this.setIdicatorChart();
                } else if (this.indicator === 'ao') {
                    this.aoResult = data;
                    this.indicatorView = 'aoView';
                    if (this.aoResult.candles.length > this.cutoff) {
                        this.aoResult.candles.splice(0, this.aoResult.candles.length - this.cutoff);
                    }
                    if (this.aoResult.indicatordataframe.length > this.cutoff) {
                        this.aoResult.indicatordataframe.splice(0, this.aoResult.indicatordataframe.length - this.cutoff);
                    }
                    this.ohlcChart = this.aoResult.candles;
                    this.setChart();
                    this.indicatorChart = this.aoResult.indicatordataframe;
                    this.price = this.aoResult.current_Price;
                    this.setIdicatorChart();
                } else if (this.indicator === 'kama') {
                    this.kamaResult = data;
                    this.indicatorView = 'kamaView';
                    if (this.kamaResult.candles.length > this.cutoff) {
                        this.kamaResult.candles.splice(0, this.kamaResult.candles.length - this.cutoff);
                    }
                    if (this.kamaResult.indicatordataframe.length > this.cutoff) {
                        this.kamaResult.indicatordataframe.splice(0, this.kamaResult.indicatordataframe.length - this.cutoff);
                    }
                    this.ohlcChart = this.kamaResult.candles;
                    this.setChart();
                    this.indicatorChart = this.kamaResult.indicatordataframe;
                    this.price = this.kamaResult.current_Price;
                    this.setIdicatorChart();
                } else if (this.indicator === 'stoch') {
                    this.stochResult = data;
                    this.indicatorView = 'stochView';
                    if (this.stochResult.candles.length > this.cutoff) {
                        this.stochResult.candles.splice(0, this.stochResult.candles.length - this.cutoff);
                    }
                    if (this.stochResult.indicatordataframe.length > this.cutoff) {
                        this.stochResult.indicatordataframe.splice(0, this.stochResult.indicatordataframe.length - this.cutoff);
                    }
                    this.ohlcChart = this.stochResult.candles;
                    this.setChart();
                    this.indicatorChart = this.stochResult.indicatordataframe;
                    this.price = this.stochResult.current_Price;
                    this.setIdicatorChart();
                } else if (this.indicator === 'tsi') {
                    this.tsiResult = data;
                    this.indicatorView = 'tsiView';
                    if (this.tsiResult.candles.length > this.cutoff) {
                        this.tsiResult.candles.splice(0, this.tsiResult.candles.length - this.cutoff);
                    }
                    if (this.tsiResult.indicatordataframe.length > this.cutoff) {
                        this.tsiResult.indicatordataframe.splice(0, this.tsiResult.indicatordataframe.length - this.cutoff);
                    }
                    this.ohlcChart = this.tsiResult.candles;
                    this.setChart();
                    this.indicatorChart = this.tsiResult.indicatordataframe;
                    this.price = this.tsiResult.current_Price;
                    this.setIdicatorChart();
                } else if (this.indicator === 'uo') {
                    this.uoResult = data;
                    this.indicatorView = 'uoView';
                    if (this.uoResult.candles.length > this.cutoff) {
                        this.uoResult.candles.splice(0, this.uoResult.candles.length - this.cutoff);
                    }
                    if (this.uoResult.indicatordataframe.length > this.cutoff) {
                        this.uoResult.indicatordataframe.splice(0, this.uoResult.indicatordataframe.length - this.cutoff);
                    }
                    this.ohlcChart = this.uoResult.candles;
                    this.setChart();
                    this.indicatorChart = this.uoResult.indicatordataframe;
                    this.price = this.uoResult.current_Price;
                    this.setIdicatorChart();
                } else if (this.indicator === 'adi') {
                    this.adiResult = data;
                    this.indicatorView = 'adiView';
                    if (this.adiResult.candles.length > this.cutoff) {
                        this.adiResult.candles.splice(0, this.adiResult.candles.length - this.cutoff);
                    }
                    if (this.adiResult.indicatordataframe.length > this.cutoff) {
                        this.adiResult.indicatordataframe.splice(0, this.adiResult.indicatordataframe.length - this.cutoff);
                    }
                    this.ohlcChart = this.adiResult.candles;
                    this.setChart();
                    this.indicatorChart = this.adiResult.indicatordataframe;
                    this.price = this.adiResult.current_Price;
                    this.setIdicatorChart();
                } else if (this.indicator === 'fii') {
                    this.fiiResult = data;
                    this.indicatorView = 'fiiView';
                    if (this.fiiResult.candles.length > this.cutoff) {
                        this.fiiResult.candles.splice(0, this.fiiResult.candles.length - this.cutoff);
                    }
                    if (this.fiiResult.indicatordataframe.length > this.cutoff) {
                        this.fiiResult.indicatordataframe.splice(0, this.fiiResult.indicatordataframe.length - this.cutoff);
                    }
                    this.ohlcChart = this.fiiResult.candles;
                    this.setChart();
                    this.indicatorChart = this.fiiResult.indicatordataframe;
                    this.price = this.fiiResult.current_Price;
                    this.setIdicatorChart();
                } else if (this.indicator === 'atr') {
                    this.atrResult = data;
                    this.indicatorView = 'atrView';
                    if (this.atrResult.candles.length > this.cutoff) {
                        this.atrResult.candles.splice(0, this.atrResult.candles.length - this.cutoff);
                    }
                    if (this.atrResult.indicatordataframe.length > this.cutoff) {
                        this.atrResult.indicatordataframe.splice(0, this.atrResult.indicatordataframe.length - this.cutoff);
                    }
                    this.ohlcChart = this.atrResult.candles;
                    this.setChart();
                    this.indicatorChart = this.atrResult.indicatordataframe;
                    this.price = this.atrResult.current_Price;
                    this.setIdicatorChart();
                } else if (this.indicator === 'bb') {
                    this.bbResult = data;
                    this.indicatorView = 'bbView';
                    if (this.bbResult.candles.length > this.cutoff) {
                        this.bbResult.candles.splice(0, this.bbResult.candles.length - this.cutoff);
                    }
                    if (this.bbResult.indicatordataframe.length > this.cutoff) {
                        this.bbResult.indicatordataframe.splice(0, this.bbResult.indicatordataframe.length - this.cutoff);
                    }
                    this.ohlcChart = this.bbResult.candles;
                    this.setChart();
                    this.indicatorChart = this.bbResult.indicatordataframe;
                    this.price = this.bbResult.current_Price;
                    this.setIdicatorChart();
                }        
            
                setTimeout(() => {
                    this.spinner.hide();
                }, 1000);
            },
            (error) => {
                console.log(error);
                this.spinner.hide();
                //add code here for popup saying an error occured
            }
        );
        this.lastUpdated = new Date().toLocaleTimeString();
        }

        else {
            this.fault = true;
            this.spinner.hide()
        }
    }

    redirectLearnMore(): void {
            window.open('https://www.amazon.com/dp/B0BCD511L1', '_blank');
    }

    downloadFile(data: any, type: string, fileType: string){
        let blob = new Blob([data], {type: type});
        const element = document.createElement('a');
        element.href = URL.createObjectURL(blob);
        var myDate = new Date();
        element.download = `exilio-ebook.${fileType}`;
        document.body.appendChild(element);
        element.click();
    }

    setChart(): void {
        this.Highcharts.theme = {
            colors: [
                '#2b908f',
                '#90ee7e',
                '#f45b5b',
                '#7798BF',
                '#aaeeee',
                '#ff0066',
                '#eeaaee',
                '#55BF3B',
                '#DF5353',
                '#7798BF',
                '#aaeeee',
                '#1d1e2e',
            ],
            chart: {
                backgroundColor: this.chartTheme,
                style: {
                    fontFamily: "'Unica One', sans-serif",
                },
                plotBorderColor: '#606063',
            },
            title: {
                style: {
                    color: this.chartText,
                    textTransform: 'uppercase',
                    fontSize: '20px',
                },
            },
            subtitle: {
                style: {
                    color: this.chartText,
                    textTransform: 'uppercase',
                },
            },
            xAxis: {
                gridLineColor: '#707073',
                labels: {
                    style: {
                        color: this.chartText,
                    },
                },
                lineColor: '#707073',
                minorGridLineColor: '#505053',
                tickColor: '#707073',
                title: {
                    style: {
                        color: this.chartText,
                    },
                },
            },
            yAxis: {
                gridLineColor: '#707073',
                labels: {
                    style: {
                        color: this.chartText,
                    },
                },
                lineColor: '#707073',
                minorGridLineColor: '#505053',
                tickColor: '#707073',
                tickWidth: 1,
                title: {
                    style: {
                        color: this.chartText,
                    },
                },
            },
            tooltip: {
                backgroundColor: '#B8CCC8',
                style: {
                    color: '#000000',
                },
            },
            plotOptions: {
                series: {
                    dataLabels: {
                        color: this.chartText,
                        style: {
                            fontSize: '13px',
                        },
                    },
                    // marker: {
                    //     lineColor: '#333',
                    // },
                },
                boxplot: {
                    fillColor: '#505053',
                },
                candlestick: {
                    lineColor: 'white',
                },
                errorbar: {
                    color: 'white',
                },
            },
            legend: {
                backgroundColor: this.chartTheme,
                itemStyle: {
                    color: this.chartText,
                },
                itemHoverStyle: {
                    color: '#FFF',
                },
                itemHiddenStyle: {
                    color: '#606063',
                },
                title: {
                    style: {
                        color: '#C0C0C0',
                    },
                },
            },
        };
        // Apply the theme
        Highcharts.setOptions(this.Highcharts.theme);

        this.plotOptions = {
            rangeSelector: {
                selected: 1,
            },
            title: {
                text: this.symbol.slice(0, -4) + ' OHLC',
            },
            chart: {
                height: 400,
            },
            xAxis: {
                type: 'datetime',
                tickLength: 0,

            },
            yAxis: [
                {
                    labels: {
                        align: 'right',
                        x: -3,
                    },
                    title: {
                        text: 'OHLC',
                    },
                    height: '100%',
                    // gridLineWidth: 0,
                    lineWidth: 2,
                    resize: {
                        enabled: true,
                    },
                },
            ],
            series: [
                {
                    type: 'candlestick',
                    name: this.symbol.slice(0,-4),
                    upColor: '#589636',
                    upLineColor: '#589636',
                    lineColor: '#B2022F',
                    color: '#B2022F',
                    data: this.ohlcChart,
                },
            ],
            tooltip: {
                split: true,
                valueSuffix: ' ',
            },
            credits: {
                enabled: false,
            },
            exporting: {
                enabled: true,
            },
        };

        HC_exporting(Highcharts);

        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 300);
    }

    setIdicatorChart(): void {
        Highcharts.setOptions(this.Highcharts.theme);

        this.rsiOptions = {
            rangeSelector: {
                selected: 1,
            },
            title: {
                text: this.indicator,
            },
            chart: {
                height: 350,
            },
            xAxis: {
                type: 'datetime',
            },
            yAxis: [
                {
                    labels: {
                        align: 'right',
                        x: -3,
                    },
                    title: {
                        text: '',
                    },
                    max: 100,
                    min: 0,
                    height: '100%',
                    lineWidth: 2,
                    resize: {
                        enabled: true,
                    },

                    plotLines: [
                        {
                            color: this.chartText,
                            width: 2,
                            value: 70,
                            dashStyle: 'dot',
                            label: {
                                text: 'Overbought (70)',
                                align: 'right',
                                style: {
                                    color: this.chartText,
                                },
                                y: -20 /*moves label down*/,
                                x: 5 /*moves label left*/,
                            },
                        },
                        {
                            color: this.chartText,
                            width: 2,
                            value: 30,
                            dashStyle: 'dot',
                            label: {
                                text: 'Oversold (30)',
                                align: 'right',
                                style: {
                                    color: this.chartText,
                                },
                                y: 25 /*moves label down*/,
                                x: 5 /*moves label left*/,
                            },
                        },
                    ],
                },
            ],
            series: [
                {
                    name: this.indicator,
                    lineColor: '#B2022F',
                    marker: {
                          enabled: false},
                    data: this.indicatorChart,
                    dataGrouping: {
                        units: [
                            [
                                'week', // unit name
                                [1], // allowed multiples
                            ],
                            ['month', [1, 2, 3, 4, 6]],
                        ],
                    },
                },
            ],
            tooltip: {
                valueDecimals: 2,
            },
            credits: {
                enabled: false,
            },
            exporting: {
                enabled: true,
            },
        };

        this.adxOptions = {
            rangeSelector: {
                selected: 1,
            },
            title: {
                text: this.indicator,
            },
            chart: {
                height: 350,
            },
            xAxis: {
                type: 'datetime',
            },
            yAxis: [
                {
                    labels: {
                        align: 'right',
                        x: -3,
                    },
                    title: {
                        text: '',
                    },
                    height: '100%',
                    lineWidth: 2,
                    resize: {
                        enabled: true,
                    },
                },
            ],
            series: [
                {
                    name: 'ADX',
                    lineColor: '#add8e6',
                    marker: {
                          enabled: false},
                    data: this.adxChart,
                },
                {
                    name: 'DI+',
                    lineColor: '#589636',
                    marker: {
                          enabled: false},
                    data: this.adxPlusDI,
                },
                {
                    name: 'DI-',
                    lineColor: '#B2022F',
                    marker: {
                          enabled: false},
                    data: this.adxNegDI,
                },
            ],
            tooltip: {
                valueDecimals: 2,
            },
            credits: {
                enabled: false,
            },
            exporting: {
                enabled: true,
            },
        };

        this.macdOptions = {
            rangeSelector: {
                selected: 1,
            },
            title: {
                text: this.indicator,
            },
            chart: {
                height: 350,
            },
            xAxis: {
                type: 'datetime',
            },
            yAxis: [
                {
                    labels: {
                        align: 'right',
                        x: -3,
                    },
                    title: {
                        text: '',
                    },
                    height: '100%',
                    lineWidth: 2,
                    resize: {
                        enabled: true,
                    },
                                        plotLines: [
                        {
                            color: this.chartText,
                            width: 2,
                            value: 0,
                            dashStyle: 'line',
                            label: {
                                text: 'Inversion Point',
                                align: 'left',
                                style: {
                                    color: this.chartText,
                                },
                                y: -20 /*moves label down*/,
                                x: 5 /*moves label left*/,
                            },
                        },
                    ],

                },
            ],
            series: [
                {
                    name: 'MACD',
                    lineColor: '#B2022F',
                    marker: {
                          enabled: false},
                    data: this.indicatorChart,
                },
            ],
            tooltip: {
                valueDecimals: 2,
            },
            credits: {
                enabled: false,
            },
            exporting: {
                enabled: true,
            },
        };
        this.rocOptions = {
            rangeSelector: {
                selected: 1,
            },
            title: {
                text: this.indicator,
            },
            chart: {
                height: 350,
            },
            xAxis: {
                type: 'datetime',
            },
            yAxis: [
                {
                    labels: {
                        align: 'right',
                        x: -3,
                    },
                    title: {
                        text: '',
                    },
                    height: '100%',
                    lineWidth: 2,
                    resize: {
                        enabled: true,
                    },
                },
            ],
            series: [
                {
                    name: 'ROC',
                    lineColor: '#B2022F',
                    marker: {
                          enabled: false},
                    data: this.indicatorChart,
                },
            ],
            tooltip: {
                valueDecimals: 2,
            },
            credits: {
                enabled: false,
            },
            exporting: {
                enabled: true,
            },
        };

        this.uiOptions = {
            rangeSelector: {
                selected: 1,
            },
            title: {
                text: this.indicator,
            },
            chart: {
                height: 350,
            },
            xAxis: {
                type: 'datetime',
            },
            yAxis: [
                {
                    labels: {
                        align: 'right',
                        x: -3,
                    },
                    title: {
                        text: '',
                    },
                    height: '100%',
                    lineWidth: 2,
                    resize: {
                        enabled: true,
                    },
                },
            ],
            series: [
                {
                    name: 'UI',
                    lineColor: '#B2022F',
                    marker: {
                          enabled: false},
                    data: this.indicatorChart,
                },
            ],
            tooltip: {
                valueDecimals: 2,
            },
            credits: {
                enabled: false,
            },
            exporting: {
                enabled: true,
            },
        };
        this.obvOptions = {
            rangeSelector: {
                selected: 1,
            },
            title: {
                text: this.indicator,
            },
            chart: {
                height: 350,
            },
            xAxis: {
                type: 'datetime',
            },
            yAxis: [
                {
                    labels: {
                        align: 'right',
                        x: -3,
                    },
                    title: {
                        text: '',
                    },
                    height: '100%',
                    lineWidth: 2,
                    resize: {
                        enabled: true,
                    },
                },
            ],
            series: [
                {
                    name: 'OBV',
                    lineColor: '#B2022F',
                    marker: {
                          enabled: false},
                    data: this.indicatorChart,
                },
            ],
            tooltip: {
                valueDecimals: 2,
            },
            credits: {
                enabled: false,
            },
            exporting: {
                enabled: true,
            },
        };
        this.aoOptions = {
            rangeSelector: {
                selected: 1,
            },
            title: {
                text: this.indicator,
            },
            chart: {
                height: 350,
            },
            xAxis: {
                type: 'datetime',
            },
            yAxis: [
                {
                    labels: {
                        align: 'right',
                        x: -3,
                    },
                    title: {
                        text: '',
                    },
                    height: '100%',
                    lineWidth: 2,
                    resize: {
                        enabled: true,
                    },
                },
            ],
            series: [
                {
                    name: 'AO',
                    lineColor: '#B2022F',
                    marker: {
                          enabled: false},
                    data: this.indicatorChart,
                },
            ],
            tooltip: {
                valueDecimals: 2,
            },
            credits: {
                enabled: false,
            },
            exporting: {
                enabled: true,
            },
        };
        this.kamaOptions = {
            rangeSelector: {
                selected: 1,
            },
            title: {
                text: this.indicator,
            },
            chart: {
                height: 350,
            },
            xAxis: {
                type: 'datetime',
            },
            yAxis: [
                {
                    labels: {
                        align: 'right',
                        x: -3,
                    },
                    title: {
                        text: '',
                    },
                    height: '100%',
                    lineWidth: 2,
                    resize: {
                        enabled: true,
                    },
                },
            ],
            series: [
                {
                    name: 'KAMA',
                    lineColor: '#B2022F',
                    marker: {
                          enabled: false},
                    data: this.indicatorChart,
                },
            ],
            tooltip: {
                valueDecimals: 2,
            },
            credits: {
                enabled: false,
            },
            exporting: {
                enabled: true,
            },
        };
        this.stochOptions = {
            rangeSelector: {
                selected: 1,
            },
            title: {
                text: this.indicator,
            },
            chart: {
                height: 350,
            },
            xAxis: {
                type: 'datetime',
            },
            yAxis: [
                {
                    labels: {
                        align: 'right',
                        x: -3,
                    },
                    title: {
                        text: '',
                    },
                    height: '100%',
                    lineWidth: 2,
                    resize: {
                        enabled: true,
                    },
                },
            ],
            series: [
                {
                    name: 'STOCH',
                    lineColor: '#B2022F',
                    marker: {
                          enabled: false},
                    data: this.indicatorChart,
                },
            ],
            tooltip: {
                valueDecimals: 2,
            },
            credits: {
                enabled: false,
            },
            exporting: {
                enabled: true,
            },
        };
        this.tsiOptions = {
            rangeSelector: {
                selected: 1,
            },
            title: {
                text: this.indicator,
            },
            chart: {
                height: 350,
            },
            xAxis: {
                type: 'datetime',
            },
            yAxis: [
                {
                    labels: {
                        align: 'right',
                        x: -3,
                    },
                    title: {
                        text: '',
                    },
                    height: '100%',
                    lineWidth: 2,
                    resize: {
                        enabled: true,
                    },
                },
            ],
            series: [
                {
                    name: 'TSI',
                    lineColor: '#B2022F',
                    marker: {
                          enabled: false},
                    data: this.indicatorChart,
                },
            ],
            tooltip: {
                valueDecimals: 2,
            },
            credits: {
                enabled: false,
            },
            exporting: {
                enabled: true,
            },
        };
        this.uoOptions = {
            rangeSelector: {
                selected: 1,
            },
            title: {
                text: this.indicator,
            },
            chart: {
                height: 350,
            },
            xAxis: {
                type: 'datetime',
            },
            yAxis: [
                {
                    labels: {
                        align: 'right',
                        x: -3,
                    },
                    title: {
                        text: '',
                    },
                    height: '100%',
                    lineWidth: 2,
                    resize: {
                        enabled: true,
                    },
                },
            ],
            series: [
                {
                    name: 'UO',
                    lineColor: '#B2022F',
                    marker: {
                          enabled: false},
                    data: this.indicatorChart,
                },
            ],
            tooltip: {
                valueDecimals: 2,
            },
            credits: {
                enabled: false,
            },
            exporting: {
                enabled: true,
            },
        };
        this.adiOptions = {
            rangeSelector: {
                selected: 1,
            },
            title: {
                text: this.indicator,
            },
            chart: {
                height: 350,
            },
            xAxis: {
                type: 'datetime',
            },
            yAxis: [
                {
                    labels: {
                        align: 'right',
                        x: -3,
                    },
                    title: {
                        text: '',
                    },
                    height: '100%',
                    lineWidth: 2,
                    resize: {
                        enabled: true,
                    },
                },
            ],
            series: [
                {
                    name: 'ADI',
                    lineColor: '#B2022F',
                    marker: {
                          enabled: false},
                    data: this.indicatorChart,
                },
            ],
            tooltip: {
                valueDecimals: 2,
            },
            credits: {
                enabled: false,
            },
            exporting: {
                enabled: true,
            },
        };
        this.fiiOptions = {
            rangeSelector: {
                selected: 1,
            },
            title: {
                text: this.indicator,
            },
            chart: {
                height: 350,
            },
            xAxis: {
                type: 'datetime',
            },
            yAxis: [
                {
                    labels: {
                        align: 'right',
                        x: -3,
                    },
                    title: {
                        text: '',
                    },
                    height: '100%',
                    lineWidth: 2,
                    resize: {
                        enabled: true,
                    },
                },
            ],
            series: [
                {
                    name: 'FII',
                    lineColor: '#B2022F',
                    marker: {
                          enabled: false},
                    data: this.indicatorChart,
                },
            ],
            tooltip: {
                valueDecimals: 2,
            },
            credits: {
                enabled: false,
            },
            exporting: {
                enabled: true,
            },
        };
        this.atrOptions = {
            rangeSelector: {
                selected: 1,
            },
            title: {
                text: this.indicator,
            },
            chart: {
                height: 350,
            },
            xAxis: {
                type: 'datetime',
            },
            yAxis: [
                {
                    labels: {
                        align: 'right',
                        x: -3,
                    },
                    title: {
                        text: '',
                    },
                    height: '100%',
                    lineWidth: 2,
                    resize: {
                        enabled: true,
                    },
                },
            ],
            series: [
                {
                    name: 'ATR',
                    lineColor: '#B2022F',
                    marker: {
                          enabled: false},
                    data: this.indicatorChart,
                },
            ],
            tooltip: {
                valueDecimals: 2,
            },
            credits: {
                enabled: false,
            },
            exporting: {
                enabled: true,
            },
        };
        this.bbOptions = {
            rangeSelector: {
                selected: 1,
            },
            title: {
                text: this.indicator,
            },
            chart: {
                height: 350,
            },
            xAxis: {
                type: 'datetime',
            },
            yAxis: [
                {
                    labels: {
                        align: 'right',
                        x: -3,
                    },
                    title: {
                        text: '',
                    },
                    height: '100%',
                    lineWidth: 2,
                    resize: {
                        enabled: true,
                    },
                },
            ],
            series: [
                {
                    name: 'BB',
                    lineColor: '#B2022F',
                    marker: {
                          enabled: false},
                    data: this.indicatorChart,
                },
            ],
            tooltip: {
                valueDecimals: 2,
            },
            credits: {
                enabled: false,
            },
            exporting: {
                enabled: true,
            },
        };

        HC_exporting(Highcharts);

        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 300);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    downloadCSV() {

        //Gets ID from Exilio-Export ID Input Field and verifies it is within the database
        var exportIdInput = document.getElementById('exportIdInput') as HTMLInputElement;
        var exportId = exportIdInput.value;
        this.ExilioExportId = exportId
        console.log('Exilio-Export ID:', exportId);

        //Runs getindicator function and then allows CSV download of data
        this.dashboardService.checkExilioExportID(this.ExilioExportId).subscribe(
            (data) => {
                this.Result = data
                console.log(this.Result)

                if (this.Result.CodeValidity === 'Correct') {

                    this.ExilioExportCodeError = null;
                    
                this.dashboardService.getIndicator(this.interval, this.symbol, this.margin / 100, this.currency, this.indicator, this.option).subscribe(
                    (Exportdata) => {
                        console.log(Exportdata)
                        // Extract candles data from Exportdata
                        console.log(Exportdata['ExilioExport'])
                        const candles = Exportdata['ExilioExport'];
                        const indicatordata = Exportdata['indicatordataframe']
                        

                        // Prepare data for CSV
                        const data: Array<Array<string | number>> = [
                            ['Date', 'Open', 'High', 'Low', 'Close', 'Volume', 'Indicator value', 'Signal']
                        ];
            
                        // Iterate through candles and add formatted data to the data array
                        candles.forEach(candle => {
                            const [timestamp, Open, High, Low, Close, Volume, indicatorValue, signal] = candle; // Assuming candle is an array with 6 elements
                            
            
                            // Convert timestamp to Date object
                            const date = new Date(timestamp);
                            
                            // Format the date as "DD-MON-YYYY"
                            const formattedDate = `${date.getDate()}-${this.getMonthAbbreviation(date.getMonth())}-${date.getFullYear()}`;
            
                            // Add formatted data to the data array
                            data.push([formattedDate, Open, High, Low, Close, Volume, indicatorValue, signal]);
                        });
            
                        // Convert data to CSV format
                        const csvContent: string = data.map(row => row.join(',')).join('\n');
            
                        // Create a Blob object with CSV data
                        const blob: Blob = new Blob([csvContent], { type: 'text/csv' });
            
                        // Create a temporary anchor element and trigger the download
                        const url: string = window.URL.createObjectURL(blob);
                        const a: HTMLAnchorElement = document.createElement('a');
                        a.href = url;
                        a.download = 'ExilioExport.csv';
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                        document.body.removeChild(a);
                    }
                );



                } else {
                    this.ExilioExportCodeError = 'failed';
                }

            }
            )
        //If key matches then run csv downoad code 

        //if key does not match, display incorrect key error



    }
    
    // Function to get abbreviated month name from month number (0 to 11)
    getMonthAbbreviation(month) {
        const months = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        return months[month];
    }
    
    
    
}
