<div *ngIf="this.mobile" style="overflow-x: hidden;">

<div class="yeo-slogan">
    <div class="yeo-header">
        <div class="columns">
            <div class="column centered col-12">
                <header class="navbar">
                    <div class="column centered col-7">
                        <a class="navbar-mobile logo">
                            <img style="max-height:25px;" class="logo-img" src="assets/landing/images/icon.png" alt="" /><span>Exilio Labs</span>
                        </a>
                    </div>
                </header>
                <br>
                    <div class="navbar-section column centered col-12">
                        <a style="font-size:small;" class="btn btn-link" (click)="goToId('we-do')">What we do</a>
                        <a style="font-size:small;" class="btn btn-link" (click)="goToId('we-work')">How we work</a>
                        <a style="font-size:small;" class="btn btn-link" (click)="goToId('price')">Plans</a>
                        <a style="font-size:small;" class="btn btn-link"  href="https://www.exiliolabs.com/#/default/ebook" target="_blank">Our Book</a>
                    </div>
                    <br>
            </div>
        </div>
    </div>
    <div class="slogan">
        <div class="columns">
            <div class="column centered col-10">
                <div class="slogan-content">
                    <h1>
                        <span class="slogan-bold">Cryptocurrency</span>
                        <span class="slogan-bold">Technical Analysis</span>
                        <span class="slogan-bold">Trading Platform </span>
                    </h1>
                    <p>
                        Exilio, from the latin to take a leap, Is the world's first devoted platform for cryptocurrency
                        technical analysis developed to allow users of all levels to begin their journey in the
                        cryptocurrency space
                    </p>
                    <a
                        style="margin-left: 5px; margin-bottom: 2px;"
                        class="btn btn-primary btn-sm btn-start"
                        routerLinkActive="list-item-active"
                        target="_blank"
                        routerLink="/default"
                        >The Platform</a
                    >
                    <a
                        style="margin-left: 5px; margin-top: 2px;"
                        class="btn btn-primary btn-sm btn-start"
                        routerLinkActive="list-item-active"
                        target="_blank"
                        routerLink="/sim-default"
                        >The Simulator</a
                    >
                </div>
            </div>

        </div>
    </div>
</div>
<div class="yeo-client">
    <div class="yeo-client-list">
        <div class="columns">
            <div class="column centered col-11">
                <h3 class="feature-title">Supported Cryptocurrencies</h3>
                <div class="client-logo">
                    <a>
                        <img matTooltip="Ripple" src="assets/landing/images/xrp-logo.png" height="60px" alt="" />
                    </a>
                    <a>
                        <img matTooltip="Bitcoin" src="assets/landing/images/btc-logo.png" height="60px" alt="" />
                    </a>
                    <a>
                        <img matTooltip="Binance Coin" src="assets/landing/images/bnb-logo.png" height="60px" alt="" />
                    </a>
                    <a>
                        <img matTooltip="Dash" src="assets/landing/images/dash-logo.png" height="60px" alt="" />
                    </a>
                    <a>
                        <img matTooltip="Ethereum" src="assets/landing/images/eth-logo.png" height="60px" alt="" />
                    </a>
                </div>
                <h3 class="feature-title" style="font-size: small;">And Many More...</h3>
            </div>
        </div>
    </div>
</div>
<div class="yeo-do" id="we-do">
    <div class="container yeo-body">
        <div style="width:100%">
            <div class="column centered col-7">
                <h2 class="feature-title">What We Do</h2>
            </div>
            <br>
        <div class="column centered col-7">
            <div class="yeo-do-content">
                <img style="max-width: 10vw; min-width: 35px;" src="assets/landing/images/what-we-do-1.svg" alt="" />
                <span style="color: #645664;" class="name"> Technical Analysis</span>
                <!-- <p>
                    Exilio offers Users the ability to perform technical analysis on live data for a multitude of
                    cryptocurrencies.
                </p> -->
            </div>
        </div>
        <div class="column centered col-7">
            <div class="yeo-do-content">
                <img style="max-width: 10vw; min-width: 35px;" src="assets/landing/images/what-we-do-3.svg" alt="" />
                <span style="color: #645664;" class="name"> Indicator Signals</span>
                <!-- <p>
                    Users can test indicators accuracy in returning a specified margin to allow for short term
                    traders to minimise risk.
                </p> -->
            </div>
        </div>
        <div class="column centered col-7">
            <div class="yeo-do-content">
                <img style="max-width: 10vw; min-width: 35px;" src="assets/landing/images/what-we-do-2.svg" alt="" />
                <span style="color: #645664;" class="name"> Premum Book</span>
                <!-- <p>
                    Exilio provides e-books to upskill users in technical analysis and minimise their trading risks
                    reach their full potential.
                </p> -->
            </div>
        </div>
        </div>
    </div>
</div>
<div class="yeo-open-source" id="we-work" style="width:99%;">
    <div class="container yeo-body" >
        <div style="width:100%;">
            <div class="column centered col-7">
                <div class="yeo-do-content">
                    <h2 class="feature-title">The Analytics Platform</h2>
                    <p style="width: 100% ; font-size: small; ">
                        Exilio Lab leverages the advantages of automated technical analysis through the use of our Analytics
                        Engine that allows users to run historical checks on the accuracy of trading indicators.
                    </p>
                 </div>
            </div>
            <div class="column centered col-8">
                <a routerLinkActive="list-item-active" target="_blank" routerLink="/default">
                    <img style="width: 100%;" class="platform-img" src="assets/landing/images/platform.jpg" alt="" />
                </a>
                <br />
                <br />
            </div>
        </div>
    </div>
</div>
<div class="yeo-open-source" style="width:99%;">
    <div class="container yeo-body">
        <div style="width:100%">
            <div class="column centered col-7">
                <h2 class="feature-title">The Simulator Engine</h2>
                <p style="width: 95% ; padding-right: 2.5%; padding-left:2.5%; font-size: small; ">
                    Exilio Sim is a testing platform that allows user to test there trading ability. Users generate a
                    score based on choosing the correct decision and can see where they stand among the population on
                    our leader board.
                </p>
            </div>
            <div class="column centered col-8">
                <a routerLinkActive="list-item-active" target="_blank" routerLink="/sim-default">
                    <img style="width: 95%;" class="platform-img" src="assets/landing/images/sim.jpg" alt="" />
                </a>
                <br />
                <br />
            </div>
        </div>
    </div>
</div>
<div class="yeo-work" id="price">
    <div class="container yeo-body">
        <div style="width:100%">
            <div style="width:100%">
                <h2 class="feature-title">How We Work</h2>
            </div>
            <div style="width:95%">
                <h2>
                    We've built an <span class="yeo-work-feature-bold">Analysis Engine</span> that will do the heavy
                    lifting for you.
                </h2>
            </div>
        </div>
    </div>
</div>
<div>
    <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
    <stripe-pricing-table pricing-table-id="prctbl_1NW3AnHwGvYDPSamqkrA31ky"
    publishable-key="pk_live_51IRLvIHwGvYDPSamRE3oKM3xMyObgXpeGw0mLS9tiP8b7UyzMeYSE6v8ykXsffh0Zg4nWqAuMugJ1RUKUyAzn28s00WXkR87uQ">
    </stripe-pricing-table>
</div>
<div class="yeo-open-source" id="contact">
    <div class="container yeo-body">
        <div class="columns">
            <div class="column centered col-7">
                <h2 class="feature-title">Our Inbox is Always Open!</h2>
            </div>
            <div class="column centered col-7">
                <img class="open-source-img" src="assets/landing/images/open-source.svg" alt="" />
                <h2 class="slogan-bold"></h2>
                <a routerLink="default/contact" target="_blank" class="btn btn-lg btn-open-source">Contact Us!</a>
            </div>
        </div>
    </div>
</div>
<div class="yeo-footer">
    <div class="container yeo-body">
        <div class="columns">
            <div class="column col-2">
                <div class="yeo-footer-content-mobile">
                    <h4>Product</h4>
                    <ul class="nav">
                        <li class="nav-item">
                            <a routerLink="default/pricing">Pricing</a>
                        </li>
                        <li class="nav-item">
                            <a  href="https://www.exiliolabs.com/#/default/ebook" target="_blank"> Book</a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="default/">Exilio Export</a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="default/cancel-subscription">Cancel Subscription</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="column col-2">
                <div class="yeo-footer-content-mobile">
                    <h4>Company</h4>
                    <ul class="nav">
                        <li class="nav-item">
                            <a routerLink="default/about">About Exilio</a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="default/tos">Terms of Service</a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="default/privacy">Privacy Policy</a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="default/contact">Contact Us</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="column centered col-2">
                <div class="yeo-footer-content-mobile">
                    <h4>© ExilioLabs 2022</h4>
                    <a class="navbar-brand logo">
                        <img class="logo-img" src="assets/landing/images/icon.png" alt="" />
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
</div>



<!-- /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// -->

<script>
   function toggleZoomScreen() {
       document.body.style.zoom = "90%";
   }
</script>

<div *ngIf="!this.mobile" style="overflow-x: hidden;" onload="toggleZoomScreen();"> <!-- added in style="overflow-x: hidden;" to stop horizontal scrollbar appearing -->
    <div class="yeo-slogan" >
        <div class="container yeo-header">
            <div class="columns">
                <div class="column col-12">
                    <header class="navbar">
                        <section class="navbar-section">
                            <a class="navbar-brand logo">
                                <img class="logo-img" src="assets/landing/images/icon.png" alt="" /><span style="font-family: 'Cooper Black';"><b> Exilio Labs</b></span>
                            </a>
                        </section>
                        <section class="navbar-section">
                            <a class="btn btn-link" (click)="goToId('we-do')">What we do</a>
                            <a class="btn btn-link" (click)="goToId('we-work')">The Platforms</a>
                            <a class="btn btn-link" (click)="goToId('price')">Pricing</a>
                            <a class="btn btn-link" href="https://www.exiliolabs.com/#/default/ebook" target="_blank">Our Book</a>
                        </section>
                    </header>
                </div>
            </div>
        </div>
        <div class="container slogan">
            <div class="columns">
                <div class="column col-7 col-sm-12">
                    <div class="slogan-content">
                        <h1>
                            <span class="slogan-bold">Cryptocurrency</span>
                            <span class="slogan-bold">Technical Analysis</span>
                            <span class="slogan-bold">Trading Platform </span>
                        </h1>
                        <p>
                            Exilio, from the latin to take a leap, Is the world's first devoted platform for cryptocurrency
                            technical analysis developed to allow users of all levels to begin their journey in the
                            cryptocurrency space
                        </p>
                        <a
                            class="btn btn-primary btn-lg btn-start"
                            routerLinkActive="list-item-active"
                            target="_blank"
                            routerLink="/default"
                            >The Platform</a
                        >
                        <br /><br />
                        <a
                            class="btn btn-primary btn-lg btn-start"
                            routerLinkActive="list-item-active"
                            target="_blank"
                            routerLink="/sim-default"
                            >The Simulator Engine</a
                        >
                    </div>
                </div>
                <div class="column col-4 hide-sm">
                <img class="slogan-img" src="assets/landing/images/yeo-feature-1.svg" alt="" />
            </div>
            </div>
        </div>
    </div>
    <div class="yeo-client">
        <div class="container yeo-client-list">
            <div class="columns">
                <div class="column centered col-12">
                    <h3 class="feature-title">Supported Cryptocurrencies</h3>
                    <div class="client-logo">
                        <a>
                            <img matTooltip="Bitcoin" src="assets/landing/images/btc-logo.png" height="60px" alt="" />
                        </a>
                        <a>
                            <img matTooltip="Aave" src="assets/landing/images/aave-logo.png" height="60px" alt="" />
                        </a>
                        <a>
                            <img matTooltip="Compound" src="assets/landing/images/comp-logo.png" height="60px" alt="" />
                        </a>
                        <a>
                            <img matTooltip="Huobi Token" src="assets/landing/images/ht-logo.png" height="60px" alt="" />
                        </a>
                        <a>
                            <img matTooltip="Neo" src="assets/landing/images/neo-logo.png" height="60px" alt="" />
                        </a>
                        <a>
                            <img matTooltip="Nem" src="assets/landing/images/xem-logo.png" height="60px" alt="" />
                        </a>
                        <a>
                            <img matTooltip="Binance Coin" src="assets/landing/images/bnb-logo.png" height="60px" alt="" />
                        </a>
                        <a>
                            <img matTooltip="Dash" src="assets/landing/images/dash-logo.png" height="60px" alt="" />
                        </a>
                        <a>
                            <img matTooltip="Ethereum" src="assets/landing/images/eth-logo.png" height="60px" alt="" />
                        </a>
                        <a>
                            <img matTooltip="Stellar" src="assets/landing/images/xlm-logo.png" height="60px" alt="" />
                        </a>
                        <a>
                            <img matTooltip="Ripple" src="assets/landing/images/xrp-logo.png" height="60px" alt="" />
                        </a>
                    </div>
                    <h3 class="feature-title" style="font-size: small;">And more...</h3>
                </div>

            </div>
        </div>
    </div>
    <div class="yeo-do" id="we-do">
        <div class="container yeo-body">
            <div class="columns">
                <div class="column centered col-12">
                    <h2 class="feature-title">What We Do</h2>
                </div>
                <div class="column centered col-3">
                    <div class="yeo-do-content">
                        <img src="assets/landing/images/what-we-do-1.svg" alt="" />
                        <h3>Technical Analysis</h3>
                        <p>
                            ExilioLabs offers our users the ability to perform technical analysis on live trading data for a multitude of
                            cryptocurrencies.
                        </p>
                    </div>
                </div>
                <div class="column centered col-3">
                    <div class="yeo-do-content">
                        <img src="assets/landing/images/what-we-do-2.svg" alt="" />
                        <h3>Indicator Signals</h3>
                        <p>
                            Our users can test the historical accuracy of multiple indicators for specified margins, 
                            allowing for short term traders to manage their risk more effectively
                        </p>
                    </div>
                </div>
                <div class="column centered col-3">
                    <div class="yeo-do-content">
                        <img src="assets/landing/images/what-we-do-3.svg" alt="" />
                        <h3>Premium Book</h3>
                        <p>
                            ’ExilioLabs provides educational resources to upskill our users in the world of trading.
                            Our books are available in paperback and e-book format allowing readers to access the information in the way that suits them best.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="yeo-open-source" id="we-work">
        <div class="container yeo-body">
            <div class="columns">
                <div class="column centered col-12">
                    <h2 class="feature-title">The Analytics Platform</h2>
                    <p>
                        Our Analytics Platform leverages the advantages of automated technical analysis.
                        Users can select technical indicators to run historical data checks to better improve their knowledge.
                        Check me out!
                    </p>
                </div>
                <div class="column col-10 centered col-sm-12">
                    <a routerLinkActive="list-item-active" target="_blank" routerLink="/default">
                        <img class="platform-img" src="assets/landing/images/ExilioPlatforms.png" alt="" />
                    </a>
                    <br />
                    <br />
                </div>
            </div>
        </div>
    </div>
    <div class="yeo-open-source">
        <div class="container yeo-body">
            <div class="columns">
                <div class="column centered col-12">
                    <h2 class="feature-title">The Simulator Engine</h2>
                    <p>
                        Our simulator platform freely allows users to test their trading capabilities and knowledge.
                        The simulator platform offers hints and insights into trading patterns allowing the user to adapt
                        and learn about the ever-changing market conditions.
                        Check me out! 
                    </p>
                </div>
                <div class="column col-10 centered col-sm-12">
                    <a routerLinkActive="list-item-active" target="_blank" routerLink="/sim-default">
                        <img class="platform-img" src="assets/landing/images/SimulatorPlatform.png" alt="" />
                    </a>
                    <br />
                    <br />
                </div>
            </div>
        </div>
    </div>
    <div class="yeo-work" id="price">
        <div class="container yeo-body">
            <div class="columns">
                <div class="column col-12 col-sm-12">
                    <h2 class="feature-title">How We Work</h2>
                </div>
                <div class="column col-10 col-sm-12 centered">
                    <h2 class="yeo-work-feature">
                        We've built an <span class="yeo-work-feature-bold">Analysis Engine</span> that will do the heavy
                        lifting for you.
                    </h2>
                </div>
            </div>
        </div>
    </div>
    <div>
        <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
        <stripe-pricing-table pricing-table-id="prctbl_1NW3AnHwGvYDPSamqkrA31ky"
        publishable-key="pk_live_51IRLvIHwGvYDPSamRE3oKM3xMyObgXpeGw0mLS9tiP8b7UyzMeYSE6v8ykXsffh0Zg4nWqAuMugJ1RUKUyAzn28s00WXkR87uQ">
        </stripe-pricing-table>
    </div>
    <!-- <div class="yeo-team" id="team">
        <div class="container yeo-body column col-8">
            <div class="columns">
                <div class="column centered col-12">
                    <h2 class="feature-title">Meet Our Team</h2>
                </div>
                <div class="column centered col-2 flip-card">
                    <div class="flip-card-inner">
                        <div class="flip-card-front">
                            <img class="img2" src="assets/landing/images/emmet.png" alt="Avatar">
                        </div>
                        <div class="flip-card-back">
                            <a href="https://www.linkedin.com/in/emmet-cullen-0150a811a/" target="_blank">
                                <img src="assets/landing/images/EmmetQR.png" class="img3">
                            </a>

                        </div>
                    </div>
                    <br>
                    <a><span class="name">Emmet Cullen</span></a>
                    <span class="title">Software Engineer</span>
                </div>
                <div class="column centered col-2 flip-card">
                    <div class="flip-card-inner">
                        <div class="flip-card-front">
                            <img class="img2" src="assets/landing/images/Matthew.jpeg" alt="Avatar">
                        </div>
                        <div class="flip-card-back">
                            <a href="https://www.linkedin.com/in/matthew-cullen-86b211167/" target="_blank">
                                <img src="assets/landing/images/MatthewQR.png" class="img3">
                            </a>
                        </div>
                    </div>
                    <br>
                    <a><span class="name">Matthew Cullen</span></a>
                    <span class="title">Software Engineer</span>
                </div>
                <div class="column centered col-2 flip-card">
                    <div class="flip-card-inner">
                        <div class="flip-card-front">
                            <img class="img2" src="assets/landing/images/nate.png" alt="Avatar">
                        </div>
                        <div class="flip-card-back">
                            <a href="https://www.linkedin.com/in/nathan-mindomba-b81025150/" target="_blank">
                                <img src="assets/landing/images/NathanQR.png" class="img3">
                            </a>
                        </div>
                    </div>
                    <br>
                    <a><span class="name">Nathan Mindomba</span></a>
                    <span class="title">Software Engineer</span>
                </div>
            </div>
        </div>
    </div> -->
    <br><br><br><br>
    <div class="yeo-open-source" id="contact">
        <div class="container yeo-body">
            <div class="columns">
                <div class="column col-12">
                    <h2 class="feature-title">Our Inbox is Always Open!</h2>
                </div>
                <div class="column col-10 centered col-sm-12">
                    <img class="open-source-img" src="assets/landing/images/open-source.svg" alt="" />
                    <h2 class="slogan-bold"></h2>
                    <a routerLink="default/contact" target="_blank" class="btn btn-lg btn-open-source">Contact Us!</a>
                </div>
            </div>
        </div>
    </div>
    <div class="yeo-footer">
        <div class="container">
            <div class="columns">
                <div style="margin-left: 18%" class="column col-2 col-sm-6">
                    <div class="yeo-footer-content">
                        <h4>Product</h4>
                        <ul class="nav">
                            <li class="nav-item">
                                <a routerLink="default/pricing">Pricing</a>
                            </li>
                            <li class="nav-item">
                                <a  href="https://www.exiliolabs.com/#/default/ebook" target="_blank">Educational Book</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="default/">Exilio Export</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="default/cancel-subscription">Cancel Subscription</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="column col-2 col-sm-6">
                    <div class="yeo-footer-content">
                        <h4>Company</h4>
                        <ul class="nav">
                            <li class="nav-item">
                                <a routerLink="default/about">About Exilio</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="default/tos">Terms of Service</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="default/privacy">Privacy Policy</a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="default/contact">Contact Us</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="column col-2 col-sm-6">
                    <div class="yeo-footer-content">
                        <h4>Socials</h4>
                        <ul class="nav">
                            <li class="nav-item">
                                <a href="https://www.instagram.com/exiliolabs/" target="_blank">Instagram</a>
                            </li>
                            <li class="nav-item">
                                <a href="https://www.facebook.com/ExilioLabs/" target="_blank">Facebook</a>
                            </li>
                            <li class="nav-item">
                                <a href="https://twitter.com/ExilioLabs" target="_blank">Twitter</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="column col-2 col-sm-6">
                    <div class="yeo-footer-content">
                        <h4>© ExilioLabs 2023</h4>
                        <a class="navbar-brand logo">
                            <img class="logo-img" src="assets/landing/images/icon.png" alt="" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>

