<div style="margin-left: 10%; margin-right: 10%">
    <h1 style="text-align: center;">Simulator Leaderboard</h1>
    <mat-card> 
        <div class="mat-elevation-z1">
            <table style="width:100%;" mat-table [dataSource]="dataSource">
                <ng-container matColumnDef="position">
                    <th mat-header-cell *matHeaderCellDef>No.</th>
                    <td mat-cell *matCellDef="let usernames; let i=index">{{ i + 1 + (paginator.pageIndex * paginator.pageSize) }}</td>
                </ng-container>
    
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>Name</th>
                    <td  mat-cell *matCellDef="let usernames" > {{usernames}}</td>
                </ng-container>
    
                <ng-container matColumnDef="score">
                    <th mat-header-cell *matHeaderCellDef>Score</th>
                    <td mat-cell *matCellDef="let scores; let i=index" >{{ score[i] }}</td>
                </ng-container>

                <ng-container matColumnDef="accuracy">
                    <th mat-header-cell *matHeaderCellDef>Accuracy</th>
                    <td mat-cell *matCellDef="let accuracies; let i=index" >{{ accuracy[i] }}</td>
                </ng-container>
    
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
    
            <mat-paginator #paginator [pageSize]="25"></mat-paginator>
        </div>
    </mat-card>
</div>