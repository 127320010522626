import { Component, Inject, OnInit } from '@angular/core';



export interface UserDats {
    user: string;
    email: string;
}

@Component({
    selector: 'app-default',
    templateUrl: './default.component.html',
    styleUrls: ['./default.component.scss'],
})
export class SimDefaultComponent implements OnInit {

    sideBarOpen = true;

    constructor() {

    }

    // tslint:disable-next-line: typedef
    ngOnInit() {
    }

    // tslint:disable-next-line: typedef
    sideBarToggler() {
        this.sideBarOpen = !this.sideBarOpen;
    }
}

