<mat-nav-list>
    <a mat-list-item routerLinkActive="list-item-active" routerLink="/">
        <mat-icon>stacked_line_chart</mat-icon>
        Simulation Dashboard
    </a>

    <a mat-list-item routerLinkActive="list-item-active" routerLink="/leaderboard">
        <mat-icon>leaderboard</mat-icon>
        Scoreboard
    </a>

    <mat-divider></mat-divider>

    <h2 matSubheader>Other</h2>

    <a mat-list-item routerLinkActive="list-item-active" routerLink="/about">
        <mat-icon>import_contacts</mat-icon>
        About Us
    </a>

    <a mat-list-item routerLinkActive="list-item-active" routerLink="/contact">
        <mat-icon>contact_phone</mat-icon>
        Contact
    </a>

    <a mat-list-item href="https://phoenix-trading.herokuapp.com" target="_blank">
        <mat-icon>show_chart</mat-icon>
        Phoenix Trading
    </a>
</mat-nav-list>
