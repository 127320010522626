<div mat-dialog-content>
    <div>
        <!-- <img src="assets/imgs/icon.png" style="padding-left: 100px; max-height: 100px" /> -->
        <div style="text-align: center; display: block">
            <img src="assets/imgs/icon.png" style="max-height: 100px" />
            <br />
            <img src="assets/imgs/exilio_white.png" style="max-height: 60px" />
        </div>
    </div>
    <br />
    <mat-divider></mat-divider>
    <br />
    <div>
        <p style="text-align: center; font-size: 20px; margin-left: 50px; margin-right: 50px">
            You're all set to start your <b>Trading Analysis</b>, and start earning rewards on your investments!
        </p>
        <br />
        <div style="text-align: center; display: block;">
            <!-- <button style="margin-right: 20px; padding: 10px 10px 10px 10px;  font-size: 15px;" mat-raised-button color="primary">Take Tutorial</button> -->
            <button  style="margin-left: 20px; padding: 10px 10px 10px 10px;  font-size: 15px;" mat-raised-button color="accent" tabindex="-1" (click)="close()">Get To Work!</button>
        </div>
    </div>
</div>
<div mat-dialog-actions></div>
