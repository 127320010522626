<style>
h1 {text-align: center;}
p {text-align: center; font-size: 20px}
div {text-align: center; width:100%; margin:0 auto;}

</style>

<div class="header">
    <h1 style="font-size: 50px font-weight: bold;">Privacy Policy</h1>
</div>

<div>
    <p>
       <b>Privacy Policy;</b> This Privacy Policy governs the manner in which ExilioLabs collects, uses,
       maintains and discloses information collected from users of our platforms. This Privacy Policy applies
       to ExilioLabs and all services offered by ExilioLabs. Personal identification information; we may collect
       personal identification information from Users in a variety of ways, including, but not limited to, when
       Users sign up to an ExilioLabs platform or a service provided by ExilioLabs, subscribe to a newsletter,
       and in connection with other activities, services, features, or resources we make available to users.
       We will collect personal identification information from Users only if they voluntarily submit such
       information to us. Users can refuse to supply personal identification information but doing so may prevent
       them from utilising certain platform related functionalities.
       <br> <br> <b>How we use collected information;</b>  ExilioLabs may collect and use Users’ personal identification
       information for the following purposes: To improve customer service; Information you provide helps us respond
       to your customer service requests and support needs more efficiently. To personalize user experience; We may
       use information in the aggregate to understand how our Users as a group use the services and resources provided
       by ExilioLabs. To send periodic emails: We may use Users email addresses to send Users information and updates
       pertaining to their subscriptions or other services provided by ExilioLabs. Users email addresses may also be
       used to respond to Users inquiries, questions, or other requests. Sharing your personal information, We may be
       required to share personal information for compliance/legal reasons at times
       <br> <br> <b>Third party websites</b>: Users may find advertising or other content in ExilioLabs that link to the
        websites and services of our partners, suppliers, advertisers, sponsors, licensors and other third parties. We
        do not control the content or links that appear on these websites and are not responsible for the practices
        employed by websites linked to or from ExilioLabs. In addition, these websites or services, including their
        content and links, may be constantly changing. These websites and services may have their own privacy policies
        and customer service policies. Browsing and interaction on any other website, including websites which have a
        link to ours, is subject to that website's own terms and policies. 
       <br> <br> <b>Changes to this Privacy Policy:</b> Changes to this Privacy Policy: Exilio has the discretion to
       update this Privacy Policy at any time. We encourage Users to frequently check this page for any changes. You
       acknowledge and agree that it is your responsibility to review this Privacy Policy periodically and become aware
       of modifications. Your acceptance of these terms By signing up to ExilioLabs signifies your acceptance of this
       Privacy Policy. If you do not agree to this Privacy Policy, please do not create an account or provide us with
       any user details for any ExilioLabs platform’s or service’s. Your continued engagement in Exilio following the
       posting of changes to this Privacy Policy will be deemed your acceptance of those changes.
    </p>
</div>
