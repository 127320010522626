import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { DashboardService } from '../../core/services/dashboard.service';
import { ResourceService } from '../../core/services/resource.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { toTypeScript } from '@angular/compiler';
import { RouterModule } from '@angular/router';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;
        return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
}

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  emailFormControl = new FormControl('', [Validators.required, Validators.email]);

  matcher = new MyErrorStateMatcher();
  public loginView: number;
  public email: string;
  public emailError: string;
  public messageError: string;
  

  constructor(private dashboardService: DashboardService, private _resourceService: ResourceService, private spinner: NgxSpinnerService) {}
    
  messageSent: boolean = false;

    ngOnInit(): void {

    }

    testFunction(): void {

        this.emailError = null
        this.messageError =null
        var email = (<HTMLInputElement>document.getElementById('email')).value;
        var message = (<HTMLInputElement>document.getElementById('message')).value;
        if (email !== null && email !== "" && message !== null && message !==""){
           this.messageSent = true;
        } else if (email == null || email == "") {
            this.loginView = 1;
            this.emailError = "Please enter an email"
        } else if (message == null || message == ""){
            this.messageError = "Please enter a message"
        }
        
    }


}
