<div mat-dialog-content> 
    <button class="close" mat-button (click)="close()">X</button>
    <h1 style="text-align: center;" >Welcome to ExilioLabs! </h1>
    <br>
    <h3 style="text-align: center; font-size:18px;">A place where your trading expertise can be put to the test!</h3>
    <br>
    <h3 style="text-align: center;" >Guide</h3>
    <ol>
    <li> Our main simulator can be located by navigating through > Trading > Simulator.
    </li>
    <br>
    <li>
        Taking part in our trading game is simple. The user will be presented with a randomly generated dataset,
        with the aim of predicting whether the stock price will rise or fall by buying or selling. 
    </li>
    <br>
    <!-- img1 -->
    <div >
        <img src="./../../../../assets/imgs/help1.png" style="max-width: 500px" />
    </div>
    <br>
    <li>
        Every correct position will earn the user 1 point. Every incorrect prediction will result in the user losing 1 point.
        After each prediction the data set will change, the undetermined button allows the user to skip to the next dataset without loosing points. 
    </li>
    <br>
    <li>
        Your current score accuracy will be displayed on the right-hand side of the dashboard.
        (This is calculated on the number of correct predictions since the start of your session.) 
    </li>
    <br>
    <!-- img2 -->
    <div>
        <img src="./../../../../assets/imgs/help2.png" style="max-width: 500px" />
    </div>
    <br>
    <li> A leader board will display your position among other traders that use our platform. (Note: An account must be made to display your score on our leader board.) Please select ‘Save session’ to andif you are in the top 25 your name will appear on our leader board. </li>
    <br>
    <!-- img3 -->
    <div>
        <img src="./../../../../assets/imgs/help3.png" style="max-width: 500px" />
    </div>
    <br>
    <li>
        If you do not have an account with us. Simply navigate through > Account > Register. Goodluck & Happy trading 😊!!
    </li>
    </ol>
    <br>
    <!-- <mat-divider></mat-divider> -->
    <br>
</div>

<div mat-dialog-actions style="margin-left: 200px;">
    <button  mat-raised-button color="green" tabindex="-1" (click)="close()" style="background: linear-gradient(0.25turn,#d768e0,#1891d8)">Best of luck!😎</button>
</div>
