<div>
    <div >
        <button class="close" mat-button (click)="onMaybeLater()">X</button>
        <div style="text-align: center">
            <img src="./../../../assets/imgs/icon.png" style="max-height: 90px" />
        </div>
        <h2 style="text-align: center">Exilio Trading</h2>
        <h3 style="text-align: center">Simulator Engine 2.0</h3>
        <br />
        <div *ngIf="loginView == 0">
            <div mat-dialog-content style="text-align: center">
                
                <mat-form-field appearance="outline">
                    <mat-label>Username</mat-label>
                    <input matInput [(ngModel)]="data.name" />
                    <mat-icon matSuffix>accessibility_new</mat-icon>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput [(ngModel)]="data.email" />
                    <mat-icon matSuffix>contact_mail</mat-icon>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Password</mat-label>
                    <input matInput [type]="hide ? 'password' : 'text'" [(ngModel)]="data.password" />
                    <mat-icon matSuffix (click)="hide = !hide">{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
                </mat-form-field>
                <div>
                    <p style="color: red;" *ngIf="this.usernameError != null">*Username Error: {{ usernameError }}</p>
                    <p style="color: red;" *ngIf="this.emailError != null">*Email Error: {{ emailError }}</p>
                    <p style="color: red;" *ngIf="this.passwordError != null">*Password Error: {{ passwordError }}</p>
                    <p style="color: red;" *ngIf="this.registerError != null">*Register Error: {{ registerError }}</p>
                </div>
            </div>
            <div mat-dialog-actions style="margin-left: 28%">
                <button mat-raised-button color="accent" (click)="onMaybeLater()" cdkFocusInitial>Maybe Later</button>
                <button mat-raised-button color="primary" (click)="onRegister(data.name, data.email, data.password)">
                    Register
                </button>
                <br>
                <button mat-button color="accent" (click)="loginView = 1">Already have an Account?</button>
            </div>
        </div>

        <div *ngIf="loginView == 1">
            <div mat-dialog-content style="text-align: center">
                <mat-form-field appearance="outline">
                    <mat-label>Username</mat-label>
                    <input matInput [(ngModel)]="data.name" />
                    <mat-icon matSuffix>alternate_email</mat-icon>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Password</mat-label>
                    <input matInput [type]="hide ? 'password' : 'text'" [(ngModel)]="data.password" />
                    <mat-icon matSuffix (click)="hide = !hide">{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
                </mat-form-field>
            </div>
            <div style="text-align: center">
                <p style="color: red;" *ngIf="this.loginError != null">*Login Error: {{ loginError }}</p>
            </div>
            <div mat-dialog-actions style="margin-left: 28%">
                <button mat-raised-button color="accent" (click)="loginView = 0">Register</button>
                <button mat-raised-button color="primary" (click)="onLogin(data.name, data.password)" cdkFocusInitial>
                    Login
                </button>
            </div>
        </div>

        <div *ngIf="loginView == 2">
            <mat-divider></mat-divider>
            <div mat-dialog-content style="text-align: center">
                <br />
                <h2>Welcome back {{ data.name }} 😁</h2>
            </div>
            <div mat-dialog-actions style="margin-left: 140px">
                <button mat-raised-button color="primary" (click)="onCancel1()">Continue to Simulator</button>
            </div>
        </div>

        <div *ngIf="loginView == 3">
            <mat-divider></mat-divider>
            <div mat-dialog-content style="text-align: center">
                <br />
                <h2>Thank you {{data.name}} for signing up to Exilio 😁.</h2>
            </div>
            <div mat-dialog-actions style="margin-left: 140px">
                <button mat-raised-button color="primary" (click)="onCancel1()">Continue to Simulator</button>
            </div>
        </div>
        <div *ngIf="loginView == 4">
            <div mat-dialog-content style="text-align: center">
                <mat-form-field appearance="outline">
                    <mat-label>Confirmation Code</mat-label>
                    <input matInput [(ngModel)]="data.confirmationCode" />
                    <mat-icon matSuffix>accessibility_new</mat-icon>
                </mat-form-field>
            </div>
            <p style="color: greenyellow; text-align: center" *ngIf="this.confirmCodeError == null" >Please input the confirmation code we've emailed to you or click "Resend Code" to get a new code sent.</p>
            <p style="color: red; text-align: center" *ngIf="this.confirmCodeError != null" >*Confirmation Code Error: {{ confirmCodeError }}</p>
            <div mat-dialog-actions style="margin-left: 130px">
                <button mat-raised-button color="accent" (click)="onResendCode()" cdkFocusInitial>Resend Code</button>
                <button mat-raised-button color="primary" (click)="onConfirmationCode(data.confirmationCode)">
                    Continue
                </button>
                <br>
                <button mat-button color="accent" (click)="loginView = 1">Already have an Account?</button>
            </div>
        </div>
    </div>
</div>
