import { Component, OnInit, ViewChild, Inject, ViewEncapsulation} from '@angular/core';
import { DashboardServices } from '../../../core/services/sim-dashboard.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ScoreboardService } from '../../../core/services/scoreboard-api.service';
import * as Highcharts from 'highcharts/highstock';
import HC_exporting from 'highcharts/modules/exporting';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, } from '@angular/material/snack-bar';
import {CognitoUserPool, CognitoUserAttribute, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import * as AWS from 'aws-sdk';
import { resourceLimits } from 'worker_threads';
import { error } from 'console';
import { DashboardService } from 'src/app/core/services/dashboard.service';
import { Observable } from 'rxjs';

export interface ScoreboardModel {
    id: number;
    user: string;
    score: string;
    accuracy: string;
}

@Component({
    selector: 'app-sim-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class SimDashboardComponent implements OnInit {
    name: string;
    public binance: any[];
    public ohlcChart: any[];
    public emailSent: any;
    public volume: any[];
    public OBV: any[];
    public Stoch: any [];
    public ULCER: any[];
    public ROC: any[];
    public ADX: any[];
    public RSI: any[];
    public MACD: any[];
    public Comment: any[];
    public SMA: any[];
    public EMA: any[];
    public WMA: any[];
    public scores: ScoreboardModel[];
    public currentScore: number;
    public dataSource: any;
    public username: string;
    public ticker: string;
    public prediction: string;
    public answer: number;
    public correct: number;
    public incorrect: number;
    public accuracy: number;
    public leaderboard: number;
    public loggedIn: boolean;
    public ordinal: string;
    plotOptions: {};
    plotOptions2: {};
    pieOptions: {};
    colors: any[];
    public chartTheme: string;
    public chartText: string;
    public submitting: boolean;
    public previousComment: any[];
    public previousPrediction : string;
    public leaderboardPos: any;
    public mobile: boolean;
    public cutoff: number;

    score: number;
    accuracy2: number;
    Highcharts = Highcharts;

    displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';

    constructor(
        private dashboardServices: DashboardServices,
        private scoreboardService: ScoreboardService,
        private _snackBar: MatSnackBar,
        private dashboardService: DashboardService,
    ) {
        this.scores = [];
        this.ohlcChart = [];
        this.leaderboard = 0;
        this.binance = [];
        this.answer = 0;
        this.ordinal = '';
        this.currentScore = +localStorage.getItem('score') || 0;
        this.accuracy = +localStorage.getItem('accuracy') || 0;
        this.correct = +localStorage.getItem('correct') || 0;
        this.incorrect = ((100/this.accuracy)*this.correct)-this.correct || 0;
    }

    ngOnInit() {
        if (window.screen.width < 500) {
            // 768px portrait
            this.mobile = true;
            this.cutoff = 35;}
            else {this.cutoff = 0}
        this.retrieveOHLCData();
        this.retrieveScoreboard();
        if (localStorage.getItem('access_token') !== null) {
            this.loggedIn = true;
        } else {
            this.loggedIn = false;
        }
        //Setting Chart theme based on toggle settings
        if (localStorage.getItem('prefers-color') === 'light') {
            this.chartTheme = '#FFFFFF';//'#ffffff';
            this.chartText = '#000000';
            this.retrieveOHLCData();
        } else {
            this.chartTheme = '#0E0D14';//'#1D1D2F';
            this.chartText = '#ffffff';
            this.retrieveOHLCData();
        }
        this.setPieAccuracy();
        this.getLoaderboardPosition();
        HC_exporting(Highcharts);
        this.loadLeaderboard();
        this.openSnackBar('Exilio Simulator Uses Local Storage for Essential Functions Required To Run The Simulator');
    }


    retrieveScoreboard(): void {
        this.scoreboardService.getAll().subscribe(
            (data) => {
                this.scores = data;
                this.dataSource = new MatTableDataSource<ScoreboardModel>(this.scores);
                this.dataSource.paginator = this.paginator;
            },
            (error) => {
                console.log(error);
            }
        );
    }

    retrieveOHLCData(): void {
        this.dashboardServices.getOhlc().subscribe(
            (data) => {
                this.ohlcChart = Object.values(data.Data)
                this.ohlcChart = this.ohlcChart.splice(0, this.ohlcChart.length - this.cutoff)
                this.volume = Object.values(data.Volume);
                this.volume = this.volume.splice(0, this.volume.length - this.cutoff)
                this.OBV = data.Obv;
                this.ULCER = data.Ulcer;
                this.Stoch = data.Stoch;
                this.ROC = data.Roc;
                this.ADX = data.Adx;
                this.RSI = data.RSI;
                this.MACD = Object.values(data.MACD);
                this.MACD = this.MACD.splice(0,this.MACD.length - this.cutoff)
                this.SMA = Object.values(data.SMA);
                this.SMA = this.SMA.splice(0,this.SMA.length - this.cutoff)
                this.EMA = Object.values(data.EMA);
                this.EMA = this.EMA.splice(0,this.EMA.length - this.cutoff)
                this.WMA = Object.values(data.WMA);
                this.WMA = this.WMA.splice(0,this.WMA.length - this.cutoff)
                this.Comment = data.Comment;
                this.ticker = data.Ticker;
                this.prediction = data.Prediction;
                this.setChart();
                this.submitting = false;
                console.log(data);
            },
            (error) => {
                console.log(error);
            }
        );

    }


    getLoaderboardPosition(): void {
        this.scoreboardService.getAll().subscribe(
            (data) => {
                this.leaderboard = data.findIndex((x) => x.user === localStorage.getItem('username')) + 1;
                this.ordinal = this.getOrdinal(this.leaderboard);
            },
            (error) => {
                console.log(error);
            }
        );
    }

    getOrdinal(position: number): string {
        if (position > 3 && position < 21) {
            return 'th';
        }
        switch (position % 10) {
            case 1:
                return 'st';
            case 2:
                return 'nd';
            case 3:
                return 'rd';
            default:
                return 'th';
        }
    }

    async submitAction(action: string): Promise<void> {
        let result = '';
        this.submitting = true;

        if (action !== 'undetermined') {
            if (action === this.prediction) {
                this.answer = 1;
                this.correct++;
                this.currentScore++;
                result = 'Correct';
            } else {
                this.answer = 2;
                this.incorrect++;
                this.currentScore--;
                result = 'Incorrect';
            }
            this.accuracy = (this.correct / (this.incorrect + this.correct)) * 100;
            localStorage.setItem('score', this.currentScore.toString());
            localStorage.setItem('correct', this.correct.toString());
            localStorage.setItem('accuracy', this.accuracy.toString());
            this.openSnackBar(result);
        }
        this.setPieAccuracy();
        const delay = (ms) => new Promise((res) => setTimeout(res, ms));
        await delay(1000);
        this.previousComment = this.Comment
        this.previousPrediction = this.prediction
        this.retrieveOHLCData();
        // this.scoreboardService.saveScores


    }

    usernameObservable() {
        const test = localStorage.getItem('username')
        this.dashboardService.getUserLeaderboardPosition().subscribe()
    }


    loadLeaderboard(){
        const username = localStorage.getItem('username');
        const loadToken = localStorage.getItem('loadToken')

        if (username !== 'null') {
            this.dashboardService.getLeaderboard().subscribe(
            (data) => {
            this.leaderboardPos = data.usernameArray.indexOf(localStorage.getItem('username')) + 1
            if (data.scoresArray[this.leaderboardPos - 1] == null) {
                this.currentScore = 0
            } else {
                this.currentScore = data.scoresArray[this.leaderboardPos - 1]
            }

            if (data.accuracyArray[this.leaderboardPos -1 ] == null) {
                this.accuracy = 0
            } else {
               this.accuracy = data.accuracyArray[this.leaderboardPos -1 ] 
            }

            this.ordinal = this.getOrdinal(this.leaderboardPos)
            
            this.setPieAccuracy();
            return this.currentScore, this.accuracy
        }) 
        }
    }

    openSnackBar(result: string) {
        if (result === 'Session Saved') {
            this._snackBar.open(result, 'Check out leaderboard!', {
                duration: 2000,
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
                panelClass: ['green-snackbar'],
            });
        } else if (result === 'Please Login to Save Session') {
            this._snackBar.open(result, '', {
                duration: 5000,
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
                panelClass: ['red-snackbar'],
            });
        } else if (result === 'Correct') {
            this._snackBar.open(result, 'Good Work!', {
                duration: 1000,
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
                panelClass: ['green-snackbar'],
            });
        } else if (result === 'Exilio Simulator Uses Local Storage for Essential Functions Required To Run The Simulator') {
            this._snackBar.open(result, '', {
                duration: 5000,
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
                panelClass: ['green-snackbar'],
            });
        } else {
            this._snackBar.open(result, 'Try Again!', {
                duration: 1000,
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
                panelClass: ['red-snackbar'],
            });
        }
    }

    async onSave(): Promise<void> {

        const username = localStorage.getItem('username');
        console.log(localStorage.getItem('username'))
        console.log(typeof(localStorage.getItem('username')))
        console.log(this.currentScore)
        this.accuracy = Math.round(this.accuracy)


        if (username !== 'null') {
            this.dashboardService.saveSimulatorSession(username, this.currentScore, this.accuracy ).subscribe()             
        
        } else  {
            this.openSnackBar('Please Login to Save Session');
            this.leaderboardPos = 0
            this.ordinal = ""
        }

        const delay = ms => new Promise(res => setTimeout(res, ms));
        await delay(500);   

                
        if (username !== 'null') {
            this.dashboardService.getLeaderboard().subscribe(
            (data) => {
            this.leaderboardPos = data.usernameArray.indexOf(localStorage.getItem('username')) + 1
            this.currentScore = data.scoresArray[this.leaderboardPos - 1]
            this.accuracy = data.accuracyArray[this.leaderboardPos -1 ]
            console.log(this.leaderboardPos)
            this.ordinal = this.getOrdinal(this.leaderboardPos)
            this.setPieAccuracy();
        }) 
        }
        
        

        
    }

    

    saveSession(exists: boolean, score: any, existingScoreId: number): void {
        if (!exists) {
            this.scoreboardService.create(score).subscribe(
                (data) => {
                    this.openSnackBar('Session Saved');
                    this.getLoaderboardPosition();
                },
                (error) => {
                    console.log(error);
                }
            );
        } else {
            this.scoreboardService.update(existingScoreId, score).subscribe(
                (data) => {
                    this.openSnackBar('Session Saved');
                    this.getLoaderboardPosition();
                },
                (error) => {
                    console.log(error);
                }
            );
        }
    }

    setChart(): void {
        this.Highcharts.theme = {
            colors: [
                '#2b908f',
                '#90ee7e',
                '#f45b5b',
                '#7798BF',
                '#aaeeee',
                '#ff0066',
                '#eeaaee',
                '#55BF3B',
                '#DF5353',
                '#7798BF',
                '#aaeeee',
                '#1d1e2e',
            ],
            chart: {
                backgroundColor: this.chartTheme,
                style: {
                    fontFamily: "'Unica One', sans-serif",
                },
                plotBorderColor: '#606063',
            },
            title: {
                style: {
                    color: this.chartText,
                    textTransform: 'uppercase',
                    fontSize: '20px',
                },
            },
            subtitle: {
                style: {
                    color: this.chartText,
                    textTransform: 'uppercase',
                },
            },
            xAxis: {
                gridLineColor: '#707073',
                labels: {
                    style: {
                        color: this.chartText,
                    },
                },
                lineColor: '#707073',
                minorGridLineColor: '#505053',
                tickColor: '#707073',
                title: {
                    style: {
                        color: '#A0A0A3',
                    },
                },
            },
            yAxis: {
                gridLineColor: '#707073',
                labels: {
                    style: {
                        color: this.chartText,
                    },
                },
                lineColor: '#707073',
                minorGridLineColor: '#505053',
                tickColor: '#707073',
                tickWidth: 1,
                title: {
                    style: {
                        color: this.chartText,
                    },
                },
            },
            tooltip: {
                backgroundColor: '#B8CCC8',
                style: {
                    color: '#000000',
                },
            },
            plotOptions: {
                series: {
                    dataLabels: {
                        color: '#F0F0F3',
                        style: {
                            fontSize: '13px',
                        },
                    },
                    marker: {
                        lineColor: '#333',
                    },
                },
                boxplot: {
                    fillColor: '#505053',
                },
                candlestick: {
                    lineColor: 'white',
                },
                errorbar: {
                    color: 'white',
                },
            },
            legend: {
                backgroundColor: this.chartTheme,
                itemStyle: {
                    color: this.chartText,
                },
                itemHoverStyle: {
                    color: '#FFF',
                },
                itemHiddenStyle: {
                    color: '#606063',
                },
                title: {
                    style: {
                        color: '#C0C0C0',
                    },
                },
            },
            credits: {
                style: {
                    color: '#666',
                },
            },
            drilldown: {
                activeAxisLabelStyle: {
                    color: '#F0F0F3',
                },
                activeDataLabelStyle: {
                    color: '#F0F0F3',
                },
            },
            navigation: {
                buttonOptions: {
                    symbolStroke: '#DDDDDD',
                    theme: {
                        fill: '#505053',
                    },
                },
            },
            // scroll charts
            rangeSelector: {
                buttonTheme: {
                    fill: '#505053',
                    stroke: '#000000',
                    style: {
                        color: '#CCC',
                    },
                    states: {
                        hover: {
                            fill: '#707073',
                            stroke: '#000000',
                            style: {
                                color: 'white',
                            },
                        },
                        select: {
                            fill: '#000003',
                            stroke: '#000000',
                            style: {
                                color: 'white',
                            },
                        },
                    },
                },
                inputBoxBorderColor: '#505053',
                inputStyle: {
                    backgroundColor: '#333',
                    color: 'silver',
                },
                labelStyle: {
                    color: 'silver',
                },
            },
            navigator: {
                handles: {
                    backgroundColor: '#666',
                    borderColor: '#AAA',
                },
                outlineColor: '#CCC',
                maskFill: 'rgba(255,255,255,0.1)',
                series: {
                    color: '#7798BF',
                    lineColor: '#A6C7ED',
                },
                xAxis: {
                    gridLineColor: '#505053',
                },
            },
            scrollbar: {
                barBackgroundColor: '#808083',
                barBorderColor: '#808083',
                buttonArrowColor: '#CCC',
                buttonBackgroundColor: '#606063',
                buttonBorderColor: '#606063',
                rifleColor: '#FFF',
                trackBackgroundColor: '#404043',
                trackBorderColor: '#404043',
            },
        };
        // Apply the theme
        Highcharts.setOptions(this.Highcharts.theme);

        this.plotOptions = {
            rangeSelector: {
                selected: 1,
            },
            title: {
                text: this.ticker + ' OHLC Data',
            },
            chart: {
                height: 700,
            },
            xAxis: {
                type: 'datetime',
            },
            yAxis: [
                {
                    labels: {
                        align: 'right',
                        x: -3,
                    },
                    title: {
                        text: 'OHLC',
                    },
                    height: '50%',
                    lineWidth: 2,
                    resize: {
                        enabled: true,
                    },
                },
                {
                    labels: {
                        align: 'right',
                        x: -3,
                    },
                    title: {
                        text: 'Volume',
                    },
                    top: '55%',
                    height: '20%',
                    offset: 0,
                    lineWidth: 2,
                },
                {
                    labels: {
                        align: 'right',
                        x: -3,
                    },
                    title: {
                        text: 'MACD',
                    },
                    top: '80%',
                    height: '20%',
                    offset: 0,
                    lineWidth: 2,
                    plotLines: [
                        {
                            color: '#ADD8E6',
                            width: 4,
                            value: 0,
                            dashStyle: 'line',
                            upColor: '#ADD8E6',
                            upLineColor: '#ADD8E6',
                            lineColor: '#ADD8E6',

                        },
                    ],
                },
            ],
            series: [
                {
                    type: 'candlestick',
                    name: 'OHLC',
                    upColor: '#589636',
                    upLineColor: '#589636',
                    lineColor: '#B2022F',
                    color: '#B2022F',
                    data: this.ohlcChart,
                    dataGrouping: {
                        units: [
                            [
                                'week', // unit name
                                [1], // allowed multiples
                            ],
                            ['month', [1, 2, 3, 4, 6]],
                        ],
                    },
                },
                {
                    type: 'column',
                    name: 'Volume',
                    data: this.volume,
                    yAxis: 1,
                    dataGrouping: {
                        units: [
                            [
                                'week', // unit name
                                [1], // allowed multiples
                            ],
                            ['month', [1, 2, 3, 4, 6]],
                        ],
                    },
                },
                {
                    type: 'line',
                    name: 'MACD',
                    upColor: '#589636',
                    upLineColor: '#589636',
                    lineColor: '#B2022F',
                    color: '#B2022F',
                    data: this.MACD,
                    yAxis: 2,
                    dataGrouping: {
                        units: [
                            [
                                'week', // unit name
                                [1], // allowed multiples
                            ],
                            ['month', [1, 2, 3, 4, 6]],
                        ],
                    },
                },
                {
                    name: 'SMA',
                    marker: {
                          enabled: false},
                    lineColor: '#FFA500',
                    color: '#FFA500',
                    data: this.SMA,
                },
                {
                    name: 'EMA',
                    marker: {
                          enabled: false},
                    lineColor: '#ADD8E6',
                    color: '#ADD8E6',
                    data: this.EMA,
                },
                {
                    name: 'WMA',
                    marker: {
                          enabled: false},
                    lineColor: '#ADD8E6',
                    color: '#ADD8E6',
                    data: this.WMA,
                },
            ],
            subtitle: {
                text: 'Simulation',
            },
            tooltip: {
                split: true,
                valueSuffix: ' ',
            },
            credits: {
                enabled: false,
            },
            exporting: {
                enabled: false,
            },
        };
        this.plotOptions2 = {
            rangeSelector: {
                selected: 1,
            },
            title: {
                text:"",
            },
            chart: {
                height: 250 ,
            },
            xAxis: {
                type: 'datetime',
            },
            yAxis: [
                {
                    labels: {
                        align: 'right',
                        x: -3,
                    },
                    title: {
                        text: 'MACD',
                    },
                    height: '60%',
                    lineWidth: 2,
                    resize: {
                        enabled: true,
                    },
                },
            ],
            series: [
                {
                    name: 'MACD',
                    upColor: '#589636',
                    upLineColor: '#589636',
                    lineColor: '#B2022F',
                    color: '#B2022F',
                    data: this.MACD,
                    dataGrouping: {
                        units: [
                            [
                                'week', // unit name
                                [1], // allowed multiples
                            ],
                            ['month', [1, 2, 3, 4, 6]],
                        ],
                    },
                },
            ],
            subtitle: {
                text: 'MACD',
            },
            tooltip: {
                split: true,
                valueSuffix: ' ',
            },
            credits: {
                enabled: false,
            },
            exporting: {
                enabled: false,
            },
        };

        HC_exporting(Highcharts);

        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 300);
    }

    setPieAccuracy(): void {
        this.pieOptions = {
            chart: {
                backgroundColor: null,
                plotBorderWidth: 0,
                plotShadow: false,
            },
            title: {
                text: '',
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
            },
            accessibility: {
                point: {
                    valueSuffix: '%',
                },
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        enabled: true,
                        distance: -50,
                        style: {
                            fontWeight: 'bold',
                            color: 'white',
                        },
                    },
                    colors: this.getAccuracyColours(),
                    startAngle: -90,
                    endAngle: 90,
                    center: ['50%', '75%'],
                    size: '110%',
                },
            },
            series: [
                {
                    type: 'pie',
                    name: 'Accuracy',
                    innerSize: '50%',
                    data: [
                        {
                            name: 'Correct',
                            y: this.accuracy,
                            dataLabels: {
                                enabled: false,
                            },
                        },
                        {
                            name: 'Incorrect',
                            y: 100 - this.accuracy,
                            dataLabels: {
                                enabled: false,
                            },
                        },
                    ],
                },
            ],
            exporting: {
                enabled: false,
            },
            credits: {
                enabled: false,
            },
        };
    }
    getAccuracyColours(): any {
        if (this.currentScore > 0) {
            return ['#589636', '#ffffff'];
        } else if (this.currentScore < 0) {
            return ['#B2022F', '#ffffff'];
        } else {
            return ['#959390', '#ffffff'];
        }
    }
}
