import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import { delay } from 'rxjs/internal/operators';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  //pdfSource =  "../../../assets/ebook/exilio_ebook.pdf";
  constructor(private router: Router, private viewportScroller: ViewportScroller) { }
    isSelected1: boolean = false;
    isSelected2: boolean = false;
    isSelected3: boolean = false;
    isSelected4: boolean = false;


  ngOnInit(): void {
  }



  onClick1() {
    this.isSelected2 = !this.isSelected2;
    this.isSelected3 = !this.isSelected3;
    this.isSelected4 = !this.isSelected4;
    }

  onClick2() {
     this.isSelected2 = !this.isSelected2;
    }

  onClick3() {
     this.isSelected3 = !this.isSelected3;
    }

  onClick4() {
     this.isSelected4 = !this.isSelected4;
    }


  goToId(id: string): void {
  console.log('test');
  }

  myFunction2() {
    var x = document.getElementById("section2");
    if (x.style.display === "none") {
      x.style.display = "block";
      this.viewportScroller.scrollToAnchor("section2");
    } else {
      x.style.display = "none";
    }
  }

  myFunction3() {
    var x = document.getElementById("section3");
    if (x.style.display === "none") {
      x.style.display = "block";
      this.viewportScroller.scrollToAnchor("section3");
    } else {
      x.style.display = "none";
    }
  }

  myFunction4() {
    var x = document.getElementById("section4");
    if (x.style.display === "none") {
      x.style.display = "block";
      this.viewportScroller.scrollToAnchor("section4");
    } else {
      x.style.display = "none";
    }
  }

  myFunction5() {
  document.getElementById("section2").style.display = "none";
  document.getElementById("section3").style.display = "none";
  document.getElementById("section4").style.display = "none";
  }
}
