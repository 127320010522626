import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { DefaultComponent } from './layouts/default/default.component';
import { SimDefaultComponent } from './layouts/sim/default/default.component';
import { DashboardComponent } from './routes/dashboard/dashboard.component';
import { SimDashboardComponent } from './routes/sim/dashboard/dashboard.component';
import { AboutComponent } from './routes/about/about.component';
import { ContactComponent } from './routes/contact/contact.component';
import { LandingComponent } from './routes/landing/landing.component';
import { SimLeaderboardComponent } from './routes/sim/leaderboard/leaderboard.component';
import {LoginComponent} from './routes/login/login.component';
import {BlogComponent} from './routes/blog/blog.component';
import {PricingComponent} from './routes/pricing/pricing.component';
import {TosComponent} from './routes/tos/tos.component';
import {PrivacyComponent} from './routes/privacy/privacy.component';
import { TechnicalAComponent } from './routes/technical-a/technical-a.component';
import { EbookComponent } from './routes/ebook/ebook.component';

const routes: Routes = [
    {
        path: '',
        component: LandingComponent,
    },
    {
        path: 'default',
        component: DefaultComponent,
        children: [
            {
                path: '',
                component: DashboardComponent,
            },
            {
                path: 'about',
                component: AboutComponent,
            },
            {
                path: 'contact',
                component: ContactComponent,
            },
            {
                path: 'cancel-subscription',
                component: LoginComponent,
            },
            {
                path: 'pricing',
                component: PricingComponent,
            },
            {
                path: 'tos',
                component: TosComponent,
            },
            {
                path: 'privacy',
                component: PrivacyComponent,
            },
            {
                path: 'ebook',
                component: EbookComponent ,
            },
        ],
    },
    {
        path: 'sim-default',
        component: SimDefaultComponent,
        children: [
            {
                path: '',
                component: SimDashboardComponent,
            },
            {
                path: 'leaderboard',
                component: SimLeaderboardComponent,
            },
        ],
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        useHash: true,
        anchorScrolling: 'enabled'
    }), HttpClientModule],
    providers: [],
    exports: [RouterModule],
})
export class AppRoutingModule {}
