import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';


@Injectable({
    providedIn: 'root',
})
export class ResourceService {
    // tslint:disable-next-line: variable-name
    private _routePrefix = `${environment.apiBaseUrl}trading/resource`;

    constructor(private http: HttpClient) {}

    downloadEbook(): Observable<any> {
        return this.http.get(`${this._routePrefix}/ebook`);
    }

}
