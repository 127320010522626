<!DOCTYPE html>
<html>
<head>
    <!-- Theme Made By www.w3schools.com -->
    <title>No title</title>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">
    <link href="https://fonts.googleapis.com/css?family=Montserrat" rel="stylesheet" type="text/css">
    <link href="https://fonts.googleapis.com/css?family=Lato" rel="stylesheet" type="text/css">
    <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
    <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>
    <style>

   * {
   box-sizing: border-box;
  }

  body {
    font: 400 15px Lato, sans-serif;
    line-height: 1.8;
    color: #818181;
    width:100%;
    height: 100%;
    overflow-y:initial;
  }
  h2 {
    font-size: 24px;
    text-transform: uppercase;
    color: #303030;
    font-weight: 600;
    margin-bottom: 30px;
  }
  h4 {
    font-size: 19px;
    line-height: 1.375em;
    color: #303030;
    font-weight: 400;
    margin-bottom: 30px;
  }
  .jumbotron {
    background: linear-gradient(0.25turn, #d768e0, #1891d8);
    color: #fff;
    padding: 100px 25px;
    font-family: Montserrat, sans-serif;
  }
  .container-fluid {
    padding: 60px 50px;
  }
  .bg-grey {
    background-color: #f6f6f6;
  }
  .logo-small {
    color: linear-gradient(0.25turn, #d768e0, #1891d8);
    background-color: transparent;
    font-size: 50px;
  }
  .logo {
    color: #a571c9 ;
    background-color: transparent;
    font-size: 200px;
  }
  .thumbnail {
    padding: 0 0 15px 0;
    border: none;
    border-radius: 0;
  }
  .thumbnail img {
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
  }
  .carousel-control.right, .carousel-control.left {
    background-image: none;
    color: #f4511e;
  }
  .carousel-indicators li {
    border-color: #f4511e;
  }
  .carousel-indicators li.active {
    background-color: #f4511e;
  }
  .item h4 {
    font-size: 19px;
    line-height: 1.375em;
    font-weight: 400;
    font-style: italic;
    margin: 70px 0;
  }
  .item span {
    font-style: normal;
  }
  .panel {
    border: 1px solid #f4511e;
    border-radius:0 !important;
    transition: box-shadow 0.5s;
  }
  .panel:hover {
    box-shadow: 5px 0px 40px rgba(0,0,0, .2);
  }
  .panel-footer .btn:hover {
    border: 1px solid #f4511e;
    background-color: #fff !important;
    color: #f4511e;
  }
  .panel-heading {
    color: #fff !important;
    background-color: #f4511e !important;
    padding: 25px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .panel-footer {
    background-color: white !important;
  }
  .panel-footer h3 {
    font-size: 32px;
  }
  .panel-footer h4 {
    color: #aaa;
    font-size: 14px;
  }
  .panel-footer .btn {
    margin: 15px 0;
    background-color: #f4511e;
    color: #fff;
  }
  .navbar {
    margin-bottom: 0;
    background-color: #f4511e;
    z-index: 9999;
    border: 0;
    font-size: 12px !important;
    line-height: 1.42857143 !important;
    letter-spacing: 4px;
    border-radius: 0;
    font-family: Montserrat, sans-serif;
  }
  .navbar li a, .navbar .navbar-brand {
    color: #fff !important;
  }
  .navbar-nav li a:hover, .navbar-nav li.active a {
    color: #f4511e !important;
    background-color: #fff !important;
  }
  .navbar-default .navbar-toggle {
    border-color: transparent;
    color: #fff !important;
  }
  footer .glyphicon {
    font-size: 20px;
    margin-bottom: 20px;
    color: #f4511e;
  }
  .slideanim {visibility:hidden;}
  .slide {
    animation-name: slide;
    -webkit-animation-name: slide;
    animation-duration: 1s;
    -webkit-animation-duration: 1s;
    visibility: visible;
  }
  @keyframes slide {
    0% {
      opacity: 0;
      transform: translateY(70%);
    }
    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  }
  @-webkit-keyframes slide {
    0% {
      opacity: 0;
      -webkit-transform: translateY(70%);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0%);
    }
  }
  @media screen and (max-width: 768px) {
    .col-sm-4 {
      text-align: center;
      margin: 25px 0;
    }
    .btn-lg {
      width: 100%;
      margin-bottom: 35px;
    }
  }
  @media screen and (max-width: 480px) {
    .logo {
      font-size: 150px;
    }
  }
  </style>
</head>
<div>
    <body id="myPage" data-spy="scroll" data-target=".navbar" data-offset="60">

    <div class="jumbotron text-center">
        <h1>Exilio Labs</h1>
        <p>Take a Leap</p>
    </div>

    <!-- Container (About Section) -->
    <div class="container-fluid bg-grey">
        <div class="row">
            <div class="col-sm-8">
                <h2>About Us</h2><br>
                <h4>Exilio, from the latin to take a leap, Is the world's first devoted platform for
                    cryptocurrency technical analysis developed to allow users of all levels to begin
                    their journey in the cryptocurrency space. Exilio is a team of 4 Engineers and
                    developers working towards creating a platform that allows Users to see the power of
                    technical analysis, test the historical accuracy of indicators and perform in-depth
                    analysis using a fully customisable real live data set.</h4>
            </div>
            <div class="col-sm-4" *ngIf="isSelected2">
                <span  (click)="onClick2(); myFunction2()" class="glyphicon glyphicon-triangle-bottom logo"></span>
            </div>
            <div class="col-sm-4" *ngIf="!isSelected2">
                <h1>What We Do..</h1>
                <a href="#section2"  (click)="onClick2(); myFunction2()" class="glyphicon glyphicon-triangle-bottom logo"></a>
            </div>
        </div>
    </div>

    <div class="container-fluid bg-grey" id="section2" style="display:none" >
        <div class="row">
            <div class="col-sm-8">
                <h2>What We Do</h2><br id="we-do">
                <h4>Exilio offers Users the ability to perform technical analysis on live data for a multitude of crypto
                    currencies and the unique ability to test the historical accuracy of a range of trading indicators.
                    Users can customise their data sets to choose intervals, pairings, and periods for indicators. Users
                    can test indicators accuracy in returning a specified margin to allow for short term traders to manage
                    risk more effectively. Exilio Lab allows Users to test their current trading ability and compare it to
                    our User-database. Exilio provides E-books to upskill users in technical analysis and minimise their
                    trading risks and how to utilise our platform to its full potential.
                </h4>
            </div>
            <div class="col-sm-4" *ngIf="!isSelected3">
                <h1>Our Mission..</h1>
                <a href="#section3"  (click)="onClick3(); myFunction3()" class="glyphicon glyphicon-triangle-bottom logo"></a>
            </div>
            <div class="col-sm-4" *ngIf="isSelected3">
                <span (click)="onClick3(); myFunction3()" (click)="goToId('div3')" class="glyphicon glyphicon-triangle-bottom logo"></span>
            </div>
        </div>
    </div>
    <div class="container-fluid bg-grey" id="section3" style="display:none">
        <div class="row">
            <a id="div3"></a>
            <div class="col-sm-8">
                <h2>Our Mission</h2><br>
                <h4>To Provide an educational and learning tool for people of all trading levels that showcases the power
                    of technical analysis when used correctly. We aim to provide a fast, effective and interactive approach
                    to upskilling in the crypto currency trading space.
                </h4><br>
            </div>
            <div class="col-sm-4" *ngIf="!isSelected4">
                <h1>Our Platforms..</h1>
                <a href="#section4"  (click)="onClick4(); myFunction4()" class="glyphicon glyphicon-triangle-bottom logo"></a>
            </div>
            <div class="col-sm-4" *ngIf="isSelected4">
                <span (click)="onClick4(); myFunction4()" class="glyphicon glyphicon-triangle-bottom logo"></span>
            </div>
        </div>
    </div>
    <div class="container-fluid bg-grey" id="section4" style="display:none">
        <div class="row">
            <div class="col-sm-8">
                <h2>Our Platforms</h2><br>
                <h4>Exilio Lab- leverages the advantages of automated technical analysis through the use of our Analytics
                    Engine that allows users to run historical checks on the accuracy of trading indicators. Exilio Lab
                    offers a User friendly and highly visual approach to analysing crypto currency data. Exilio Sim– A
                    testing platform that allows users to test their trading ability. Users generate a score based on
                    choosing the correct decision and can see where they stand among the population on our leaderboard.
                    Exilio Sim offers hints and insights into why a chart moved in a certain direction to allow Users to
                    learn and adapt to changing market conditions.
                </h4><br>
                <br><button class="btn btn-default btn-lg" routerLink="/default/contact">Get in Touch</button>
            </div>
            <div class="col-sm-4">
                <span (click)="onClick1(); myFunction5()" class="glyphicon glyphicon-eject logo" style="cursor: pointer;"></span>
            </div>
        </div>
    </div>
    </body>
</div>


<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
<script>
$(document).ready(function(){
  // Add smooth scrolling to all links
  $("a").on('click', function(event) {

    // Make sure this.hash has a value before overriding default behavior
    if (this.hash !== "") {
      // Prevent default anchor click behavior
      event.preventDefault();

      // Store hash
      var hash = this.hash;

      // Using jQuery's animate() method to add smooth page scroll
      // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
      $('html, body').animate({
        scrollTop: $(hash).offset().top
      }, 800, function(){

        // Add hash (#) to URL when done scrolling (default click behavior)
        window.location.hash = hash;
      });
    } // End if
  });
});
</script>

</html>
