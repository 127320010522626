import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-technical-a',
  templateUrl: './technical-a.component.html',
  styleUrls: ['./technical-a.component.scss']
})
export class TechnicalAComponent implements OnInit {
  isSelected1: boolean =true;
  isSelected2: boolean;
  isSelected3: boolean;
  isSelected4: boolean;
  isSelected5: boolean;


  constructor() { }

  ngOnInit(): void {
  }
  onClick1() {
    this.isSelected1 = true;
    this.isSelected2 = false;
    this.isSelected3 = false;
    console.log(this.isSelected1)
    }
    onClick2() {
     this.isSelected1 = false;
     this.isSelected2 = true ;
     this.isSelected3 = false;
    }
    onClick3() {
     this.isSelected1 = false;
     this.isSelected2 = false;
     this.isSelected3 = true;
    }

}
