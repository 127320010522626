import { Component, OnInit } from '@angular/core';
import { Inject} from "@angular/core";


@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss'],
})
export class BlogComponent implements OnInit {
  isSelected1: boolean =true;
  isSelected2: boolean;
  isSelected3: boolean;
  isSelected4: boolean;
  isSelected5: boolean;

  constructor() { }

  ngOnInit(): void {
  }

  onClick1() {
  this.isSelected1 = true;
  this.isSelected2 = false;
  this.isSelected3 = false;
  this.isSelected4 = false;
  this.isSelected5 =false;
  }
  onClick2() {
   this.isSelected1 = false;
   this.isSelected2 = true ;
   this.isSelected3 = false;
   this.isSelected4 = false;
   this.isSelected5 =false;
  }
  onClick3() {
   this.isSelected1 = false;
   this.isSelected2 = false;
   this.isSelected3 = true;
   this.isSelected4 = false;
   this.isSelected5 =false;
  }
  onClick4() {
   this.isSelected1 = false;
   this.isSelected2 = false;
   this.isSelected3 = false;
   this.isSelected4 = true;
   this.isSelected5 =false;
  }
  onClick5() {
   this.isSelected1 = false;
   this.isSelected2 = false;
   this.isSelected3 = false;
   this.isSelected4 = false;
   this.isSelected5 =true;
  }
}
