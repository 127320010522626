import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DefaultModule } from './layouts/default/default.module';
import { DefaultDialogModule } from './layouts/default/default-dialog.module';
import {MatDialogModule} from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { AboutComponent } from './routes/about/about.component';
import { ContactComponent } from './routes/contact/contact.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxSpinnerModule } from "ngx-spinner";
import { ResponsiveModule } from 'ng2-responsive';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LandingComponent } from './routes/landing/landing.component';
import { SimDashboardComponent } from './routes/sim/dashboard/dashboard.component';
import { SimLeaderboardComponent } from './routes/sim/leaderboard/leaderboard.component';
import  {  PdfViewerModule  }  from  'ng2-pdf-viewer';
import {LoginComponent} from './routes/login/login.component';
import {PricingComponent} from './routes/pricing/pricing.component';
import {TosComponent} from './routes/tos/tos.component';
import {BlogComponent} from './routes/blog/blog.component';
import {PrivacyComponent} from './routes/privacy/privacy.component';
import { TechnicalAComponent } from './routes/technical-a/technical-a.component';
import { EbookComponent } from './routes/ebook/ebook.component';


@NgModule({
    declarations: [
        AppComponent,
        AboutComponent,
        ContactComponent,
        LandingComponent,
        SimLeaderboardComponent,
        TosComponent,
        BlogComponent,
        LoginComponent,
        PricingComponent,
        PrivacyComponent,
        TechnicalAComponent,
        EbookComponent,
        
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        DefaultDialogModule,
        BrowserAnimationsModule,
        MatCardModule,
        MatButtonModule,
        MatToolbarModule,
        MatTabsModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        DefaultModule,
        MatFormFieldModule,
        MatInputModule,
        MatDialogModule,
        FormsModule,
        MatButtonModule,
        MatPaginatorModule,
        MatTableModule,
        NgxSpinnerModule,
        MatTooltipModule,
        ResponsiveModule,
        FlexLayoutModule,
        PdfViewerModule
    ],
    providers: [SimDashboardComponent],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],

})
export class AppModule {}
