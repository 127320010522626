<html>
<title>W3.CSS</title>
<meta name="viewport" content="width=device-width, initial-scale=1">
<link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css">
<body>
<div class="row">
    <div class="column left" style="background-color: #C0C0C0; height:75vmax"></div>
    <div class="column middle">
        <div class="slideshow-container">

            <!-- Full-width images with number and caption text -->
            <div class="w3-container" *ngIf="isSelected1" style="margin-left:12.5%; margin-right:12.5%;">
                <div class="w3-card-4 w3-dark-grey" style="width:100%%; height:75%">
                    <div class="w3-container w3-center">
                        <h3 style="text-align: left; font-weight:bold;"> XRP On a Breakout (test)</h3>
                        <h3 style="text-align: left; font-size: small;"> <b><i>XRP/EUR</i></b>, <a style="font-style: italic;" >€1.28,</a>
                            <img src="assets/landing/images/Short.PNG" alt="Avatar" style="width:15%;" ></h3>
                        <img src="assets/landing/images/BTCUSDTGraph.PNG" alt="Avatar" style="width:100%;">
                        <h5>John Doe</h5>

                        <div class="w3-section">
                            <button class="w3-button w3-green">Long</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="w3-container" *ngIf="isSelected2" style="margin-left:12.5%; margin-right:12.5%;">
                <div class="w3-card-4 w3-dark-grey" style="width:100%">
                    <div class="w3-container w3-center">
                        <h3>ETH/EUR</h3>
                        <h3 style="text-align: left;">ETH/EUR, €2985, Short</h3>
                        <img src="assets/landing/images/Matthew.jpeg" alt="Avatar" style="width:80%">
                        <h5>John Doe</h5>

                        <div class="w3-section">
                            <button class="w3-button w3-red">Short</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="w3-container" *ngIf="isSelected3" style="margin-left:12.5%; margin-right:12.5%;">
                <div class="w3-card-4 w3-dark-grey" style="width:100%">
                    <div class="w3-container w3-center">
                        <h3>BTC/GBP</h3>
                        <img src="assets/landing/images/nate.png" alt="Avatar" style="width:80%">
                        <h5>John Doe</h5>

                        <div class="w3-section">
                            <button class="w3-button w3-green">Long</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <br>

        <!-- The dots/circles -->
        <div style="text-align:center">
            <span class="dot" (click)="onClick1()"></span>
            <span class="dot" (click)="onClick2()"></span>
            <span class="dot" (click)="onClick3()"></span>
        </div>
    </div>
    <div class="column right" style="background-color: #C0C0C0; height:75vmax"></div>
</div>
</body>
</html>
