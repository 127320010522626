<script>
   function toggleZoomScreen() {
       document.body.style.zoom = "90%";
   }
</script>
<ngx-spinner bdColor="rgba(16,16,25,0.8)" size="medium" color="#D768E0" type="square-jelly-box" fullScreen="false">
    <p style="font-size: 25px; color: white; font-weight: bolder">Running Analysis Engine...</p>
</ngx-spinner>
<div fxflex fxLayout="row" fxLayoutAlign="center space-between" fxLayoutGap="20px" style="margin-bottom: 5px;" >
    <div fxflex="40" class="tcontainer">
        <div class="ticker-wrap">
            <div class="ticker-move">
                <div class="ticker-item" *ngFor="let element of coinPrices">
                    {{ element.symbol }}: ${{ element.price }}
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!this.mobile" fxflex="20" fxFlexAlign="end" style="font-size: 12px">
        Last Updated: {{ this.lastUpdated }} (Refresh: {{ this.refreshPeriod/60 }} minutes)
    </div>
</div>
<div fxflex fxLayout="column" fxLayoutGap="20px">
    <div fxFlex="50">
        <div fxflex fxLayout="row" fxLayout.lt-lg="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
            <div fxFlex="65">
                <div style="text-align: center">
                        <div>
                            <highcharts-chart
                                [Highcharts]="Highcharts"
                                [options]="plotOptions"
                                style="width: 100%; height: 400px; display: block"
                            ></highcharts-chart>
                        </div>
                </div>
            </div>
            <div fxFlex="35">
                <div fxflex style="text-align: center" fxLayoutAlign="stretch stretch" fxLayout="column" fxLayoutGap="10px">
                    <div fxFlex="70">
                        <mat-card
                            fxLayout="row"
                            fxLayout.lt-lg="column"
                            fxLayoutAlign="start stretch"
                            fxLayoutGap="5px"
                            style="text-align: center"
                        >
                            <div fxFlex="50">
                                <mat-form-field [ngStyle]="{'font-size.px': 12}" style="margin-left: 10px" appearance="fill">
                                    <mat-label>Currency Selection</mat-label>
                                    <mat-select [(ngModel)]="currency">
                                        <mat-option *ngFor="let currency of currencies" [value]="currency.value">
                                            {{ currency.viewValue }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field [ngStyle]="{'font-size.px': 12}" style="margin-left: 10px" appearance="fill" *ngIf="this.options">
                                    <mat-label>Coin Pairing</mat-label>
                                    <mat-select [(ngModel)]="symbol">
                                        <mat-option *ngFor="let coin of pairings" [value]="coin.value">
                                            {{ coin.viewValue }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field [ngStyle]="{'font-size.px': 12}" style="margin-left: 10px" appearance="fill" *ngIf="this.options1">
                                    <mat-label>Stock</mat-label>
                                    <mat-select [(ngModel)]="symbol">
                                        <mat-option *ngFor="let coin of stocks" [value]="coin.value">
                                            {{ coin.viewValue }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field [ngStyle]="{'font-size.px': 12}" style="margin-left: 10px" appearance="fill" *ngIf="this.options2">
                                    <mat-label>Coin Pairing</mat-label>
                                    <mat-select [(ngModel)]="symbol">
                                        <mat-option *ngFor="let coin of currencies" [value]="coin.value">
                                            {{ coin.viewValue }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field [ngStyle]="{'font-size.px': 12}" style="margin-left: 10px" appearance="fill" *ngIf="this.options">
                                    <mat-label>Interval</mat-label>
                                    <mat-select [(ngModel)]="interval">
                                        <mat-option *ngFor="let interval of intervals" [value]="interval.value">
                                            {{ interval.viewValue }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field [ngStyle]="{'font-size.px': 12}" style="margin-left: 10px" appearance="fill" *ngIf="!this.options">
                                    <mat-label>Interval</mat-label>
                                    <mat-select [(ngModel)]="interval">
                                        <mat-option *ngFor="let interval of times" [value]="interval.value">
                                            {{ interval.viewValue }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field [ngStyle]="{'font-size.px': 12}" style="margin-left: 10px" appearance="fill">
                                    <mat-label>Margin</mat-label>
                                    <input matInput placeholder="Ex.1=1%" [(ngModel)]="margin">
                                </mat-form-field>

                                <mat-form-field [ngStyle]="{'font-size.px': 12}" style="margin-left: 10px" appearance="fill">
                                    <mat-label>Indicator</mat-label>
                                    <mat-select [(ngModel)]="indicator">
                                        <mat-option *ngFor="let indicator of indicators" [value]="indicator.value">
                                            {{ indicator.viewValue }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                             <p style="color: red;" *ngIf="this.fault"> *Currency Pairings Cannot Be the Same Currency</p>   
                            </div>
                            
                            <div fxFlex="50">
                                <br />
                                <div
                                    fxLayout="column"
                                    fxLayoutAlign="center space-between"
                                    fxLayoutGap="0px"
                                    style="text-align: center"
                                >
                                <div fxFlex="33">

                                </div>
                                    <div fxFlex="33">
                                        <h4>Coin Price</h4>
                                        <p>
                                            {{ this.currency_Object}}{{ this.price }}</p>
                                    </div>

                                    <div fxFlex="33">
                                        <button
                                            mat-raised-button
                                            style="background: linear-gradient(0.25turn, #d768e0, #1891d8)"
                                            (click)="this.updateInfo()"
                                        >
                                            Update Info
                                        </button>
                                    </div>
                                    <br>
                                    <div fxFlex="33">
                                        <button
                                            mat-raised-button
                                            style="background: linear-gradient(0.25turn, #d768e0, #1891d8)"
                                            (click)="this.downloadCSV()"
                                            id="downloadBtn"
                                        >
                                            Exilio-Export
                                        </button>
                                    </div>
                                    <script src="script.js"></script>
                                    <br>
                                    <div fxFlex="33">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Exilio-Export ID</mat-label>
                                            <input matInput id="exportIdInput"/>
                                            <mat-icon matSuffix>lock_open</mat-icon>
                                            <p style="color: red;" *ngIf="this.ExilioExportCodeError != null"> <small>*Invalid <b>ExilioExportID</b>. Sign Up for <b>ExilioExportID</b> here:  <b><a href="https://www.exiliolabs.com/#/default/pricing" style="color: dodgerblue ;">ExilioExport</a></b></small>. </p>
                                        </mat-form-field>
                                    </div>
                                    <!--
                                    <div fxFlex="33" *ngIf="!this.options1">
                                        <button
                                            mat-raised-button
                                            style="background: linear-gradient(0.25turn, #d768e0, #1891d8)"
                                            (click)="this.switchDashboard1()"
                                        >
                                            S&P
                                        </button>
                                    </div>
                                    <br *ngIf="!this.options1">
                                    <div fxFlex="33" *ngIf="!this.options2">
                                        <button
                                            mat-raised-button
                                            style="background: linear-gradient(0.25turn, #d768e0, #1891d8)"
                                            (click)="this.switchDashboard2()"
                                        >
                                            Forex
                                        </button>
                                    </div>
                                    <br *ngIf="!this.options2">
                                    <div fxFlex="33" *ngIf="!this.options">
                                        <button
                                            mat-raised-button
                                            style="background: linear-gradient(0.25turn, #d768e0, #1891d8)"
                                            (click)="this.switchDashboard()"
                                        >
                                            Crypto
                                        </button>
                                    </div>
                                    -->
                                </div>
                                <br>

                            </div>
                        </mat-card>
                    </div>
                    <div fxflex="30">
                        <mat-card style="text-align: center">
                            <h4>Indicator</h4>
                            <div style="text-align: justify; font-size: smaller">
                                <p *ngIf="this.indicatorView == 'adxView'">
                                    The ADX indicator is an average of expanding price range values. The ADX is a
                                    component of the Directional Movement System developed by Welles Wilder. This system
                                    attempts to measure the strength of price movement in positive and negative
                                    direction using the DMI+ and DMI- indicators along with the ADX. This indicators has
                                    been adapted by Exlio Labs using advanced statistical analysis to increase its accuracy. 
                                </p>
                                <p *ngIf="this.indicatorView == 'rsiView'">
                                    The relative strength index (RSI) is a momentum indicator used in technical analysis
                                    that measures the magnitude of recent price changes to evaluate overbought or
                                    oversold conditions in the price of a stock or other asset. This indicators has
                                    been adapted by Exlio Labs using advanced statistical analysis to increase its accuracy. 
                                </p>
                                <p *ngIf="this.indicatorView == 'macdView'">
                                    Moving average convergence divergence (MACD) is a trend-following momentum indicator
                                    that shows the relationship between two moving averages of a security’s price.The
                                    result of that calculation is the MACD line. A nine-day EMA of the MACD called the
                                    "signal line," is then plotted on top of the MACD line, which can function as a
                                    trigger for buy and sell signals. This indicators has been adapted by Exlio Labs using
                                    advanced statistical analysis to increase its accuracy. 
                                </p>
                                <p *ngIf="this.indicatorView == 'rocView'">
                                    The Rate of Change (ROC) indicator compares the current price with the previous
                                    price from a selected number of periods ago. The current price is divided by the
                                    previous price and expressed as a percentage. This indicator is also commonly known
                                    as a momentum indicator. This indicators has
                                    been adapted by Exlio Labs using advanced statistical analysis to increase its accuracy. 
                                </p>
                                <p *ngIf="this.indicatorView == 'uiView'">
                                    The Ulcer Index (UI) is a technical indicator that measures downside risk in terms
                                    of both the depth and duration of price declines. The index increases in value as
                                    the price moves farther away from a recent high and falls as the price rises to new
                                    highs. This indicators has
                                    been adapted by Exlio Labs using advanced statistical analysis to increase its accuracy. 
                                </p>
                                <p *ngIf="this.indicatorView == 'obvView'">
                                    On Balance Volume (OBV) measures buying and selling pressure as a cumulative
                                    indicator that adds volume on up days and subtracts volume on down days. When the
                                    security closes higher than the previous close, all of the day's volume is
                                    considered up-volume. This indicators has
                                    been adapted by Exlio Labs using advanced statistical analysis to increase its accuracy. 
                                </p>
                                <p *ngIf="this.indicatorView == 'aoView'">
                                    The Awesome Oscillator (AO) is an indicator used to measure market momentum. AO calculates
                                    the difference between a 34 Period and 5 Period Simple Moving Average. The Simple
                                    Moving Averages that are used are not calculated using closing price but rather each
                                    bar's midpoints. AO is generally used to affirm trends or to anticipate possible reversals.
                                    This indicators has
                                    been adapted by Exlio Labs using advanced statistical analysis to increase its accuracy. 
                                </p>
                                <p *ngIf="this.indicatorView == 'kamaView'">
                                    Kaufman's Adaptive Moving Average (KAMA) is an intelligent moving average that was
                                    developed by Perry Kaufman. The powerful trend-following indicator is based on the
                                    Exponential Moving Average (EMA) and is responsive to both trend and volatility. It
                                    closely follows price when noise is low and smooths out the noise when price fluctuates. This indicators has
                                    been adapted by Exlio Labs using advanced statistical analysis to increase its accuracy. 
                                </p>
                                <p *ngIf="this.indicatorView == 'stochView'">
                                    A stochastic oscillator is a momentum indicator comparing a particular closing price
                                    of a security to a range of its prices over a certain period of time. The sensitivity
                                    of the oscillator to market movements is reducible by adjusting that time period or
                                    by taking a moving average of the result. It is used to generate overbought and oversold
                                    trading signals, utilizing a 0–100 bounded range of values. This indicators has
                                    been adapted by Exlio Labs using advanced statistical analysis to increase its accuracy. 
                                </p>
                                <p *ngIf="this.indicatorView == 'tsiView'">
                                    The true strength index is a technical momentum oscillator used to identify trends and
                                    reversals. The indicator may be useful for determining overbought and oversold conditions,
                                    indicating potential trend direction changes via centerline or signal line crossovers,
                                    and warning of trend weakness through divergence. This indicators has
                                    been adapted by Exlio Labs using advanced statistical analysis to increase its accuracy. 
                                </p>
                                <p *ngIf="this.indicatorView == 'uoView'">
                                    The Ultimate Oscillator indicator (UO) indicator is a technical analysis tool used to
                                    measure momentum across three varying timeframes. The problem with many momentum oscillators
                                    is that after a rapid advance or decline in price, they can form false divergence trading
                                    signals. This indicators has
                                    been adapted by Exlio Labs using advanced statistical analysis to increase its accuracy. 
                                </p>
                                <p *ngIf="this.indicatorView == 'adiView'">
                                    The accumulation/distribution indicator (A/D) is a cumulative indicator that uses volume
                                    and price to assess whether a stock is being accumulated or distributed. The A/D measure
                                    seeks to identify divergences between the stock price and volume flow. This indicators has
                                    been adapted by Exlio Labs using advanced statistical analysis to increase its accuracy. 
                                </p>
                                <p *ngIf="this.indicatorView == 'fiiView'">
                                    The force index is a technical indicator that measures the amount of power used to move
                                    the price of an asset. The force index uses price and volume to determine the amount of
                                    strength behind a price move. The index is an oscillator, fluctuating between positive
                                    and negative territory. It is unbounded meaning the index can go up or down indefinitely.
                                    This indicators has
                                    been adapted by Exlio Labs using advanced statistical analysis to increase its accuracy. 
                                </p>
                                <p *ngIf="this.indicatorView == 'atrView'">
                                    The average true range (ATR) is a technical analysis indicator, introduced by market
                                    technician J. Welles Wilder Jr. in his book New Concepts in Technical Trading Systems,
                                    that measures market volatility by decomposing the entire range of an asset price for
                                    that period. This indicators has
                                    been adapted by Exlio Labs using advanced statistical analysis to increase its accuracy. 
                                </p>
                                <p *ngIf="this.indicatorView == 'bbView'">
                                    Bollinger Bands are envelopes plotted at a standard deviation level above and below a
                                    simple moving average of the price. Because the distance of the bands is based on
                                    standard deviation, they adjust to volatility swings in the underlying price. This indicators has
                                    been adapted by Exlio Labs using advanced statistical analysis to increase its accuracy. 
                                </p>
                            </div>
                        </mat-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
<mat-divider></mat-divider>
    <div fxFlex="50">
        <div fxflex fxLayout="row" fxLayout.lt-lg="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
            <div fxFlex="65">
                <div style="text-align: center">
                        <div *ngIf="this.indicatorView == 'adxView'">
                            <highcharts-chart
                                [Highcharts]="Highcharts"
                                [options]="adxOptions"
                                style="width: 100%; height: 350px; display: block"
                            ></highcharts-chart>
                        </div>
                        <div *ngIf="this.indicatorView == 'rsiView'">
                            <highcharts-chart
                                [Highcharts]="Highcharts"
                                [options]="rsiOptions"
                                style="width: 100%; height: 350px; display: block"
                            ></highcharts-chart>
                        </div>
                         <div *ngIf="this.indicatorView == 'macdView'">
                        <highcharts-chart
                            [Highcharts]="Highcharts"
                            [options]="macdOptions"
                            style="width: 100%; height: 350px; display: block"
                        ></highcharts-chart>
                        </div>
                        <div *ngIf="this.indicatorView == 'rocView'">
                        <highcharts-chart
                            [Highcharts]="Highcharts"
                            [options]="rocOptions"
                            style="width: 100%; height: 350px; display: block"
                        ></highcharts-chart>
                        </div>
                        <div *ngIf="this.indicatorView == 'uiView'">
                        <highcharts-chart
                            [Highcharts]="Highcharts"
                            [options]="uiOptions"
                            style="width: 100%; height: 350px; display: block"
                        ></highcharts-chart>
                        </div>
                        <div *ngIf="this.indicatorView == 'obvView'">
                        <highcharts-chart
                            [Highcharts]="Highcharts"
                            [options]="obvOptions"
                            style="width: 100%; height: 350px; display: block"
                        ></highcharts-chart>
                        </div>
                        <div *ngIf="this.indicatorView == 'aoView'">
                        <highcharts-chart
                            [Highcharts]="Highcharts"
                            [options]="aoOptions"
                            style="width: 100%; height: 350px; display: block"
                        ></highcharts-chart>
                        </div>
                        <div *ngIf="this.indicatorView == 'kamaView'">
                        <highcharts-chart
                            [Highcharts]="Highcharts"
                            [options]="kamaOptions"
                            style="width: 100%; height: 350px; display: block"
                        ></highcharts-chart>
                        </div>
                        <div *ngIf="this.indicatorView == 'stochView'">
                        <highcharts-chart
                            [Highcharts]="Highcharts"
                            [options]="stochOptions"
                            style="width: 100%; height: 350px; display: block"
                        ></highcharts-chart>
                        </div>
                        <div *ngIf="this.indicatorView == 'tsiView'">
                        <highcharts-chart
                            [Highcharts]="Highcharts"
                            [options]="tsiOptions"
                            style="width: 100%; height: 350px; display: block"
                        ></highcharts-chart>
                        </div>
                        <div *ngIf="this.indicatorView == 'uoView'">
                        <highcharts-chart
                            [Highcharts]="Highcharts"
                            [options]="uoOptions"
                            style="width: 100%; height: 350px; display: block"
                        ></highcharts-chart>
                        </div>
                        <div *ngIf="this.indicatorView == 'adiView'">
                        <highcharts-chart
                            [Highcharts]="Highcharts"
                            [options]="adiOptions"
                            style="width: 100%; height: 350px; display: block"
                        ></highcharts-chart>
                        </div>
                        <div *ngIf="this.indicatorView == 'fiiView'">
                        <highcharts-chart
                            [Highcharts]="Highcharts"
                            [options]="fiiOptions"
                            style="width: 100%; height: 350px; display: block"
                        ></highcharts-chart>
                        </div>
                        <div *ngIf="this.indicatorView == 'atrView'">
                        <highcharts-chart
                            [Highcharts]="Highcharts"
                            [options]="atrOptions"
                            style="width: 100%; height: 350px; display: block"
                        ></highcharts-chart>
                        </div>
                        <div *ngIf="this.indicatorView == 'bbView'">
                        <highcharts-chart
                            [Highcharts]="Highcharts"
                            [options]="bbOptions"
                            style="width: 100%; height: 350px; display: block"
                        ></highcharts-chart>
                        </div>
                </div>
            </div>
            <div fxFlex="20" *ngIf="this.indicatorView == 'adxView'">
                <div style="text-align: center">
                    <mat-card>
                        <h3>Average Directional Indicator</h3>
                        <br />
                        <mat-divider></mat-divider>
                        <br />
                        <p><b>Accuracy of Bullish Signal :</b> {{ this.adxResult.currentPlusDI }}</p>
                        <p><b>Accuracy of Bearish Signal :</b> {{ this.adxResult.currentNegDI }}</p>
                        <p><b>Current ADX :</b> {{ this.adxResult.currentADX }}</p>
                        <p><b>Signal Suggestion:</b> {{ this.adxResult.signalSuggestion }}</p>
                        <p><b>Buy Sell Signal:</b> {{ this.adxResult.buySellSignal }}</p>
                        <br />
                        <p style="font-size: 0.8em;"> <b>* <i> This is not financial advice.
                            This information is for educational purposes only!</i></b></p>
                        <mat-divider></mat-divider>
                        <br />
                        <button
                            mat-raised-button
                            style="background: linear-gradient(0.25turn, #d768e0, #1891d8)"
                            (click)="redirectLearnMore()"
                        >
                            Learn More
                        </button>
                    </mat-card>
                </div>
            </div>
            <div fxFlex="20" *ngIf="this.indicatorView == 'rsiView'">
                <div style="text-align: center">
                    <mat-card>
                        <h3>Relative Strength Index</h3>
                        <br />
                        <mat-divider></mat-divider>
                        <br />
                        <p><b>Accuracy of Bullish Signal :</b> {{ this.rsiResult.Accuracyofbuy }}</p>
                        <p><b>Accuracy of Bearish Signal :</b> {{ this.rsiResult.Accuracyofsell }}</p>
                        <p><b>Current RSI :</b> {{ this.rsiResult.incicatorvalue }}</p>
                        <p><b>Signal Suggestion:</b> {{ this.rsiResult.signalsuggestion }}</p>
                        <p><b>Buy Sell Signal:</b> {{ this.rsiResult.indication }}</p>
                        <br />
                        <p style="font-size: 0.8em;"> <b>* <i> This is not financial advice.
                             This information is for educational purposes only!</i></b></p>
                        <mat-divider></mat-divider>
                        <br />
                        <button
                            mat-raised-button
                            style="background: linear-gradient(0.25turn, #d768e0, #1891d8)"
                            (click)="redirectLearnMore()"
                        >
                            Learn More
                        </button>
                        
                    </mat-card>
                </div>
            </div>
            <div fxFlex="20" *ngIf="this.indicatorView == 'macdView'">
                <div style="text-align: center">
                    <mat-card>
                        <h3>Moving Average (Convergence-Divergence)</h3>
                        <br />
                        <mat-divider></mat-divider>
                        <br />
                        <p><b>Accuracy of Bullish Signal :</b> {{ this.macdResult.Accuracyofbuy }}</p>
                        <p><b>Accuracy of Bearish Signal :</b> {{ this.macdResult.Accuracyofsell }}</p>
                        <p><b>Current MACD :</b> {{ this.macdResult.incicatorvalue }}</p>
                        <p><b>Signal Suggestion:</b> {{ this.macdResult.signalsuggestion }}</p>
                        <p><b>Buy Sell Signal:</b> {{ this.macdResult.indication }}</p>
                        <br />
                        <p style="font-size: 0.8em;"> <b>* <i> This is not financial advice.
                            This information is for educational purposes only!</i></b></p>
                        <mat-divider></mat-divider>
                        <br />
                        <button
                            mat-raised-button
                            style="background: linear-gradient(0.25turn, #d768e0, #1891d8)"
                            (click)="redirectLearnMore()"
                        >
                            Learn More
                        </button>
                    </mat-card>
                </div>
            </div>
            <div fxFlex="20" *ngIf="this.indicatorView == 'rocView'">
                <div style="text-align: center">
                    <mat-card>
                        <h3>Rate of Change</h3>
                        <br />
                        <mat-divider></mat-divider>
                        <br />
                        <p><b>Accuracy of Bullish Signal :</b> {{ this.rocResult.Accuracyofbuy }}</p>
                        <p><b>Accuracy of Bearish Signal :</b> {{ this.rocResult.Accuracyofsell }}</p>
                        <p><b>Current ROC :</b> {{ this.rocResult.incicatorvalue }}</p>
                        <p><b>Signal Suggestion:</b> {{ this.rocResult.signalsuggestion }}</p>
                        <p><b>Buy Sell Signal:</b> {{ this.rocResult.indication }}</p>
                        <br />
                        <p style="font-size: 0.8em;"> <b>* <i> This is not financial advice.
                            This information is for educational purposes only!</i></b></p>
                        <mat-divider></mat-divider>
                        <br />
                        <button
                            mat-raised-button
                            style="background: linear-gradient(0.25turn, #d768e0, #1891d8)"
                            (click)="redirectLearnMore()"
                        >
                            Learn More
                        </button>
                    </mat-card>
                </div>
            </div>
            <div fxFlex="20" *ngIf="this.indicatorView == 'uiView'">
                <div style="text-align: center">
                    <mat-card>
                        <h3>Ulcer Index</h3>
                        <br />
                        <mat-divider></mat-divider>
                        <br />
                        <p><b>Accuracy of Bullish Signal :</b> {{ this.uiResult.Accuracyofbuy }}</p>
                        <p><b>Accuracy of Bearish Signal :</b> {{ this.uiResult.Accuracyofsell }}</p>
                        <p><b>Current UI :</b> {{ this.uiResult.incicatorvalue }}</p>
                        <p><b>Signal Suggestion:</b> {{ this.uiResult.signalsuggestion }}</p>
                        <p><b>Buy Sell Signal:</b> {{ this.uiResult.indication }}</p>
                        <br />
                        <p style="font-size: 0.8em;"> <b>* <i> This is not financial advice.
                            This information is for educational purposes only!</i></b></p>
                        <mat-divider></mat-divider>
                        <br />
                        <button
                            mat-raised-button
                            style="background: linear-gradient(0.25turn, #d768e0, #1891d8)"
                            (click)="redirectLearnMore()"
                        >
                            Learn More
                        </button>
                    </mat-card>
                </div>
            </div>
            <div fxFlex="20" *ngIf="this.indicatorView == 'obvView'">
                <div style="text-align: center">
                    <mat-card>
                        <h3>On Balance Volume</h3>
                        <br />
                        <mat-divider></mat-divider>
                        <br />
                        <p><b>Accuracy of Bullish Signal :</b> {{ this.obvResult.Accuracyofbuy }}</p>
                        <p><b>Accuracy of Bearish Signal :</b> {{ this.obvResult.Accuracyofsell }}</p>
                        <p><b>Current OBV :</b> {{ this.obvResult.incicatorvalue }}</p>
                        <p><b>Signal Suggestion:</b> {{ this.obvResult.signalsuggestion }}</p>
                        <p><b>Buy Sell Signal:</b> {{ this.obvResult.indication }}</p>
                        <br />
                        <p style="font-size: 0.8em;"> <b>* <i> This is not financial advice.
                            This information is for educational purposes only!</i></b></p>
                        <mat-divider></mat-divider>
                        <br />
                        <button
                            mat-raised-button
                            style="background: linear-gradient(0.25turn, #d768e0, #1891d8)"
                            (click)="redirectLearnMore()"
                        >
                            Learn More
                        </button>
                    </mat-card>
                </div>
            </div>
            <div fxFlex="20" *ngIf="this.indicatorView == 'aoView'">
                <div style="text-align: center">
                    <mat-card>
                        <h3>AO</h3>
                        <br />
                        <mat-divider></mat-divider>
                        <br />
                        <p><b>Accuracy of Bullish Signal :</b> {{ this.aoResult.Accuracyofbuy }}</p>
                        <p><b>Accuracy of Bearish Signal :</b> {{ this.aoResult.Accuracyofsell }}</p>
                        <p><b>Current AO :</b> {{ this.aoResult.incicatorvalue }}</p>
                        <p><b>Signal Suggestion:</b> {{ this.aoResult.signalsuggestion }}</p>
                        <p><b>Buy Sell Signal:</b> {{ this.aoResult.indication }}</p>
                        <br />
                        <mat-divider></mat-divider>
                        <br />
                        <p style="font-size: 0.8em;"> <b>* <i> This is not financial advice.
                            This information is for educational purposes only!</i></b></p>
                        <button
                            mat-raised-button
                            style="background: linear-gradient(0.25turn, #d768e0, #1891d8)"
                            (click)="redirectLearnMore()"
                        >
                            Learn More
                        </button>
                    </mat-card>
                </div>
            </div>
            <div fxFlex="20" *ngIf="this.indicatorView == 'kamaView'">
                <div style="text-align: center">
                    <mat-card>
                        <h3>KAMA</h3>
                        <br />
                        <mat-divider></mat-divider>
                        <br />
                        <p><b>Accuracy of Bullish Signal :</b> {{ this.kamaResult.Accuracyofbuy }}</p>
                        <p><b>Accuracy of Bearish Signal :</b> {{ this.kamaResult.Accuracyofsell }}</p>
                        <p><b>Current KAMA :</b> {{ this.kamaResult.incicatorvalue }}</p>
                        <p><b>Signal Suggestion:</b> {{ this.kamaResult.signalsuggestion }}</p>
                        <p><b>Buy Sell Signal:</b> {{ this.kamaResult.indication }}</p>
                        <br />
                        <p style="font-size: 0.8em;"> <b>* <i> This is not financial advice.
                            This information is for educational purposes only!</i></b></p>
                        <mat-divider></mat-divider>
                        <br />
                        <button
                            mat-raised-button
                            style="background: linear-gradient(0.25turn, #d768e0, #1891d8)"
                            (click)="redirectLearnMore()"
                        >
                            Learn More
                        </button>
                    </mat-card>
                </div>
            </div>
            <div fxFlex="20" *ngIf="this.indicatorView == 'stochView'">
                <div style="text-align: center">
                    <mat-card>
                        <h3>Stoch</h3>
                        <br />
                        <mat-divider></mat-divider>
                        <br />
                        <p><b>Accuracy of Bullish Signal :</b> {{ this.stochResult.Accuracyofbuy }}</p>
                        <p><b>Accuracy of Bearish Signal :</b> {{ this.stochResult.Accuracyofsell }}</p>
                        <p><b>Current Stoch :</b> {{ this.stochResult.incicatorvalue }}</p>
                        <p><b>Signal Suggestion:</b> {{ this.stochResult.signalsuggestion }}</p>
                        <p><b>Buy Sell Signal:</b> {{ this.stochResult.indication }}</p>
                        <br />
                        <p style="font-size: 0.8em;"> <b>* <i> This is not financial advice.
                            This information is for educational purposes only!</i></b></p>
                        <mat-divider></mat-divider>
                        <br />
                        <button
                            mat-raised-button
                            style="background: linear-gradient(0.25turn, #d768e0, #1891d8)"
                            (click)="redirectLearnMore()"
                        >
                            Learn More
                        </button>
                    </mat-card>
                </div>
            </div>
            <div fxFlex="20" *ngIf="this.indicatorView == 'tsiView'">
                <div style="text-align: center">
                    <mat-card>
                        <h3>TSI</h3>
                        <br />
                        <mat-divider></mat-divider>
                        <br />
                        <p><b>Accuracy of Bullish Signal :</b> {{ this.tsiResult.Accuracyofbuy }}</p>
                        <p><b>Accuracy of Bearish Signal :</b> {{ this.tsiResult.Accuracyofsell }}</p>
                        <p><b>Current TSI :</b> {{ this.tsiResult.incicatorvalue }}</p>
                        <p><b>Signal Suggestion:</b> {{ this.tsiResult.signalsuggestion }}</p>
                        <p><b>Buy Sell Signal:</b> {{ this.tsiResult.indication }}</p>
                        <br />
                        <p style="font-size: 0.8em;"> <b>* <i> This is not financial advice.
                            This information is for educational purposes only!</i></b></p>
                        <mat-divider></mat-divider>
                        <br />
                        <button
                            mat-raised-button
                            style="background: linear-gradient(0.25turn, #d768e0, #1891d8)"
                            (click)="redirectLearnMore()"
                        >
                            Learn More
                        </button>
                    </mat-card>
                </div>
            </div>
            <div fxFlex="20" *ngIf="this.indicatorView == 'uoView'">
                <div style="text-align: center">
                    <mat-card>
                        <h3>UO</h3>
                        <br />
                        <mat-divider></mat-divider>
                        <br />
                        <p><b>Accuracy of Bullish Signal :</b> {{ this.uoResult.Accuracyofbuy }}</p>
                        <p><b>Accuracy of Bearish Signal :</b> {{ this.uoResult.Accuracyofsell }}</p>
                        <p><b>Current UO :</b> {{ this.uoResult.incicatorvalue }}</p>
                        <p><b>Signal Suggestion:</b> {{ this.uoResult.signalsuggestion }}</p>
                        <p><b>Buy Sell Signal:</b> {{ this.uoResult.indication }}</p>
                        <br />
                        <p style="font-size: 0.8em;"> <b>* <i> This is not financial advice.
                            This information is for educational purposes only!</i></b></p>
                        <mat-divider></mat-divider>
                        <br />
                        <button
                            mat-raised-button
                            style="background: linear-gradient(0.25turn, #d768e0, #1891d8)"
                            (click)="redirectLearnMore()"
                        >
                            Learn More
                        </button>
                    </mat-card>
                </div>
            </div>
            <div fxFlex="20" *ngIf="this.indicatorView == 'adiView'">
                <div style="text-align: center">
                    <mat-card>
                        <h3>ADI</h3>
                        <br />
                        <mat-divider></mat-divider>
                        <br />
                        <p><b>Accuracy of Bullish Signal :</b> {{ this.adiResult.Accuracyofbuy }}</p>
                        <p><b>Accuracy of Bearish Signal :</b> {{ this.adiResult.Accuracyofsell }}</p>
                        <p><b>Current ADI :</b> {{ this.adiResult.incicatorvalue }}</p>
                        <p><b>Signal Suggestion:</b> {{ this.adiResult.signalsuggestion }}</p>
                        <p><b>Buy Sell Signal:</b> {{ this.adiResult.indication }}</p>
                        <br />
                        <p style="font-size: 0.8em;"> <b>* <i> This is not financial advice.
                            This information is for educational purposes only!</i></b></p>
                        <mat-divider></mat-divider>
                        <br />
                        <button
                            mat-raised-button
                            style="background: linear-gradient(0.25turn, #d768e0, #1891d8)"
                            (click)="redirectLearnMore()"
                        >
                            Learn More
                        </button>
                    </mat-card>
                </div>
            </div>
            <div fxFlex="20" *ngIf="this.indicatorView == 'fiiView'">
                <div style="text-align: center">
                    <mat-card>
                        <h3>Force Index</h3>
                        <br />
                        <mat-divider></mat-divider>
                        <br />
                        <p><b>Accuracy of Bullish Signal :</b> {{ this.fiiResult.Accuracyofbuy }}</p>
                        <p><b>Accuracy of Bearish Signal :</b> {{ this.fiiResult.Accuracyofsell }}</p>
                        <p><b>Current FI :</b> {{ this.fiiResult.incicatorvalue }}</p>
                        <p><b>Signal Suggestion:</b> {{ this.fiiResult.signalsuggestion }}</p>
                        <p><b>Buy Sell Signal:</b> {{ this.fiiResult.indication }}</p>
                        <br />
                        <p style="font-size: 0.8em;"> <b>* <i> This is not financial advice.
                            This information is for educational purposes only!</i></b></p>
                        <mat-divider></mat-divider>
                        <br />
                        <button
                            mat-raised-button
                            style="background: linear-gradient(0.25turn, #d768e0, #1891d8)"
                            (click)="redirectLearnMore()"
                        >
                            Learn More
                        </button>
                    </mat-card>
                </div>
            </div>
            <div fxFlex="20" *ngIf="this.indicatorView == 'atrView'">
                <div style="text-align: center">
                    <mat-card>
                        <h3>Average True Range</h3>
                        <br />
                        <mat-divider></mat-divider>
                        <br />
                        <p><b>Accuracy of Bullish Signal :</b> {{ this.atrResult.Accuracyofbuy }}</p>
                        <p><b>Accuracy of Bearish Signal :</b> {{ this.atrResult.Accuracyofsell }}</p>
                        <p><b>Current ATR :</b> {{ this.atrResult.incicatorvalue }}</p>
                        <p><b>Signal Suggestion:</b> {{ this.atrResult.signalsuggestion }}</p>
                        <p><b>Buy Sell Signal:</b> {{ this.atrResult.indication }}</p>
                        <br />
                        <p style="font-size: 0.8em;"> <b>* <i> This is not financial advice.
                            This information is for educational purposes only!</i></b></p>
                        <mat-divider></mat-divider>
                        <br />
                        <button
                            mat-raised-button
                            style="background: linear-gradient(0.25turn, #d768e0, #1891d8)"
                            (click)="redirectLearnMore()"
                        >
                            Learn More
                        </button>
                    </mat-card>
                </div>
            </div>
            <div fxFlex="20" *ngIf="this.indicatorView == 'bbView'">
                <div style="text-align: center">
                    <mat-card>
                        <h3>Bollinger Band</h3>
                        <br />
                        <mat-divider></mat-divider>
                        <br />
                        <p><b>Accuracy of Bullish Signal :</b> {{ this.bbResult.Accuracyofbuy }}</p>
                        <p><b>Accuracy of Bearish Signal :</b> {{ this.bbResult.Accuracyofsell }}</p>
                        <p><b>Current BB :</b> {{ this.bbResult.incicatorvalue }}</p>
                        <p><b>Signal Suggestion:</b> {{ this.bbResult.signalsuggestion }}</p>
                        <p><b>Buy Sell Signal:</b> {{ this.bbResult.indication }}</p>
                        <br />
                        <p style="font-size: 0.8em;"> <b>* <i> This is not financial advice.
                            This information is for educational purposes only!</i></b></p>
                        <mat-divider></mat-divider>
                        <br />
                        <button
                            mat-raised-button
                            style="background: linear-gradient(0.25turn, #d768e0, #1891d8)"
                            (click)="redirectLearnMore()"
                        >
                            Learn More
                        </button>
                    </mat-card>
                </div>
            </div>

            <div fxFlex="12.5" >
                <div
                    fxFlex
                    style="text-align: center"
                    fxLayout="column"
                    fxLayout.lt-lg="row"
                    fxLayout.lt-sm="column"
                    fxLayoutGap="20px"
                >
                    <mat-card fxFlex="100">
                        <h3>ExilioLabs Book</h3>
                        <br />
                        <mat-divider></mat-divider>
                        <br>
                        <img style="max-width:50%; max-height:50%;" src="assets/imgs/BookCoverExilio.png" alt="" />
                        <br />
                        <br>
                        <br>
                        <br>
                        <br>
                        <mat-divider></mat-divider>
                        <br>
                        <button
                                style="padding: 5px 0px 5px 0px; width: 100%;"
                                mat-raised-button
                                style="background: linear-gradient(0.25turn,#d768e0,#1891d8)"
                                routerLink="/default/ebook"
                            >
                                <mat-icon style="padding-right: 5px">book</mat-icon>

                                ExilioLabs Book
                            </button>
                    </mat-card>


                </div>
            </div>
        </div>
    </div>
</div>
<br>
<br>
<mat-divider></mat-divider>
