<style>
h1 {text-align: center;}
p {text-align: center; font-size: 20px}
div {text-align: center; margin:0 auto;}

</style>

<div>
    <div class="sidenav">
        <a (click)="onClick1()">Trading Simulator & Analysis Engine Launch</a>
        <a (click)="onClick2()">Indicator Additions</a>
        <a (click)="onClick3()">Exilio Export</a>
        <a (click)="onClick4()">Statistical Analysis for Trading</a>
        <a (click)="onClick5()">Development of Advanced Technical Indicators</a>
    </div>
</div>



<div class="header">
    <h1 style="font-size: 35px font-weight: bold;">Exilio Education</h1>
</div>

<div class="row" style="padding-left:10%; padding-right:10%; width:75vmax" *ngIf="isSelected1">
    <div class="leftcolumn">
        <div style="border: 1px solid white; text-align: center/left;" class="card yeo-work">
            <h2 style="font-size: 25px">Trading Simulator & Analysis Engine Launch</h2>
            <h5 style="font-size: 15px">April 1, 2021</h5>
            <p style="text-align:center;">
                We at Exilio are delighted to announce the launch of our Trading simulator & Analysis Engine.
                Here at Exilio we have been working extremely hard to develop the most functional analysis engine possible.
                The newly developed Analysis Enginer created by the team here at Exilio combines the best of software development
                with the lastest mathematcial and finanical tools, allowing users of all caliber to gain that desired edge.

                Through using advanced statistical modelling, we have enhanced many of the best known indicators (RSI, MACD etc..)
                to maximise thier potenital in returning a positive outcome for the user.
            </p>
            <img class="platform-img" src="assets/landing/images/sim.jpg" alt=""/>
            <p style="text-align:center;">
                We at Exilio are delighted to announce the launch of our Trading simulator & Analysis Engine.
                Here at Exilio we have been working extremely hard to develop the most functional analysis engine possible.
                The newly developed Analysis Enginer created by the team here at Exilio combines the best of software development
                with the lastest mathematcial and finanical tools, allowing users of all caliber to gain that desired edge.

                Through using advanced statistical modelling, we have enhanced many of the best known indicators (RSI, MACD etc..)
                to maximise thier potenital in returning a positive outcome for the user.
            </p>
        </div>
    </div>
</div>

<div class="row" style="padding-left:10%; padding-right:10%; width:75vmax" *ngIf="isSelected2">
    <div class="leftcolumn">
        <div style="border: 1px solid white; text-align: center/left;" class="card yeo-work">
            <h2 style="font-size: 25px">Indicator Additions</h2>
            <h5 style="font-size: 15px">April 1, 2021</h5>
            <p style="text-align:center;">
                We at Exilio are delighted to announce the launch of our Trading simulator & Analysis Engine.
                Here at Exilio we have been working extremely hard to develop the most functional analysis engine possible.
                The newly developed Analysis Enginer created by the team here at Exilio combines the best of software development
                with the lastest mathematcial and finanical tools, allowing users of all caliber to gain that desired edge.

                Through using advanced statistical modelling, we have enhanced many of the best known indicators (RSI, MACD etc..)
                to maximise thier potenital in returning a positive outcome for the user.
            </p>
            <img class="platform-img" src="assets/landing/images/sim.jpg" alt=""/>
            <p style="text-align:center;">
                We at Exilio are delighted to announce the launch of our Trading simulator & Analysis Engine.
                Here at Exilio we have been working extremely hard to develop the most functional analysis engine possible.
                The newly developed Analysis Enginer created by the team here at Exilio combines the best of software development
                with the lastest mathematcial and finanical tools, allowing users of all caliber to gain that desired edge.

                Through using advanced statistical modelling, we have enhanced many of the best known indicators (RSI, MACD etc..)
                to maximise thier potenital in returning a positive outcome for the user.
            </p>
        </div>
    </div>
</div>

<div class="row" style="padding-left:10%; padding-right:10%; width:75vmax" *ngIf="isSelected3">
    <div class="leftcolumn">
        <div style="border: 1px solid white; text-align: center/left;" class="card yeo-work">
            <h2 style="font-size: 25px">Exilio Export</h2>
            <h5 style="font-size: 15px">April 1, 2021</h5>
            <p style="text-align:center;">
                We at Exilio are delighted to announce the launch of our Trading simulator & Analysis Engine.
                Here at Exilio we have been working extremely hard to develop the most functional analysis engine possible.
                The newly developed Analysis Enginer created by the team here at Exilio combines the best of software development
                with the lastest mathematcial and finanical tools, allowing users of all caliber to gain that desired edge.

                Through using advanced statistical modelling, we have enhanced many of the best known indicators (RSI, MACD etc..)
                to maximise thier potenital in returning a positive outcome for the user.
            </p>
            <img class="platform-img" src="assets/landing/images/sim.jpg" alt=""/>
            <p style="text-align:center;">
                We at Exilio are delighted to announce the launch of our Trading simulator & Analysis Engine.
                Here at Exilio we have been working extremely hard to develop the most functional analysis engine possible.
                The newly developed Analysis Enginer created by the team here at Exilio combines the best of software development
                with the lastest mathematcial and finanical tools, allowing users of all caliber to gain that desired edge.

                Through using advanced statistical modelling, we have enhanced many of the best known indicators (RSI, MACD etc..)
                to maximise thier potenital in returning a positive outcome for the user.
            </p>
        </div>
    </div>
</div>

<div class="row" style="padding-left:10%; padding-right:10%; width:75vmax" *ngIf="isSelected4">
    <div class="leftcolumn">
        <div style="border: 1px solid white; text-align: center/left;" class="card yeo-work">
            <h2 style="font-size: 25px">Statistical Analysis for Trading</h2>
            <h5 style="font-size: 15px">April 1, 2021</h5>
            <p style="text-align:center;">
                We at Exilio are delighted to announce the launch of our Trading simulator & Analysis Engine.
                Here at Exilio we have been working extremely hard to develop the most functional analysis engine possible.
                The newly developed Analysis Enginer created by the team here at Exilio combines the best of software development
                with the lastest mathematcial and finanical tools, allowing users of all caliber to gain that desired edge.

                Through using advanced statistical modelling, we have enhanced many of the best known indicators (RSI, MACD etc..)
                to maximise thier potenital in returning a positive outcome for the user.
            </p>
            <img class="platform-img" src="assets/landing/images/sim.jpg" alt=""/>
            <p style="text-align:center;">
                We at Exilio are delighted to announce the launch of our Trading simulator & Analysis Engine.
                Here at Exilio we have been working extremely hard to develop the most functional analysis engine possible.
                The newly developed Analysis Enginer created by the team here at Exilio combines the best of software development
                with the lastest mathematcial and finanical tools, allowing users of all caliber to gain that desired edge.

                Through using advanced statistical modelling, we have enhanced many of the best known indicators (RSI, MACD etc..)
                to maximise thier potenital in returning a positive outcome for the user.
            </p>
        </div>
    </div>
</div>

<div class="row" style="padding-left:10%; padding-right:10%; width:75vmax" *ngIf="isSelected5">
    <div class="leftcolumn">
        <div style="border: 1px solid white; text-align: center/left;" class="card yeo-work">
            <h2 style="font-size: 25px">Development of Advanced Technical Indicators</h2>
            <h5 style="font-size: 15px">April 1, 2021</h5>
            <p style="text-align:center;">
                We at Exilio are delighted to announce the launch of our Trading simulator & Analysis Engine.
                Here at Exilio we have been working extremely hard to develop the most functional analysis engine possible.
                The newly developed Analysis Enginer created by the team here at Exilio combines the best of software development
                with the lastest mathematcial and finanical tools, allowing users of all caliber to gain that desired edge.

                Through using advanced statistical modelling, we have enhanced many of the best known indicators (RSI, MACD etc..)
                to maximise thier potenital in returning a positive outcome for the user.
            </p>
            <img class="platform-img" src="assets/landing/images/sim.jpg" alt=""/>
            <p style="text-align:center;">
                We at Exilio are delighted to announce the launch of our Trading simulator & Analysis Engine.
                Here at Exilio we have been working extremely hard to develop the most functional analysis engine possible.
                The newly developed Analysis Enginer created by the team here at Exilio combines the best of software development
                with the lastest mathematcial and finanical tools, allowing users of all caliber to gain that desired edge.

                Through using advanced statistical modelling, we have enhanced many of the best known indicators (RSI, MACD etc..)
                to maximise thier potenital in returning a positive outcome for the user.
            </p>
        </div>
    </div>
</div>

<br>
<br>
<link href="//maxcdn.bootstrapcdn.com/bootstrap/4.1.1/css/bootstrap.min.css" rel="stylesheet" id="bootstrap-css">
<script src="//maxcdn.bootstrapcdn.com/bootstrap/4.1.1/js/bootstrap.min.js"></script>
<script src="//cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>
<!------ Include the above in your HEAD tag ---------->

<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css" integrity="sha384-MCw98/SFnGE8fJT3GXwEOngsV7Zt27NXFoaoApmYm81iuXoPkFOJwJ8ERdknLPMO" crossorigin="anonymous">
<link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.2.0/css/all.css" integrity="sha384-hWVjflwFxL6sNzntih27bfxkr27PmbbK/iSvJ+a4+0owXq79v+lsFkW54bOGbiDQ" crossorigin="anonymous">

<!--footer starts from here-->
<footer class="footer">
    <div class="container">
        <ul class="foote_bottom_ul_amrc">
            <li><a routerLink="/">Home</a></li>
            <li><a routerLink="/default/about">About</a></li>
            <li><a routerLink="/default/services">Services</a></li>
            <li><a routerLink="/default/pricing">Pricing</a></li>
            <li><a routerLink="/default/blog">Blog</a></li>
            <li><a routerLink="/default/contact">Contact</a></li>
        </ul>
        <!--foote_bottom_ul_amrc ends here-->
        <p class="text-center">Copyright Exilio 2021©</p>

        <ul class="social_footer_ul">
            <li><a href="http://facebook.com"><i class="fab fa-facebook-f"></i></a></li>
            <li><a href="http://twitter.com"><i class="fab fa-twitter"></i></a></li>
            <li><a href="http://linkedin.com"><i class="fab fa-linkedin"></i></a></li>
            <li><a href="http://instagram.com"><i class="fab fa-instagram"></i></a></li>
        </ul>
        <!--social_footer_ul ends here-->
    </div>
</footer>


