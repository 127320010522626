import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent, MatDialogActions} from '@angular/material/dialog';
import { UserService } from 'src/app/core/services/user-api.service';

export interface DialogData {
  name: string;
  email: string;
}

export interface UserDats {
  user: string;
  email: string;
}

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss']
})
export class DefaultComponent implements OnInit {
  name: string;
  email: string;
  sideBarOpen = true;
  isDarkTheme: boolean;

  constructor(public dialog: MatDialog) {
  }

  // tslint:disable-next-line: typedef
  ngOnInit() {
      //this.openDialog();
  }


  sideBarToggler(): void {
    this.sideBarOpen = !this.sideBarOpen;
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(LoginDialog, {
      width: '500px',
      height: '520px',
      data: {name: this.name, email: this.email},
      backdropClass: 'backdropBackground'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.name = result.name;
      this.email = result.email;

    });
  }
}

// Login Dialog

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-default-dialog',
  templateUrl: './default-dialog.component.html',
  styleUrls: ['./default.component.scss']
})
// tslint:disable-next-line: component-class-suffix
export class LoginDialog {
  public register: boolean;
  public key: boolean;
  public uuid: string;
  public name: string;
  public email: string;

  constructor(
    public dialogRef: MatDialogRef<LoginDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public userService: UserService,
    ) {
      this.register = true;
      this.key = false;
    }

  onLogin(): void {
    this.dialogRef.close();
  }

  onCancel(): void {
    // this.data.name = name;
    // this.data.email = email;
    this.dialogRef.close();
  }

  onRegister(name: string, email: string): void {
    const user = {
      'user': name,
      'email': email
    };
    this.key = true;
    this.userService.create(user).subscribe(
      data => {
        this.name = data.user;
        this.email = data.email;
        this.uuid = data.uuid;
        this.key = true;
      },
      error => {
        console.log(error);
      });
  }
}



