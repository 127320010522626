import { Component, OnInit } from '@angular/core';
import { DashboardService }  from 'src/app/core/services/dashboard.service' ;
import {CognitoUserPool, CognitoUserAttribute, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import * as AWS from 'aws-sdk';6
import { __await } from 'tslib';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  public loginView: number;
  public username: any;
  public loginError: any;
  public password: any;
  

  constructor(private dashboardService: DashboardService) { }

  ngOnInit(): void {
    this.loginView = 0;
  }

  async onLogin(name: string, password: string): Promise<void> {

    localStorage.setItem('login', null);
    this.loginError = null

    console.log(name, password)

    var authenticationData = {
        Username: name,
        Password: password,
    };
    var authenticationDetails = new AuthenticationDetails(
        authenticationData
    );

    var poolData = {
        UserPoolId: 'eu-west-1_whall17HA', // Your user pool id here
        ClientId: '53eq9tifov659d5bsro82kn4oj', // Your client id here
    };
    var userPool = new CognitoUserPool(poolData);
    var userData = {
        Username: name,
        Pool: userPool,
    };
    var cognitoUser = new CognitoUser(userData);
    cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function(result) {
            var accessToken = result.getAccessToken().getJwtToken();
    
            //POTENTIAL: Region needs to be set if not already set previously elsewhere.
            AWS.config.region = 'eu-west-1';
    
            AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                IdentityPoolId: 'eu-west-1:01baacc8-3e31-4929-a0b8-4976aa4c8ebd', // your identity pool id here
                Logins: {
                    // Change the key below according to the specific region your user pool is in.
                    'cognito-idp.eu-west-1.amazonaws.com/eu-west-1_whall17HA': result
                        .getIdToken()
                        .getJwtToken(),
                },
            });
    
            //refreshes credentials using AWS.CognitoIdentity.getCredentialsForIdentity()
            ( < AWS.CognitoIdentityCredentials > AWS.config.credentials).refresh((error) => {
                if (error) {
                    console.error(error);
                    localStorage.setItem('login', String(error));
                } else {
                    // Instantiate aws sdk service objects now that the credentials have been updated.
                    // example: var s3 = new AWS.S3();
                    console.log('Successfully logged!:');
                    localStorage.setItem('login', 'logged')
                    /* code here for authentication code */
                }
            });
        },
    
        onFailure: function(err) {
            console.log(err);
            localStorage.setItem('login', err);
            console.log(localStorage.getItem('login'))
        },
    });


    const delay = ms => new Promise(res => setTimeout(res, ms));
    await delay(2000);
    if (localStorage.getItem('login') == 'logged') {
        this.loginView = 1
        this.dashboardService.updateSubscription(name).subscribe()
    }

    if (localStorage.getItem('login') == null){
        this.loginError = 'Incorrect Username or Password'
               
    } else {
            this.loginError = 'Incorrect Username or Password'
    };  



}

}
