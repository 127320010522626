<!-- Desktop -->
<div *ngIf="!this.mobile" style=" border-bottom: 10px solid; border-image: linear-gradient(0.25turn, #d768e0, #1891d8) 1;">
    <!-- <mat-toolbar  style="background: linear-gradient(#d768e0,#1891d8)" > -->
    <!-- <mat-toolbar style="background: linear-gradient(0.25turn, #d768e0, #1891d8)"> -->
        <mat-toolbar >
        <mat-toolbar-row>
            <div fxFlex fxLayout="row" fxLayoutAlign="start stretch">
                <div fxFlex="20" style="margin-top: 10px;">
                    <img (click)="redirect('/', false)" *ngIf="!this.darkTheme" src="assets/imgs/icon-black.png" style="padding-left: 20px; max-height: 50px" />
                    <span (click)="redirect('/', false)" *ngIf="!this.darkTheme" style="font-family: 'Cooper Black';font-size: 5vh;"> ExilioLabs</span>
                    <img (click)="redirect('/', false)" *ngIf="this.darkTheme" src="assets/imgs/icon.png" style="padding-left: 20px; max-height: 50px" />
                    <span (click)="redirect('/', false)" *ngIf="this.darkTheme" style="font-family: 'Cooper Black';font-size: 5vh;"> ExilioLabs</span>
                </div>
                <div fxFlex="50">
                </div>
                <div fxFlex fxLayout="row" fxLayoutAlign="flex-end">
                    <ul fxLayout="row" fxLayoutGap="0px">
                        <li>
                            <button mat-button (click)="redirect('/', false)">
                                <mat-icon>analytics</mat-icon>
                                <span> Landing </span>
                            </button>
                        </li>
                        <li>
                            <button mat-button (click)="redirect('/sim-default', false)">
                                <mat-icon>gamepad</mat-icon>
                                <span> Simulator </span>
                            </button>
                        </li>
                        <li>
                            <button mat-button [matMenuTriggerFor]="menu">
                                <mat-icon>settings</mat-icon>
                                <span> Theme </span>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button (click)="changeTheme()" mat-menu-item>
                                    <mat-icon>brightness_4</mat-icon>
                                    Toggle Theme
                                </button>
                            </mat-menu>
                        </li>
                    </ul>
                </div>
            </div>
        </mat-toolbar-row>
    </mat-toolbar>
</div>

<!-- Mobile -->

<div *ngIf="this.mobile">
    <mat-toolbar style="background: linear-gradient(0.25turn, #d768e0, #1891d8)">
        <mat-toolbar-row>
            <!-- <button mat-icon-button (click)="changeTheme()">
                <mat-icon>menu</mat-icon> 
                <mat-icon></mat-icon>
            </button> -->
            <img src="assets/imgs/icon.png" style="padding-left: 5px; max-height: 20px" />
            <img src="assets/imgs/exilio_white.png" style="max-height: 20px" />

            <mat-nav-list fxFlex fxLayout="row" fxLayoutAlign="flex-end" style="float:right ;">
                <a style="text-align: center; color: #1e1a31" mat-list-item fxFlex="30">
                    <button
                        style="text-align: right; color: #1e1a31; margin-right: 20px"
                        mat-button
                        [matMenuTriggerFor]="menu"
                    >
                        <mat-icon> menu</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button (click)="redirect('/', false)" mat-menu-item>
                            <mat-icon>analytics</mat-icon>
                            Home
                        </button>
                        <button (click)="redirect('default/about', false)" mat-menu-item>
                            <mat-icon>accessibility_new</mat-icon>
                            About Us
                        </button>
                        <button (click)="changeTheme()" mat-menu-item>
                            <mat-icon>brightness_4</mat-icon>
                            Toggle Theme
                        </button>
                        <!-- <button (click)="redirect('/', false)" mat-menu-item>
                            <mat-icon>source</mat-icon>
                            Courses
                        </button> -->
                        <!-- <button (click)="redirect('', true)"  mat-menu-item>
                            <mat-icon>gamepad</mat-icon>
                            Simulator
                        </button> -->
                        <!-- <button (click)="redirect('/contact', false)" mat-menu-item>
                            <mat-icon>chat</mat-icon>
                            Contact
                        </button> -->
                    </mat-menu>
                </a>
            </mat-nav-list>
        </mat-toolbar-row>
    </mat-toolbar>
</div>
