<div *ngIf="!this.mobile" style="border-bottom: 10px solid; border-image: linear-gradient(0.25turn, #d768e0, #1891d8) 1">
    <mat-toolbar>
        <mat-toolbar-row>
            <!-- <button mat-icon-button (click)="toggleSideBar()">
            <mat-icon>menu</mat-icon>
            <mat-icon></mat-icon>
        </button> -->
        <img (click)="redirect('/', false)" *ngIf="!this.darkTheme" src="assets/imgs/icon-black.png" style="padding-left: 20px; max-height: 50px" />
        <span (click)="redirect('/', false)" *ngIf="!this.darkTheme" style="font-family: 'Cooper Black';font-size: 5vh;"> ExilioLabs</span>
        <img (click)="redirect('/', false)" *ngIf="this.darkTheme" src="assets/imgs/icon.png" style="padding-left: 20px; max-height: 50px" />
        <span (click)="redirect('/', false)" *ngIf="this.darkTheme" style="font-family: 'Cooper Black';font-size: 5vh;"> ExilioLabs</span>
            <!-- <span style="padding-left: 10px; color: #1e1a31; font-family: 'Gill Sans', 'sans-serif'; font-size: 30px; font-weight:bold; font-style: italic;">company simulator</span> -->

            <div fxFlex fxLayout="row" fxLayoutAlign="flex-end">
                <ul fxLayout="row" fxLayoutGap="0px">
                    <li>
                        <button mat-button (click)="redirect('/', false)">
                            <mat-icon>gamepad</mat-icon>
                            <span> Home </span>
                        </button>
                    </li>
                    <li>
                        <button mat-button (click)="redirect('/sim-default/leaderboard', false)">
                            <mat-icon>leaderboard</mat-icon>
                            <span> Leaderboard </span>
                        </button>
                    </li>
                    <li>
                        <button  mat-button (click)="this.openDialog()">
                            <mat-icon>help</mat-icon>
                            <span> User Guide </span>
                        </button>
                    </li>
                    <li *ngIf="!showLogout">
                        <button (click)="openLoginDialog()"  mat-button>
                            <mat-icon>account_circle</mat-icon>
                            <span> Account </span>
                        </button>
                    </li>
                    <li *ngIf="showLogout">
                        <button  mat-button [matMenuTriggerFor]="logout">
                            <mat-icon>account_circle</mat-icon>
                            <span> {{ username }} </span>
                        </button>
                        <mat-menu #logout="matMenu">
                            <button (click)="onLogout()" mat-menu-item>
                                <mat-icon>exit_to_app</mat-icon>
                                Logout
                            </button>
                        </mat-menu>
                    </li>
                    <li>
                        <button mat-button (click)="redirect('/default', false)">
                            <mat-icon>analytics</mat-icon>
                            <span> Trading </span>
                        </button>
                    </li>
                    <li>
                        <button mat-button [matMenuTriggerFor]="menu">
                            <mat-icon>settings</mat-icon>
                            <span> Settings </span>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button (click)="changeTheme()" mat-menu-item>
                                <mat-icon>brightness_4</mat-icon>
                                Toggle Theme
                            </button>
                        </mat-menu>
                    </li>
                </ul>
            </div>
        </mat-toolbar-row>
    </mat-toolbar>
</div>

<!-- Mobile -->

<div *ngIf="this.mobile" style="overflow-x: hidden">
    <mat-toolbar style="background: linear-gradient(0.25turn, #d768e0, #1891d8)">
        <mat-toolbar-row>
            <!-- <button mat-icon-button (click)="changeTheme()">
                <mat-icon>menu</mat-icon>
                <mat-icon></mat-icon>
            </button> -->
            <img *ngIf="!this.darkTheme" src="assets/imgs/icon-black.png" style="padding-left: 20px; max-height: 25px" />
            <img *ngIf="!this.darkTheme"src="assets/imgs/exilio_black_sim.png" style="max-height: 25px" />
            <img *ngIf="this.darkTheme" src="assets/imgs/icon.png" style="padding-left: 20px; max-height: 25px" />
            <img *ngIf="this.darkTheme" src="assets/imgs/exilio_white_sim.png" style="max-height: 25px" />

            <mat-nav-list fxFlex fxLayout="row" fxLayoutAlign="flex-end">
                <a style="text-align: center; color: #1e1a31;" mat-list-item fxFlex="0">
                    <button
                        style="text-align: right; color: #1e1a31; margin-right: 20px"
                        mat-button
                        [matMenuTriggerFor]="menu"
                    >
                        <mat-icon> menu</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button (click)="redirect('/sim-default/leaderboard', false)" mat-menu-item>
                            <mat-icon>source</mat-icon>
                            Leaderboard
                        </button>
                        <div *ngIf="!showLogout">
                            <button (click)="openLoginDialog()"  mat-menu-item>
                                <mat-icon>account_circle</mat-icon>
                                <span> Account </span>
                            </button>
                        </div>
                        <div *ngIf="showLogout">
                            <button  mat-menu-item [matMenuTriggerFor]="logout">
                                <mat-icon>account_circle</mat-icon>
                                <span> Account </span>
                            </button>
                            <mat-menu #logout="matMenu">
                                <button (click)="onLogout()" mat-menu-item>
                                    <mat-icon>exit_to_app</mat-icon>
                                    Logout
                                </button>
                            </mat-menu>
                        </div>
                        <button (click)="redirect('/', false)" mat-menu-item>
                            <mat-icon>analytics</mat-icon>
                            Home
                        </button>
                        <button (click)="redirect('/default', false)" mat-menu-item>
                            <mat-icon>source</mat-icon>
                            Lab
                        </button>
                        <button (click)="changeTheme()" mat-menu-item>
                            <mat-icon>brightness_4</mat-icon>
                            Toggle Theme
                        </button>
                    </mat-menu>
                </a>
            </mat-nav-list>
        </mat-toolbar-row>
    </mat-toolbar>
</div>
