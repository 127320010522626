import { ViewportScroller } from '@angular/common';
import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-landing',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {
    public mobile: boolean;
    public zone: string;
    public currency: number;
    public ZAR: boolean;
    public USD: boolean;
    public AUD: boolean;
    public EUR: boolean;
    public CAD: boolean;
   

    constructor(private router: Router, private viewportScroller: ViewportScroller) {}

    ngOnInit(): void {
      if (window.screen.width < 768) {
        // 768px portrait
        this.mobile = true;
    }

    this.zone = Intl.DateTimeFormat().resolvedOptions().timeZone

    this.zone =  this.zone.split('/')[0]; 
    console.log(this.zone)  
    
    this.ZAR = false
    this.USD = false
    this.AUD = false
    this.EUR = false
    this.CAD = false

    if (this.zone == "Africa") {
      this.ZAR = true
    } else if (this.zone == "America" || this.zone == "Antarctica") {
      this.USD = true
    } else if (this.zone == "Australia" || this.zone == "Pacific") {
      this.AUD = true
    } else if (this.zone == "Europe") {
      this.EUR = true
    } else if (this.zone == "Canada") {
      this.CAD = true
    } else {
      this.EUR = true
    }
    }

    goToId(id: string): void {
      this.viewportScroller.scrollToAnchor(id);
    }
    
}
