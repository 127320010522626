import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {HttpRequest, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ScoreboardService {

  // tslint:disable-next-line: variable-name
  private _listRoutePrefix = `${environment.apiBaseUrl}simulator/api/scoreboardlist`;
  private _routePrefix = `${environment.apiBaseUrl}simulator/api/score`;
  private  _authPrefix = `${environment.apiBaseUrl}auth/login`;

  constructor(private http: HttpClient) { }

  login(data): Observable<any> {
    return this.http.post(this._authPrefix, data);
  }

  getAll(): Observable<any> {
    return this.http.get(this._listRoutePrefix);
  }

  get(id): Observable<any> {
    return this.http.get(`${this._routePrefix}/${id}`);
  }

  getByUser(user): Observable<any> {
    const accessToken = localStorage.getItem('access_token');
    const customHeaders = new HttpHeaders({ Authorization: 'Bearer ' + accessToken});
    return this.http.get(`${this._routePrefix}/${user}`, {headers: customHeaders});

  }

  create(data): Observable<any> {
    const accessToken = localStorage.getItem('access_token');
    const customHeaders = new HttpHeaders({ Authorization: 'Bearer ' + accessToken});
    return this.http.post(this._routePrefix, data, {headers: customHeaders});
  }

  update(id, data): Observable<any> {
    const accessToken = localStorage.getItem('access_token');
    const customHeaders = new HttpHeaders({ Authorization: 'Bearer ' + accessToken});
    return this.http.put(`${this._routePrefix}/${id}`, data, {headers: customHeaders});
  }
}