import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-ebook',
  templateUrl: './ebook.component.html',
  styleUrls: ['./ebook.component.scss']
})
export class EbookComponent implements OnInit {
   isSelected1: boolean = false;
   isSelected2: boolean = false;
   isSelected3: boolean = false;
   isSelected4: boolean = false;
   public mobile: boolean;


  constructor( private viewportScroller: ViewportScroller) { }

  ngOnInit(): void {
    if (window.screen.width < 500) {
      // 768px portrait
      this.mobile = true;
  }
  }

  onClick1() {
    this.isSelected2 = !this.isSelected2;
    this.isSelected3 = !this.isSelected3;
    this.isSelected4 = !this.isSelected4;
    }

  onClick2() {
     this.isSelected2 = !this.isSelected2;
    }

  onClick3() {
     this.isSelected3 = !this.isSelected3;
    }

  onClick4() {
     this.isSelected4 = !this.isSelected4;
    }


  goToId(id: string): void {
  console.log('test');
  }

  myFunction2() {
    var x = document.getElementById("section2");
    if (x.style.display === "none") {
      x.style.display = "block";
      this.viewportScroller.scrollToAnchor("section2");
    } else {
      x.style.display = "none";
    }
  }

  myFunction3() {
    var x = document.getElementById("section3");
    if (x.style.display === "none") {
      x.style.display = "block";
      this.viewportScroller.scrollToAnchor("section3");
    } else {
      x.style.display = "none";
    }
  }

  myFunction4() {
    var x = document.getElementById("section4");
    if (x.style.display === "none") {
      x.style.display = "block";
      this.viewportScroller.scrollToAnchor("section4");
    } else {
      x.style.display = "none";
    }
  }

  myFunction5() {
  document.getElementById("section2").style.display = "none";
  document.getElementById("section3").style.display = "none";
  document.getElementById("section4").style.display = "none";
  }

  myFunction() {
    window.open("https://buy.stripe.com/test_28o01h6hlb8NeUo000","_blank");
  }

}
