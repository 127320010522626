<div class="center" action="action_page.php" style="border:1px solid #ccc; border-radius: .5rem;" *ngIf="loginView == 0">
    <div class="container center" style="max-width: 1000px">
        <h1>Cancel Subscription <a>&#9785;</a></h1>
        <p>Please provide your account username and password to cancel subscription:</p>
        <p>If the associated account has an active subscription it will be cancelled according to our  <b><a routerLink="/default/tos" style="color:dodgerblue">Terms of Service</a></b>.</p>
        <p>If you do not have an account created with the subscribed email please email us at <b><a href="mailto:trading@exiliolabs.com" style="color: dodgerblue ;">Trading@ExilioLabs.com</a> </b> to start the cancelation of your subscription. </p> 
        <p> <b>If the account under the associated email address was made after the initial subscription date, cancellation will need to be made by emailing <a href="mailto:trading@exiliolabs.com" style="color: dodgerblue ;"> Trading@ExilioLabs.com</a> for verification purposes.</b>  </p>
        <hr>

        <label for="email"><b>Username</b></label>
        <input type="text" placeholder="Enter Username" matInput [(ngModel)]="username">

        <label for="psw"><b>Password</b></label>
        <input type="password" placeholder="Enter Password" matInput [(ngModel)]="password">

        <div class="clearfix">
            <button type="submit" class="signupbtn" (click)="onLogin(username, password)" style="min-width: 125px;">Cancel Subscription</button>
        </div>
        <div style="text-align: center">
            <p style="color: red;" *ngIf="this.loginError != null">*Login Error: {{ loginError }}</p>
        </div>
    </div>
</div>

<div class="center" action="action_page.php" style="border:1px solid #ccc; border-radius: .5rem;" *ngIf="loginView == 1">
    <div class="container center" style="max-width: 1000px;">
        <h1>Subscription Cancelled <a>&#9785;</a></h1>
    </div>
</div>