<div>
    <div>
        <div style="text-align: center">
            <img src="./../../../assets/imgs/icon.png" style="max-height: 90px" />
        </div>
        <h2 style="text-align: center">Welcome To Exilio Trading</h2>
        <h3 style="text-align: center">Simulation 1.0</h3>
        <br />
        <div *ngIf="register && !key"> 
            <div mat-dialog-content style="text-align: center">
                <mat-form-field appearance="outline">
                    <mat-label>Username</mat-label>
                    <input matInput [(ngModel)]="data.name" />
                    <mat-icon matSuffix>accessibility_new</mat-icon>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput [(ngModel)]="data.email" />
                    <mat-icon matSuffix>contact_mail</mat-icon>
                </mat-form-field>
            </div>
            <div mat-dialog-actions style="margin-left: 130px">
                <button mat-raised-button color="accent" (click)="onCancel()" cdkFocusInitial>Maybe Later</button>
                <button mat-raised-button color="primary" (click)="onRegister(data.name, data.email)" >Register</button>
                <button mat-button color="primary" (click)="register=!register">Already have an Account?</button>
            </div>
        </div>

        <div *ngIf="!register"> 
            <div mat-dialog-content style="text-align: center">
                <mat-form-field appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput [(ngModel)]="data.name" />
                    <mat-icon matSuffix>alternate_email</mat-icon>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Unique ID</mat-label>
                    <input matInput [(ngModel)]="data.email" />
                    <mat-icon matSuffix>vpn_key</mat-icon>
                </mat-form-field>
            </div>
            <div mat-dialog-actions style="margin-left: 130px">
                <button mat-raised-button color="accent" (click)="register=!register">Back to Register</button>
                <button mat-raised-button color="primary" [mat-dialog-close]="data" cdkFocusInitial>Login</button>
            </div>
        </div>

        <div *ngIf="key"> 
            <mat-divider></mat-divider>
            <div mat-dialog-content style="text-align: center">
                <h3>Welcome {{name}} 😁</h3>
                
                <h4>Your unique ID is "{{uuid}}".</h4>
                
                <h4>Please take note if you wish to log in again 🔑</h4>

            </div>
            <div mat-dialog-actions style="margin-left: 140px">
                <button mat-raised-button color="primary" (click)="onCancel()">Continue to Simulator</button>
            </div>
        </div>
    </div>
</div>
