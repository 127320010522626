import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { interval, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';




const ohlc = 'http://localhost:8080/simulator/api/scores/ohlc';

@Injectable({
    providedIn: 'root',
})
export class DashboardService {

    public data: any;
    public limit: string;
    public period: any;
    public conversion_rate: any
    public margin: string;
    public dataSource: any;

    // tslint:disable-next-line: variable-name
    private _routePrefix = `${environment.apiBaseUrl}trading`;

    constructor(private http: HttpClient) {}

    getCoinPrice(coin: string): Observable<any> {
        return this.http.get(`${this._routePrefix}/coinprice?symbol=${coin}`);
    }

    getAllCoinPrices(): Observable<any> {
        return this.http.get(`https://1mo30s8a8h.execute-api.eu-west-2.amazonaws.com/dev/coinheader`);
    }

    getIndicator(interval: string, symbol: string, margin: any,second_currency: string, indicator: string, option: string): Observable<any> {
        this.limit = "500"
        this.period = "14"
        this.margin = margin
        this.conversion_rate = "1"  
        return this.http.get(`https://1mo30s8a8h.execute-api.eu-west-2.amazonaws.com/dev/indicator?interval=${interval}&limit=${this.limit}&period=${this.period}&symbol=${symbol}&margin=${this.margin}&second_currency=${second_currency}&conversion_rate=${this.conversion_rate}&indicator=${indicator}&option=${option}`);
    }

    getLeaderboard(): Observable<any> {
        return this.http.get(`https://1mo30s8a8h.execute-api.eu-west-2.amazonaws.com/dev/leaderboard`);
    }

    contactUs(email, message): Observable<any> {
        return this.http.get(`https://1mo30s8a8h.execute-api.eu-west-2.amazonaws.com/dev/contact?email=${email}&message=${message}`);
    }

    getUserLeaderboardPosition (): Observable <any> {
        return this.http.get('https://1mo30s8a8h.execute-api.eu-west-2.amazonaws.com/dev/leaderboard')
    }

    updateSubscription (name: string): Observable <any> {
        return this.http.get(`https://1mo30s8a8h.execute-api.eu-west-2.amazonaws.com/dev/updateSubscription?name=${name}`);
    }

    saveSimulatorSession (username: string, score: number, accuracy: number): Observable <any> {
        return this.http.get(`https://1mo30s8a8h.execute-api.eu-west-2.amazonaws.com/dev/saveSession?username=${username}&score=${score}&accuracy=${accuracy}`);
    }

    checkExilioExportID (ID: string): Observable <any> {
        return this.http.get(`https://1mo30s8a8h.execute-api.eu-west-2.amazonaws.com/dev/ExilioExportId?ID=${ID}`);
    }


}
