<app-sim-header ></app-sim-header>

<mat-drawer-container>
    
    <mat-drawer-content>
        <router-outlet></router-outlet>
    </mat-drawer-content>

</mat-drawer-container>

<app-footer></app-footer>