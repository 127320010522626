import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
} from '@angular/material/dialog';
import { ColorSchemeService } from 'src/app/core/services/color-scheme.service';
import { Router } from '@angular/router';
import { DashboardService } from '../../../core/services/dashboard.service';
import { interval, Subscription } from 'rxjs';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    public mobile: boolean;
    public darkTheme: boolean;
    public btc: string;
    public eth: string;
    public xrp: string;
    public btcOld: string;
    public ethOld: string;
    public xrpOld: string;
    public trend: string;
    public refreshPeriod: number;
    public subscription: Subscription;


    @Output() toggleSideBarForMe: EventEmitter<any> = new EventEmitter();

    constructor(
        private dashboardService: DashboardService,
        public dialog: MatDialog,
        private colorSchemeService: ColorSchemeService,
        private router: Router
    ) {
        this.mobile = false;
    }

    ngOnInit() {
        if (window.screen.width < 600) {
            // 768px portrait
            this.mobile = true;
        }
        this.loadCoinPrice();
        //this.openDialog();
        this.trend = '#ffffff';
        if (localStorage.getItem('prefers-color') === 'light') {
            this.darkTheme = false;
        } else {
            this.darkTheme = true;
        }
        // Reload Subscription every 60s
        // this.refreshPeriod = 3;
        // const source = interval(this.refreshPeriod * 1000);
        // this.subscription = source.subscribe((val) => this.loadCoinPrice());
    }

    redirect(route: string, external: boolean): void {
        if (external) {
            window.open('http://localhost:8081', '_blank'); //For links to external sites only
        } else {
            this.router.navigate([route],);
        }
    }

    loadCoinPrice(): void {
        this.dashboardService.getCoinPrice('BTCUSDT').subscribe(
            (data) => {
                this.btcOld = this.btc;
                this.btc = (Math.round(data.price * 1000) / 1000).toFixed(3);
                if (+this.btc > +this.btcOld) {
                } else {
                    this.trend = '#F10000';
                }
            },
            (error) => {
                console.log(error);
            }
        );
        this.dashboardService.getCoinPrice('XRPUSDT').subscribe(
            (data) => {
                this.xrp = (Math.round(data.price * 1000) / 1000).toFixed(3);
                if (+this.xrp > +this.xrpOld) {
                    this.trend = '#589636';
                } else {
                    this.trend = '#F10000';
                }
            },
            (error) => {
                console.log(error);
            }
        );
        this.dashboardService.getCoinPrice('ETHUSDT').subscribe(
            (data) => {
                this.eth = (Math.round(data.price * 1000) / 1000).toFixed(3);
                if (+this.eth > +this.ethOld) {
                    this.trend = '#589636';
                } else {
                    this.trend = '#F10000';
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    // tslint:disable-next-line: typedef
    toggleSideBar() {
        this.toggleSideBarForMe.emit();
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 300);
    }

    changeTheme(): void {
        const scheme = this.colorSchemeService.currentActive();
        if (scheme === 'dark') {
            this.colorSchemeService.update('light');
        } else {
            this.colorSchemeService.update('dark');
        }

        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/default']);
        });
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(HelpDialog, {
            width: '500px',
            height: '500px',
            backdropClass: 'backdropBackground',
        });

        dialogRef.afterClosed().subscribe((result) => {});
    }
}

// Help dialog

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'app-help-dialog',
    templateUrl: './help-dialog.component.html',
    styleUrls: ['./header.component.scss'],
})
// tslint:disable-next-line: component-class-suffix
export class HelpDialog {
    constructor(public dialogRef: MatDialogRef<HelpDialog>) {}

    close(): void {
        this.dialogRef.close();
    }
}
