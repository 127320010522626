import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class DashboardServices {
    // tslint:disable-next-line: variable-name
    private _routePrefix = `${environment.apiBaseUrl}simulator/api/scores/ohlc`;

    constructor(private http: HttpClient) {}

    getOhlc(): Observable<any> {
        return this.http.get(`https://1mo30s8a8h.execute-api.eu-west-2.amazonaws.com/dev/simulator`);
    }
}
